import { searchhub } from '../../../../../data/api/hub';
import { ListResponse } from '../../../../../data/model/interfaces';
import { HubDetailEntity } from '../../../../../data/model/trip/hub';
import {
  Schema,
  customComponentTypes,
  validatorTypes,
} from '../../../../../reusable-components/data-driven-forms';

const schema = {
  fields: [
    {
      label: 'Select Hub',
      name: 'hub_id',
      component: customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      loadOptions: (currentSearchValue: string) =>
        new Promise((resolve, reject) => {
          searchhub(currentSearchValue)
            .then((response: ListResponse<HubDetailEntity>) => {
              resolve(
                response.results.map((item: HubDetailEntity) => ({
                  label: item.address.address_name,
                  value: item.id,
                }))
              );
            })
            .catch(reject);
        }),
    },
  ],
} as const;

export type AssignHubFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
