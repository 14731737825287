import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { convertPaisaToRupee } from '../../../../../utils/currenty-util';
import InfoIcon from '@mui/icons-material/Info';

export const PaymentDetailCard = ({
  amountTypeText,
  amountValue,
  isPendingValue = false,
}: {
  amountTypeText: string;
  amountValue: number;
  isPendingValue?: boolean;
}) => {
  return (
    <Box sx={{ minWidth: 200, }}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: isPendingValue ? 'rgba(255, 0, 0, 0.1)' : undefined,
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 24 }}>{amountTypeText}</Typography>
          {isPendingValue ? (
            <Box sx={{ height: 18 }} />
          ) : (
            <Typography sx={{ fontSize: 12 }}>till date</Typography>
          )}
          <Box sx={{ height: '16px' }} />
          <Typography sx={{ fontSize: 18 }}>
            {convertPaisaToRupee(amountValue)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export const BalanceDetailCard = ({
  amountTypeText,
  amountValue,
  tooltipText,
}: {
  amountTypeText: string;
  amountValue: number;
  tooltipText: string;
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <Card variant="outlined">
        <CardContent
          style={{
            display: isMobileScreen ? 'column' : 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Typography variant="h4">{amountTypeText}</Typography>
            <Box sx={{ width: '8px' }} />
            <Tooltip title={tooltipText}>
              <IconButton size="small">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
          {isMobileScreen ? <Box sx={{ height: '8px' }} /> : undefined}
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            {convertPaisaToRupee(amountValue)}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
