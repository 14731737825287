import { AxiosResponse } from 'axios';
import { ListResponse } from '../model/interfaces';
import { logisticsApiClient } from './logisticsApiClient';
import { DeliveryAgentListEntity } from '../model/deliveryAgent/deliveryAgent';
import { UserRole } from '../model/auth/auth';
import { deliveryAgentUrl } from './apiUrls/logisticsApiUrls';

export const getDeliveryAgentList = async ({
  pageNumber = 1,
  searchString,
  hubId,
  role,
  queryParams,
}: {
  pageNumber?: number;
  searchString?: string;
  hubId: string;
  role: string;
  queryParams?: {
    [key: string]: any;
  };
}) => {
  const axiosResponse: AxiosResponse<ListResponse<DeliveryAgentListEntity>> =
    await logisticsApiClient.get(deliveryAgentUrl(hubId), {
      params: {
        page: pageNumber,
        search_query: searchString,
        role: role,
        ...queryParams,
      },
    });
  return axiosResponse.data;
};

export const linkDeliveryAgentToHub = async ({
  userRole,
  hubId,
}: {
  userRole: UserRole;
  hubId: string;
}) => {
  const axiosResponse: AxiosResponse<any> = await logisticsApiClient.post(
    deliveryAgentUrl(hubId),
    {
      user_id: userRole.data.user_profile.user_id,
      role: userRole.role,
      user_info: {
        name: userRole.data.profile_name,
        phone: userRole.data.user_profile.phone,
      },
    }
  );
  return axiosResponse.data;
};
