import { Grid, Typography } from '@mui/material';

export const DetailItem = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2" component="div">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" component="div">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const CustomDetailItem = ({
  labelComponent,
  valueComponent,
}: {
  labelComponent: any;
  valueComponent: any;
}) => {
  return (
    <>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12} sm={4}>
          {labelComponent}
        </Grid>
        <Grid item xs={12} sm={8}>
          {valueComponent}
        </Grid>
      </Grid>
    </>
  );
};

export const DetailItemVerticalDirection = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body2" component="div">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" component="div">
            {value}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const CustomDetailItemVerticalDirection = ({
  labelComponent,
  valueComponent,
}: {
  labelComponent: any;
  valueComponent: any;
}) => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={4}>
          {labelComponent}
        </Grid>
        <Grid item xs={12} sm={8}>
          {valueComponent}
        </Grid>
      </Grid>
    </>
  );
};