import { AxiosResponse } from 'axios';
import { UserRole } from '../model/auth/auth';
import { OnboardUserRequest } from '../model/auth/onboard-user';
import { Image } from '../model/interfaces';
import { commerceApiClient } from './commerceApiClient';
import { onboardUserUrl, uploadPhotoUrl } from './apiUrls/commerceApiUrls';

export const onboardUser = async (requestBody: OnboardUserRequest) => {
  const axiosResponse: AxiosResponse<UserRole> = await commerceApiClient.post(
    onboardUserUrl,
    requestBody
  );
  return axiosResponse.data;
};

export const uploadPhoto = async (requestBody: FormData) => {
  const axiosResponse: AxiosResponse<Image> = await commerceApiClient.postForm(
    uploadPhotoUrl,
    requestBody
  );
  return axiosResponse.data;
};
