import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ShipmentIdButton = ({
  tripId,
  shipmentId,
}: {
  tripId: number;
  shipmentId: number;
}) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
      navigate(`/trips/${tripId}/shipments/${shipmentId}`);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={handleSubmit}
      >
        {shipmentId ?? '--'}
      </Button>
    </>
  );
};
