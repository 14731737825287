import { PaymentStatus } from '../../../../../constants/trips/payment';
import { TripShipmentRelationStatus } from '../../../../../constants/trips/trip-list';
import {
  Schema,
  validatorTypes,
  customComponentTypes,
} from '../../../../../reusable-components/data-driven-forms';
import {
  createdShipmentOptions,
  inTransitShipmentOptions,
} from './trip-shipment-status-option-type';

const schema = {
  description: 'Login',
  fields: [
    {
      label: 'Shipment Status',
      name: 'status',
      component: customComponentTypes.SELECT,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      resolveProps: (_props: any, _field: any, formValues: any) => {
        const favoriteValue = formValues.initialValues.status;
        return {
          options:
            favoriteValue == TripShipmentRelationStatus.CREATED
              ? createdShipmentOptions
              : inTransitShipmentOptions,
        };
      },
    },
    {
      label: '** By changing the shipment status to delivered, you also marked payment status as collected.',
      name: 'delivered_text',
      component: customComponentTypes.PLAIN_TEXT,
      condition: {
        and: [
          {
            when: 'status',
            is: TripShipmentRelationStatus.DELIVERED,
          },
          {
            when: 'is_last_trip',
            is: true,
          },
          {
            not: [
              {
                when: 'payment_status',
                is: PaymentStatus.PAID,
              }
            ]
          }, 
        ]
      },
    },
    {
      label: 'Cancellation Reason',
      name: 'cancellation_note',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      condition: {
        when: 'status',
        is: TripShipmentRelationStatus.CUSTOMER_REJECTED,
      },
    },
  ],
} as const;

export type UpdateTripShipmentStatusFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
