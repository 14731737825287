import { searchhub } from '../../../data/api/hub';
import { HubDetailEntity } from '../../../data/model/trip/hub';
import {
  Schema,
  validatorTypes,
  customComponentTypes,
} from '../../../reusable-components/data-driven-forms';

const schema = {
  description: 'Select Hub',
  fields: [
    {
      label: 'Select Hub',
      name: 'hub_object',
      component: customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD,
      isSearchable: true,
      loadOptions: (currentSearchValue: string) =>
        new Promise((resolve, reject) => {
          searchhub(currentSearchValue)
            .then((response) => {
              resolve(
                response.results.map((item: HubDetailEntity) => ({
                  label: item.address.address_name,
                  value: item,
                }))
              );
            })
            .catch(reject);
        }),
      simpleValue: true,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type SelectHubFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
