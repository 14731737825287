import { Box, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { IFilterValue } from '../../../../../data/model/interfaces';
import { listSateKeys } from '../../../../../redux/lists/constants';
import { listActions } from '../../../../../redux/lists/reducer';
import { tripListSelectors } from '../../../../../redux/trip-list/selectors';
import { CustomSelectFilter } from '../../../../../reusable-components/filter-components/CustomSelectFilter';
import {
  LogisticsPartner,
  LogisticsPartnerFilter,
  TripLogisticsPartnerFilterLabel,
  TripStatus,
  TripStatusFilter,
  TripStatusFilterLabel,
} from '../../../../../constants/trips/trip-list';
import { SelectFilter } from '../../../../../reusable-components/filter-components/SelectFilter';

export const TripFilters = ({
  onFilterChange: refreshList,
}: {
  onFilterChange: () => void;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const tripStatusValues = useAppSelector((state) =>
    tripListSelectors.selectFilterValue(state, TripStatusFilter.FILTER_KEY));

  const setTripStatusValues = (value: IFilterValue | null) => {
    dispatch(
      listActions.setFilterValue({
        filterValue: value,
        filterKey: TripStatusFilter.FILTER_KEY,
        key: listSateKeys.TRIP_LIST,
      })
    );
    refreshList();
  };

  const logisticsPartnerFilterValues = useAppSelector((state) =>
    tripListSelectors.selectFilterValue(
      state,
      LogisticsPartnerFilter.FILTER_KEY
    )
  );

  const setSelectedLogisticsPartnerValues = (value: IFilterValue[] | null) => {
    dispatch(
      listActions.setFilterValue({
        filterValue: value,
        filterKey: LogisticsPartnerFilter.FILTER_KEY,
        key: listSateKeys.TRIP_LIST,
      })
    );
    refreshList();
  };

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          overflow: 'auto',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          [theme.breakpoints.down('md')]: {
            display: 'flex',
          },
        }}
      >
        <CustomSelectFilter
          label={TripStatusFilterLabel.CREATED}
          oldValue={tripStatusValues as IFilterValue}
          setValue={setTripStatusValues}
          updatedValue={TripStatus.CREATED}
        />
        <CustomSelectFilter
          label={TripStatusFilterLabel.STARTED}
          oldValue={tripStatusValues as IFilterValue}
          setValue={setTripStatusValues}
          updatedValue={TripStatus.STARTED}
        />
        <CustomSelectFilter
          label={TripStatusFilterLabel.COMPLETED}
          oldValue={tripStatusValues as IFilterValue}
          setValue={setTripStatusValues}
          updatedValue={TripStatus.COMPLETED}
        />
        <SelectFilter
          label={TripLogisticsPartnerFilterLabel.ONE_BRIDGE}
          oldValue={logisticsPartnerFilterValues as IFilterValue[]}
          newValue={{
            label: TripLogisticsPartnerFilterLabel.ONE_BRIDGE,
            value: LogisticsPartner.ONE_BRIDGE,
          }}
          setValue={setSelectedLogisticsPartnerValues}
        />
        <SelectFilter
          label={TripLogisticsPartnerFilterLabel.TPL}
          oldValue={logisticsPartnerFilterValues as IFilterValue[]}
          newValue={{
            label: TripLogisticsPartnerFilterLabel.TPL,
            value: LogisticsPartner.TPL,
          }}
          setValue={setSelectedLogisticsPartnerValues}
        />
        <SelectFilter
          label={TripLogisticsPartnerFilterLabel.LOCAL}
          oldValue={logisticsPartnerFilterValues as IFilterValue[]}
          newValue={{
            label: TripLogisticsPartnerFilterLabel.LOCAL,
            value: LogisticsPartner.LOCAL,
          }}
          setValue={setSelectedLogisticsPartnerValues}
        />
      </Box>
    </Box>
  );
};
