import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { useState, SetStateAction, Dispatch } from 'react';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { GridRowId } from '@mui/x-data-grid';
import { tripShipmentListThunkActions } from '../../../../../../redux/trip-shipment-relation-list/thunk-actions';
import { SnakbarUtils } from '../../../../../../utils/snakbar-util';
import CustomDialogBox from '../../../../../../reusable-components/CustomDialogBox';

const RemoveShipmentButton = ({
  selectedRowsIds,
  tripId,
  setSelectedRowIds,
}: {
  selectedRowsIds: GridRowId[];
  tripId: string;
  setSelectedRowIds: Dispatch<SetStateAction<GridRowId[]>>;
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    let response: boolean | undefined = await dispatch(
      tripShipmentListThunkActions.removeShipmentFromTripAction({
        tripId: tripId,
        shipmentIds: selectedRowsIds as string[],
      })
    );

    if (response) {
      SnakbarUtils.showSuccessMessage('Shipment Removed successfully');
      setSelectedRowIds([]);
      closeDialog();
    }
    setIsSubmitting(false);
  };

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setOpen(false);
  };
  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        disabled={selectedRowsIds.length === 0}
      >
        Remove Shipment
      </Button>
      <CustomDialogBox
        open={open}
        closeDialog={closeDialog}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        buttonText="Yes, Sure"
        messageBody={`Are you sure, you want to remove selected shipments?`}
        hasIcon
      />
    </>
  );
};

export default RemoveShipmentButton;
