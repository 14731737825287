import { GridColDef } from '@mui/x-data-grid';
import { ShipmentListEntity } from '../../../data/model/trip/shipment';
import { DateUtils } from '../../../utils/date-util';
import { DisplayUtils } from '../../../utils/display-util';

export const getColumns: (
  rowModesModel: any,
  handleEditClick: any,
  handleCancelClick: any,
  handleSaveClick: any
) => GridColDef<ShipmentListEntity, any, any>[] = (
  rowModesModel,
  handleEditClick,
  handleCancelClick,
  handleSaveClick
) => [
  {
    field: 'short_tracking_id',
    headerName: 'Shipment ID',
    type: 'string',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    hideable: false,
    editable: false,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created Date',
    type: 'string',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return DateUtils.getFormattedDateString(params.value);
    },
  },
  {
    field: 'pick_up_address.full_address',
    headerName: 'Pickup Location',
    type: 'string',
    minWidth: 300,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return DisplayUtils.getFullAddress(params.value);
    },
    valueGetter(params) {
      return params.row.pick_up_address;
    },
  },
  {
    field: 'pick_up_address.poc_phone',
    headerName: 'Pickup Contact Details',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 175,
    valueFormatter(params) {
      return `${params.value.poc_name} (${params.value.poc_phone})`
    },
    valueGetter(params) {
      return params.row.pick_up_address;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'pick_up_address.pincode',
    headerName: 'Pickup Pincode',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.pick_up_address.pincode;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.full_address',
    headerName: 'Drop Location',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 300,
    valueFormatter(params) {
      return DisplayUtils.getFullAddress(params.value)
    },
    valueGetter(params) {
      return params.row.drop_address;
    },
    hideable: false,
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.poc_phone',
    headerName: 'Drop Contact Details',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueFormatter(params) {
      return `${params.value.poc_name} (${params.value.poc_phone})`
    },
    valueGetter(params) {
      return params.row.drop_address;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.pincode',
    headerName: 'Drop Pincode',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.drop_address.pincode;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Shipment Status',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.status;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'scheduled_drop_time',
    headerName: 'Est. Date of Delivery',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 150,
    valueFormatter(params) {
      return params.value
        ? DateUtils.getFormattedDateString(params.value)
        : 'NA';
    },
    valueGetter(params) {
      return params.row.scheduled_drop_time;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.weight',
    headerName: 'Net Weight',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueFormatter(params) {
      return DisplayUtils.weightFormatter(params.value ?? 0);
    },
    valueGetter(params) {
      return params.row.item_details.weight;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.quantity',
    headerName: 'Quantity',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.item_details.quantity;
    },
    editable: false,
    sortable: false,
  },
];
