import { AxiosResponse } from 'axios';
import { ListResponse } from '../model/interfaces';
import {
  CheckinListEntity,
  ReporteeListEntity,
} from '../model/geolocation/reportee';
import { commerceApiClient } from './commerceApiClient';
import { LinkReporteeRequest } from '../model/geolocation/link-reportee';
import { logisticsApiClient } from './logisticsApiClient';
import {
  getCheckinsUrl,
  getReporteesUrl,
  linkReporteeUrl,
} from './apiUrls/commerceApiUrls';
import { UserRoleData } from '../../constants/auth/userRoles';

export const fetchReporteeList = async (managerId: string, pageNumber = 1) => {
  const axiosResponse: AxiosResponse<ListResponse<ReporteeListEntity>> =
    await commerceApiClient.get(getReporteesUrl(managerId), {
      params: {
        page: pageNumber > 1 ? pageNumber : undefined,
      },
      meta: {
        userRole: UserRoleData.HUB_MANAGER,
      },
    });
  return axiosResponse.data;
};

export const linkReportee = async (
  linkReporteeRequest: LinkReporteeRequest
) => {
  const axiosResponse: AxiosResponse = await commerceApiClient.post(
    linkReporteeUrl(),
    linkReporteeRequest,
    {
      meta: {
        userRole: UserRoleData.HUB_MANAGER,
      },
    }
  );
  return axiosResponse.data;
};

export const fetchCheckinList = async (
  reporteeIds: string[],
  fromDate: string,
  toDate: string,
  userRole: 'AGENT' | string
) => {
  const axiosResponse: AxiosResponse<CheckinListEntity[]> =
    await logisticsApiClient.get(getCheckinsUrl(), {
      params: {
        user_ids: reporteeIds.join(','),
        from_date: fromDate,
        to_date: toDate,
        user_role: userRole,
      },
      meta: {
        userRole: UserRoleData.HUB_MANAGER,
      },
    });
  return axiosResponse.data;
};

export const unlinkReportee = async (
  linkReporteeRequest: LinkReporteeRequest
) => {
  const axiosResponse: AxiosResponse = await commerceApiClient.delete(
    linkReporteeUrl(),
    {
      data: linkReporteeRequest,
      meta: {
        userRole: UserRoleData.HUB_MANAGER,
      },
    }
  );
  return axiosResponse.data;
};
