import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import LinkedPincodePage from '../../modules/serviceAbility/pincode/PincodeListPage';

const ServiceAbilityRoutes: RouteObject = {
  path: 'hub/',
  element: <MainLayout />,
  children: [
    {
      path: 'pincodes',
      element: <LinkedPincodePage />,
    },
  ],
};

export default ServiceAbilityRoutes;
