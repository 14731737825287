import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Toolbar,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { read, utils } from 'xlsx';
import { BulkUploadPreviewTable } from './BulkUploadPreviewTable';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { TeamplateHeaders } from './constants';
import PageContainer from '../../../reusable-components/PageContainer';
import { SnakbarUtils } from '../../../utils/snakbar-util';
import { LinkReporteeRequest } from '../../../data/model/geolocation/link-reportee';
import { linkReportee } from '../../../data/api/reportee';
import { FileUpload, FileUploadProps } from '../FileUpload';

const ReporteeBulkUploadComponent = () => {
  const [relations, setRelations] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);

  const handleImport = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      try {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length > 0) {
            setRelations(rows as any);
          } else {
            SnakbarUtils.showErrorMessage('Nothing is there to add.');
          }
        }
      } catch (err: any) {
        SnakbarUtils.showErrorMessage(err.message);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const fileUploadProp: FileUploadProps = {
    accept:
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    onFileSelected: (file: File) => {
      handleImport(file);
    },
  };

  const handleUpload = async () => {
    setSubmitted(true);
    relations.forEach(async (sku: any) => {
      const request: LinkReporteeRequest = {
        manager_phone: sku[TeamplateHeaders.MANAGER_PHONE],
        reportee_phone: sku[TeamplateHeaders.REPORTEE_PHONE],
        manager_role: sku[TeamplateHeaders.MANAGER_ROLE],
        reportee_role: sku[TeamplateHeaders.REPORTEE_ROLE],
        reportee_name: sku[TeamplateHeaders.REPORTEE_NAME]
      };
      try {
        await linkReportee(request);
        setUploadedCount((prevCount) => prevCount + 1);
      } catch (e) {
        SnakbarUtils.showAxiosErrorMessage(e);
        setFailedCount((prevCount) => prevCount + 1);
      }
    });
  };

  const handleClear = async () => {
    setRelations([]);
  };

  const handleDone = async () => {
    setRelations([]);
    setSubmitted(false);
    setUploadedCount(0);
    setFailedCount(0);
  };

  if (submitted) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '30vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {relations.length > uploadedCount + failedCount ? (
          <CircularProgress />
        ) : undefined}
        <Typography variant="h6">Added : {uploadedCount}</Typography>
        {failedCount ? (
          <Typography variant="h6">Failed : {failedCount}</Typography>
        ) : undefined}
        {relations.length > uploadedCount + failedCount ? undefined : (
          <>
            <Typography>
              Uploaded linkings can take a few minutes to be visible
            </Typography>
            <Button
              sx={{
                mt: '30px',
              }}
              variant="contained"
              onClick={handleDone}
            >
              Done
            </Button>
          </>
        )}
      </Box>
    );
  }

  return (
    <PageContainer>
      <Toolbar
        sx={{
          pl: { xs: 2 },
          pr: { xs: 2 },
        }}
      >
        <Typography variant="h6">Link Reportees</Typography>
        <Box sx={{ width: 8 }} />
        <Box sx={{ flexGrow: 1 }} />
        {relations.length > 0 ? (
          <Button
            variant="outlined"
            size="small"
            onClick={handleUpload}
            startIcon={<FileUploadOutlinedIcon />}
          >
            Upload All
          </Button>
        ) : undefined}
      </Toolbar>
      {relations.length === 0 ? (
        <Box
          sx={{
            pl: { xs: 2 },
            pr: { xs: 2 },
          }}
        >
          <FileUpload {...fileUploadProp} />
          <br></br>
          <a href="../employee_linking_template.xlsx" target="_blank">
            <Button variant="contained">
              <span
                style={{
                  textDecoration: 'unset',
                }}
              >
                Download Template
              </span>
            </Button>
          </a>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              pl: { xs: 2 },
              pr: { xs: 2 },
              display: 'flex',
              pt: 2,
            }}
          >
            <Typography variant="button">
              {relations.length} relations linked
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={handleClear}
              startIcon={<CancelOutlinedIcon />}
            >
              Clear
            </Button>
          </Box>
          <Divider />
          <BulkUploadPreviewTable skuItems={relations} />
        </Box>
      )}
    </PageContainer>
  );
};

export default ReporteeBulkUploadComponent;
