import { LoadingStatus } from '../../data/model/interfaces';
import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import { deliveryAgentListSelectors } from './selectors';
import {
  getDeliveryAgentList,
  linkDeliveryAgentToHub,
} from '../../data/api/deliveryAgent';
import { getQueryParams } from '../../utils/qurey-param-util';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { OnboardUserRequest } from '../../data/model/auth/onboard-user';
import { UserRoleData } from '../../constants/auth/userRoles';
import { onboardUser } from '../../data/api/profile';

const fetchList = (forceReload = false): AppThunk => {
  return async (dispatch, getState) => {
    const hubId = getState().sharedPref.selectedHubId;
    if (
      deliveryAgentListSelectors.selectLoadingStatus(getState()) ===
        LoadingStatus.LOADING ||
      hubId == null
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.DELIVERY_AGENT_LIST));
    }
    if (
      deliveryAgentListSelectors.selectListItems(getState()).length <
        deliveryAgentListSelectors.selectPageSize(getState()) *
          deliveryAgentListSelectors.selectPageNumber(getState()) ||
      deliveryAgentListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.DELIVERY_AGENT_LIST,
          })
        );
        const deliveryAgentListApiResponse = await getDeliveryAgentList({
          pageNumber: deliveryAgentListSelectors.selectPageNumber(getState()),
          hubId: hubId,
          role: UserRoleData.AGENT,
          queryParams: getQueryParams(
            deliveryAgentListSelectors.selectFilters(getState())
          ),
        });
        dispatch(
          listActions.setListResponse({
            listItems: deliveryAgentListApiResponse.results.map(
              (item) => item.user_id
            ),
            count: deliveryAgentListApiResponse.count,
            key: listSateKeys.DELIVERY_AGENT_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: deliveryAgentListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.user_id,
            })),
            key: entityStateKeys.DELIVERY_AGENT_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.DELIVERY_AGENT_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.DELIVERY_AGENT_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (
      deliveryAgentListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      deliveryAgentListSelectors.selectCount(getState()) >
      deliveryAgentListSelectors.selectPageSize(getState()) *
        deliveryAgentListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber:
            deliveryAgentListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.DELIVERY_AGENT_LIST,
        })
      );
      dispatch(fetchList());
    }
  };
};

const reloadListCurrentPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: deliveryAgentListSelectors.selectPageNumber(getState()),
        key: listSateKeys.DELIVERY_AGENT_LIST,
      })
    );
    dispatch(fetchList());
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (deliveryAgentListSelectors.selectPageNumber(getState()) > 1) {
      dispatch(
        listActions.setPageNumber({
          pageNumber:
            deliveryAgentListSelectors.selectPageNumber(getState()) - 1,
          key: listSateKeys.DELIVERY_AGENT_LIST,
        })
      );
    }
  };
};

export const addDeliveryAgentAction =
  (
    requestBody: OnboardUserRequest
  ): AppThunk<Promise<boolean | undefined>> =>
  async (_dispatch, getState) => {
    try {
      const { selectedHubId } = getState().sharedPref;
      const userData = await onboardUser(requestBody);
      if(userData) {
        await linkDeliveryAgentToHub({userRole: userData, hubId: selectedHubId ?? ''});
        return true;
      }
    } catch (error) {
      console.log(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };

export const deliveryAgentListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
  addDeliveryAgentAction,
};
