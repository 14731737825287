import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColumns } from './shipment-list-table-data';
import {
  DataGrid,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import CreateTripButton from './CreateTripButton';
import AddToExistingTripButton from './AddToExistingTripButton';
import { CreateShipmentButton } from './CreateShipmentButton';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { ShipmentListEntity } from '../../../../data/model/trip/shipment';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { shipmentListSelectors } from '../../../../redux/shipment-list/selectors';
import { shipmentListThunkActions } from '../../../../redux/shipment-list/thunk-actions';
import PageContainer from '../../../../reusable-components/PageContainer';
import ListDataGridToolbar from '../../../../reusable-components/data-grid/ListDataGridToolbar';
import { UserRoleData } from '../../../../constants/auth/userRoles';
import { ShipmentFilters } from './ShipmentFilters';
import { listActions } from '../../../../redux/lists/reducer';
import {
  ShipmentTripAssignedFilter,
  ShipmentTripAssignedLabel,
} from '../../../../constants/trips/trip-list';
import { listSateKeys } from '../../../../redux/lists/constants';
import { getQueryParams } from '../../../../utils/qurey-param-util';
import { store } from '../../../../redux/store';
import Box from '@mui/material/Box/Box';
import SelectHubContainer from '../../../dashbord/SelectHubContainer';

function ShipmentListPage() {
  const location = useLocation();
  const forceReload = location.state?.forceReload ?? false;
  const shipmentList = useAppSelector(shipmentListSelectors.selectPageItems);
  const shipmentCount = useAppSelector(shipmentListSelectors.selectCount);
  const shipmentPageSize = useAppSelector(shipmentListSelectors.selectPageSize);
  const shipmentPageNumber = useAppSelector(
    shipmentListSelectors.selectPageNumber
  );
  const shipmentListLoadingStatus = useAppSelector(
    shipmentListSelectors.selectLoadingStatus
  );

  const loginProfile = useAppSelector(
    (state) => state.sharedPref.authData
  )?.profile;
  const userName =
    loginProfile?.LOGISTICS_ADMIN?.data?.profile_name ||
    loginProfile?.HUB_MANAGER?.data?.profile_name ||
    '';

  const userContactDetail =
    loginProfile?.LOGISTICS_ADMIN?.data?.user_profile?.phone ||
    loginProfile?.HUB_MANAGER?.data?.user_profile?.phone ||
    '';

  const { selectedHubName } = useAppSelector((state) => state.sharedPref);
  const selectedUserRole = store.getState().sharedPref.selectedUserRole;

  const isFilterSelected = getQueryParams(
    shipmentListSelectors.selectFilters(store.getState())
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);
  const userRole = useAppSelector((state) => state.sharedPref.selectedUserRole);

  const [selectedRowsIds, setSelectedRowIds] = useState<number[]>([]);

  useEffect(() => {
    if (shipmentListSelectors.selectListItems(store.getState()).length === 0) {
      dispatch(
        listActions.setFilterValue({
          filterValue: {
            label: ShipmentTripAssignedLabel.UNASSIGNED,
            value: false,
          },
          filterKey: ShipmentTripAssignedFilter.FILTER_KEY,
          key: listSateKeys.SHIPMENT_LIST,
        })
      );
    }

    dispatch(shipmentListThunkActions.fetchList(forceReload));
  }, [dispatch, forceReload, hubId]);

  const fetchNextPage = () => {
    dispatch(shipmentListThunkActions.fetchListNextPage());
  };

  const fetchPreviousPage = () => {
    dispatch(shipmentListThunkActions.fetchListPreviousPage());
  };

  const refresh = () => {
    dispatch(shipmentListThunkActions.fetchList(true));
  };


  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: GridRowParams<ShipmentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > shipmentPageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < shipmentPageNumber - 1) {
      fetchPreviousPage();
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams<ShipmentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    navigate(`/shipments/${params.id}`);
  };

  return (
    <SelectHubContainer>
          <PageContainer>
      <DataGrid<ShipmentListEntity>
        checkboxSelection
        onRowSelectionModelChange={(selectedRow: GridRowId[]) =>
          setSelectedRowIds(selectedRow as number[])
        }
        columns={getColumns(
          rowModesModel,
          userName ?? '',
          selectedHubName ?? '',
          userContactDetail ?? '',
          selectedUserRole ?? ''
        )}
        rows={shipmentList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        pagination
        keepNonExistentRowsSelected
        editMode="row"
        getRowId={(row) => row.short_tracking_id}
        paginationModel={{
          page: shipmentPageNumber - 1,
          pageSize: shipmentPageSize,
        }}
        pageSizeOptions={[]}
        rowCount={shipmentCount}
        paginationMode="server"
        loading={shipmentListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            titleSuffix:
              userRole === UserRoleData.LOGISTICS_ADMIN ? (
                <CreateShipmentButton />
              ) : undefined,
            refresh,
            prefixRefreshButton: (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <AddToExistingTripButton
                  selectedIds={selectedRowsIds}
                  refresh={refresh}
                />
                <div style={{ margin: '8px' }}></div>
                <CreateTripButton
                  selectedIds={selectedRowsIds}
                  refresh={refresh}
                />
              </Box>
            ),
            filterComponent: <ShipmentFilters onFilterChange={refresh} />,
          },
        }}
        onPaginationModelChange={handlePaginationModelChange}
        onRowEditStop={handleRowEditStop}
        //   processRowUpdate={processRowUpdate}
        rowSelection={true}
        onRowClick={handleRowClick}
        getRowHeight={() => 'auto'}
      />
    </PageContainer>
    </SelectHubContainer>
  );
}

export default ShipmentListPage;
