import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ViewCheckinsButton = ({
  reporteeId,
  role,
  reporteeName
}: {
  reporteeId: string;
  role: string;
  reporteeName: string;
}) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate(`/employee-management/${role}/${reporteeId}/checkins`,{
      state: {
        userName: reporteeName,
      },
    });
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="medium"
        onClick={handleSubmit}
      >
        View Checkins
      </Button>
    </>
  );
};
