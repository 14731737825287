import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { fetchServicablePincodeList } from '../../../../redux/pincode/thunk-actions';
import { PageLoading } from '../../../../reusable-components/PageLoading';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';
import LinkPincodeForm from '../AddPincodeForm';
import { PincodeListTable } from '../PincodeListTable';
import Card from '@mui/material/Card/Card';
import SelectHubContainer from '../../../dashbord/SelectHubContainer';

function LinkedPincodePage() {
  const dispatch = useAppDispatch();

  const { selectedHubId } = useAppSelector((state) => state.sharedPref);

  const [linkedPincodeList, setLinkedPincodeList] = useState<string[]>([]);
  const [pincodeListLoadingStatus, setPincodeListLoadingStatus] = useState(
    LoadingStatus.IDLE
  );

  const getServicablePincode = async (hubId: string) => {
    if (pincodeListLoadingStatus === LoadingStatus.LOADING) {
      return;
    }

    setPincodeListLoadingStatus(LoadingStatus.LOADING);

    let response = await dispatch(fetchServicablePincodeList(hubId));

    if (response) {
      setLinkedPincodeList(response);
      setPincodeListLoadingStatus(LoadingStatus.IDLE);
    } else {
      setPincodeListLoadingStatus(LoadingStatus.FAILED);
    }
  };

  useEffect(() => {
    if (selectedHubId) {
      getServicablePincode(selectedHubId!);
    }
  }, [dispatch, selectedHubId]);

  if (pincodeListLoadingStatus === LoadingStatus.LOADING) {
    return (
      <Box>
        <PageLoading />
      </Box>
    );
  }
  return (
    <SelectHubContainer>
      <Box sx={{ m: 2 }}>
        <LinkPincodeForm
          refresh={() => {
            getServicablePincode(selectedHubId!);
          }}
        />
        <Box sx={{ height: '100px' }} />
        <Card>
          <Typography variant="h6" sx={{ paddingLeft: 2 }}>
            Servicable Pincodes
          </Typography>
          <PincodeListTable
            linkedPincode={linkedPincodeList}
            setLinkedPincodeList={setLinkedPincodeList}
          />
        </Card>
      </Box>
    </SelectHubContainer>
  );
}

export default LinkedPincodePage;
