import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import { AuthenticationStatus } from '../../redux/login/type';
import { authenticationStatusSelector } from '../../redux/login/selectors';

export function UnAuthenticatedLayout() {
  const authenticationStatus = useAppSelector(authenticationStatusSelector);

  const selectedHubId = useAppSelector(
    (state) => state.sharedPref.selectedHubId
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      authenticationStatus === AuthenticationStatus.AUTHENTICATED &&
      selectedHubId
    ) {
      navigate('/');
    } else if (
      !selectedHubId &&
      authenticationStatus === AuthenticationStatus.AUTHENTICATED
    ) {
      navigate('/selectHub');
    }
  }, [authenticationStatus, selectedHubId]);
  return <Outlet />;
}

export default UnAuthenticatedLayout;
