import { GridColDef, GridRowModesModel } from '@mui/x-data-grid';
import { ShipmentListEntity } from '../../../../data/model/trip/shipment';
import { DisplayUtils } from '../../../../utils/display-util';
import { DateUtils } from '../../../../utils/date-util';
import { ShipmentStatus } from '../../../../constants/trips/trip-list';
import { UserRoleData } from '../../../../constants/auth/userRoles';
import { Typography } from '@mui/material';

export const getColumns: (
  rowModesModel: GridRowModesModel,
  userName: string,
  selectedHubName: string,
  userContactDetail: string,
  selectedUserRole: string
) => GridColDef<ShipmentListEntity, any, any>[] = (
  rowModesModel,
  userName,
  selectedHubName,
  userContactDetail,
  selectedUserRole
) => [
  {
    field: 'short_tracking_id',
    headerName: 'Shipment ID',
    type: 'string',
    minWidth: 100,
    align: 'center',
    headerAlign: 'center',
    hideable: false,
    editable: false,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created Date',
    type: 'string',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return DateUtils.getFormattedDateString(params.value);
    },
  },
  {
    field: 'pick_up_address.full_address',
    headerName: 'Pickup Location',
    type: 'string',
    minWidth: 300,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    editable: false,
    sortable: false,
    valueFormatter(params) {
      const status = params.value.status;
      const address =
        status === ShipmentStatus.CREATED ||
        status === ShipmentStatus.READY_FOR_PICKUP ||
        selectedUserRole === UserRoleData.LOGISTICS_ADMIN
          ? DisplayUtils.getFullAddress(params.value.pick_up_address)
          : selectedHubName;

      return address;
    },
    valueGetter(params) {
      return params.row;
    },
  },
  {
    field: 'pick_up_address.poc_phone',
    headerName: 'Pickup Contact Details',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 175,
    renderCell(params) {
      const status = params.row.status;
      const { poc_name, poc_phone } = params.row.pick_up_address;
      const shouldShowLogisticsInfo =
        status === ShipmentStatus.CREATED ||
        status === ShipmentStatus.READY_FOR_PICKUP ||
        selectedUserRole === UserRoleData.LOGISTICS_ADMIN;

      const name = shouldShowLogisticsInfo ? poc_name : userName;
      const contactDetail = shouldShowLogisticsInfo
        ? poc_phone
        : userContactDetail;

      return (
        <Typography variant="body2">
          <div>{name}</div>
          <div>{contactDetail}</div>
        </Typography>
      );
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.full_address',
    headerName: 'Drop Location',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    minWidth: 300,
    valueFormatter(params) {
      return DisplayUtils.getFullAddress(params.value);
    },
    valueGetter(params) {
      return params.row.drop_address;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.poc_phone',
    headerName: 'Drop Contact Details',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 150,
    renderCell(params) {
      const { poc_name, poc_phone } = params.row.drop_address;

      return (
        <Typography variant="body2">
          <div>{poc_name}</div>
          <div>{poc_phone}</div>
        </Typography>
      );
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Shipment Status',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.status;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'scheduled_drop_time',
    headerName: 'Est. Date of Delivery',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 150,
    valueFormatter(params) {
      return params.value
        ? DateUtils.getFormattedDateString(params.value)
        : 'NA';
    },
    valueGetter(params) {
      return params.row.scheduled_drop_time;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.weight',
    headerName: 'Net Weight',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueFormatter(params) {
      return DisplayUtils.weightFormatter(params.value ?? 0);
    },
    valueGetter(params) {
      return params.row.item_details.weight;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.quantity',
    headerName: 'Quantity',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 120,
    valueGetter(params) {
      return params.row.item_details.quantity;
    },
    editable: false,
    sortable: false,
  },
];
