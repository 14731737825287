import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dialogTransition } from '../../../../utils/transitions';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { LoadingButton } from '@mui/lab';
import { unlinkReportee } from '../../../../data/api/reportee';

export const UnlinkReporteeButton = ({
  managerPhone,
  reporteePhone,
  managerRole,
  reporteeRole,
  refresh
}: {
  managerPhone: string;
  reporteePhone: string;
  managerRole: string;
  reporteeRole: string;
  refresh: Function
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      let response = await unlinkReportee({
        reportee_phone: reporteePhone,
        manager_phone: managerPhone,
        manager_role: managerRole,
        reportee_role: reporteeRole,
      });
      closeDialog();
      refresh();
    } catch (err) {}

    setIsSubmitting(false);
  };

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setIsSubmitting(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={() => {
          setOpen(true);
        }}
      >
        Unlink
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            m: 4,
          }}
        >
          <WarningAmberRoundedIcon
            sx={{
              fontSize: '64px',
              color: theme.palette.warning.dark,
              mb: 1,
            }}
          />
          <Typography variant="h4">Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmit}
            variant="contained"
          >
            Yes Unlink
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
