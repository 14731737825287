import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../../../../reusable-components/CustomButton';

function DepositIdButton({ depositId }: { depositId: number }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`./${depositId}`);
  };
  return (
    <CustomButton
      buttonText={depositId.toString()}
      handleClick={handleClick}
      variant="text"
    />
  );
}

export default DepositIdButton;
