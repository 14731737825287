import { ClientTPLDetails } from '../data/model/trip/trip';
import { Address } from '../data/model/trip/shipment';
import { PaymentStatus } from '../constants/trips/payment';
import {
  LogisticsPartner,
  ShipmentTrailConstants,
  TripLogisticsPartnerFilterLabel,
} from '../constants/trips/trip-list';
import { DateUtils } from './date-util';
import { CashDepositStatus } from '../constants/cashDeposit/cashDeposit';
import { convertPaisaToRupee } from './currenty-util';

export class DisplayUtils {
  static removeUnderscoreConvertInSmallcase = (data: string) => {
    const splittedData = data.split('_');
    let finalWord = '';
    for (let word in splittedData) {
      if (splittedData[word] === 'DA') {
        finalWord += splittedData[word];
        continue;
      }
      finalWord +=
        ' ' +
        splittedData[word].charAt(0) +
        splittedData[word].substring(1).toLowerCase() +
        ' ';
    }
    return finalWord;
  };

  static removeUnderscoreConvertInUppercase = (data: string) => {
    return data.replaceAll('_', ' ');
  };

  static weightFormatter = (weight: number) => {
    let weightString = '';
    if (weight === 0) {
      return (weightString = 'NA');
    }
    if (weight > 1000) {
      weightString = `${weight / 1000} Kg`;
    } else {
      weightString = `${weight} Gms`;
    }
    return weightString;
  };

  static dimentionStringFormatter = ({
    length,
    breadth,
    height,
  }: {
    length: number;
    breadth: number;
    height: number;
  }) => {
    let text = '';
    if (length && breadth && height) {
      return (text = `${length} * ${breadth} * ${height}`);
    } else {
      return (text = `l * b * h`);
    }
  };

  static shipmentPaymentStatusString = (input: boolean) => {
    switch (input) {
      case true:
        return 'UN_PAID';
      case false:
        return 'PAID';
    }
  };

  static getFullAddress = (address: Address) => {
    let prettyAddress = '';
    if (address.full_address) {
      prettyAddress += address.full_address;
    }

    if (address.district) {
      if (prettyAddress) {
        prettyAddress += `, ${address.district}`;
      } else {
        prettyAddress += address.district;
      }
    }

    if (address.state) {
      if (prettyAddress) {
        prettyAddress += `, ${address.state}`;
      } else {
        prettyAddress += address.state;
      }
    }

    if (address.country) {
      if (prettyAddress) {
        prettyAddress += `, ${address.country}`;
      } else {
        prettyAddress += address.country;
      }
    }

    if (address.pincode) {
      if (prettyAddress) {
        prettyAddress += `, ${address.pincode}`;
      } else {
        prettyAddress += address.pincode;
      }
    }
    return DisplayUtils.removeDuplicatesFromString(prettyAddress);
  };

  static removeDuplicatesFromString(prettyAddress: string) {
    const values = prettyAddress.split(',');
    const uniqueValues: string[] = [];

    for (const value of values) {
      if (!uniqueValues.includes(value)) {
        uniqueValues.push(value);
      }
    }
    return uniqueValues.join(',');
  }

  static paymentStatusString = (paymentStatus: string) => {
    switch (paymentStatus) {
      case PaymentStatus.PAID:
        return 'DONE';
      case PaymentStatus.UNPAID:
        return 'PENDING';
      case PaymentStatus.COLLECTED:
        return 'COLLECTED';
    }
  };

  static cashDepositStatusString = (status: string) => {
    switch (status) {
      case CashDepositStatus.CREATED:
        return 'PENDING';
      case CashDepositStatus.VERIFIED:
        return 'APPROVED';
      case CashDepositStatus.DRAFTED:
        return 'DRAFTED';
    }
  };

  static getLogisticsPartnerName = ({
    logisticsPartner,
    clientTPLDetails,
  }: {
    logisticsPartner: string;
    clientTPLDetails: ClientTPLDetails;
  }) => {
    switch (logisticsPartner) {
      case LogisticsPartner.ONE_BRIDGE:
        return TripLogisticsPartnerFilterLabel.ONE_BRIDGE;
      case LogisticsPartner.TPL:
        return clientTPLDetails.delivery_partner_name;
      default:
        return TripLogisticsPartnerFilterLabel.LOCAL;
    }
  };

  static getShipmentTrailText({
    status,
    createdTime,
    hubName,
  }: {
    status: string;
    createdTime: string;
    hubName: string;
  }) {
    switch (status) {
      case ShipmentTrailConstants.SHIPMENT_CREATED:
        return `Shipment was created on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.SHIPMENT_READY_FOR_PICKUP:
        return `Shipment marked ready for pickup on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.SHIPMENT_OUT_FOR_PICKUP:
        return '';
      case ShipmentTrailConstants.SHIPMENT_PICKED:
        return `Shipment was picked from origin on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.LEFT_HUB:
        return `Shipment left ${hubName} on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.REACHED_HUB:
        return `Shipment reached ${hubName} on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.SHIPMENT_OUT_FOR_DELIVERY:
        return '';
      case ShipmentTrailConstants.SHIPMENT_DELIVERED:
        return `Shipment was delivered on ${DateUtils.getFormattedDateTimeString(
          createdTime
        )}`;
      case ShipmentTrailConstants.SHIPMENT_CANCELLED:
        return 'Shipment was cancelled';
      case ShipmentTrailConstants.CUSTOMER_REJECTED:
        return `Shipment was rejected by customer`;
    }
  }

  static amountDepositString = (amount: number) => {
    const formattedAmount = convertPaisaToRupee(amount);
    return `Amount to be Deposited ${formattedAmount}`;
  }
}
