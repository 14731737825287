export const ordersUrl = '/api/v1/orders/';
export const orderDetailUrl = (orderId: string) => `/api/v1/orders/${orderId}`;
export const orderAcceptUrl = (orderId: string) =>
  `/api/v1/orders/${orderId}/accept`;
export const orderReadyUrl = (orderId: string) =>
  `/api/v1/orders/${orderId}/accept`;
export const orderCompleteUrl = (orderId: string) =>
  `/api/v1/orders/${orderId}/complete`;
export const orderCancelUrl = (orderId: string) =>
  `/api/v1/orders/${orderId}/cancel`;
export const orderDownloadUrl = `/api/v1/orders/download`;
///Activites
export const activitiesUrl = '/api/v1/gamification/activities';
export const activitiesDetailUrl = (activityId: string) =>
  `/api/v1/gamification/activities/${activityId}`;

export const activateActivitiesDetailUrl = (activityId: string) =>
  `/api/v1/gamification/activities/${activityId}/activate`;
///Rewards
export const rewardsUrl = '/api/v1/gamification/rewards';
export const rewardsDetailUrl = (activityId: string) =>
  `/api/v1/gamification/rewards/${activityId}`;

export const activateRewardsUrl = (activityId: string) =>
  `/api/v1/gamification/rewards/${activityId}/activate`;

// Cluster
export const clusterDetailsUrl = (clusterId: string) =>
  `/api/v1/clusters/${clusterId}`;
export const clustersUrl = '/api/v1/clusters';
// SalesMan
export const salesManListUrl = (clusterId: string) =>
  `api/v1/salesman/?cluster_id=${clusterId}`;
export const salesManCustomerDetailUrl = (salesManId: string) =>
  `/api/v1/salesman/${salesManId}/customers`;
export const searchNonLinkedCustomersUrl = (clusterId: string) =>
  `/api/v1/salesman/unlinked-customers?cluster_id=${clusterId}`;
export const homeSearchUrl = (clusterId: string, searchString: string) =>
  `/api/v1/global_search/home?search_string=${searchString}&cluster_id=${clusterId}`;
// Kutumb

export const kutumbsUrl = '/api/v1/kutumbs/';
export const bapsUrl = '/api/v1/baps/';
export const fossUrl = '/api/v1/foss/';
export const geographyUrl = '/api/v1/geographys/';

//business
export const businessesDetailsUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}`;
export const getBusinessesUrl = `/api/v1/businesses/`;
export const getBusinessSkusUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/skus`;
export const updateBusinnessSkuUrl = (
  businessId: string,
  productId: string,
  skuId: string
) =>
  `/api/v1/businesses/${businessId}/catalog/products/${productId}/skus/${skuId}`;
export const bulkUpdateCatalogueUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products/bulk`;
export const getProductsForBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products`;
export const categoriesForBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/categories`;
export const productDetailsUrl = (businessId: string, productId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products/${productId}`;
export const productsForCategoryUrl = (
  businessId: string,
  categoryId: string
) =>
  `/api/v1/businesses/${businessId}/catalog/categories/${categoryId}/products`;
export const categoriesForProductUrl = (
  businessId: string,
  categoryId: string
) =>
  `/api/v1/businesses/${businessId}/catalog/products/${categoryId}/categories`;
export const postAddSkuToProduct = (businessId: string, productId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products/${productId}/skus`;

export const postAddProductToBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products/full`;

export const patchUpdateProductToBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products/full`;

export const patchCategoriesForBusinessUrl = (
  businessId: string,
  categoryId: string
) => `/api/v1/businesses/${businessId}/catalog/categories/${categoryId}`;

export const getAllProdcutsForBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/catalog/products`;

export const getDeliveryAutomationConfigurationsForBusinessUrl = (
  businessId: string
) => `/api/v1/businesses/${businessId}/deliveryautomation`;

export const getBusinessCategoriesUrl = '/api/v1/businesses/bcats';

export const updatePulishStatusUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/approve`;

export const markBusinessOpenUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/open`;
export const markBusinessClosedUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/close`;
export const updateBusinessAddressUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/address`;

export const createBusinessUrl = '/api/v1/businesses/';
export const switchMerchantAccountUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/switch`;

export const offerUrl = `/api/v1/offers/`;
export const updateOfferListForBusinessUrl = (
  businessId: string,
  promoCode: string
) => `/api/v1/offers/${promoCode}/business/${businessId}`;
export const updateOfferDetailsUrl = (promoCode: string) =>
  `/api/v1/offers/${promoCode}`;

export const updateOfferStatusToClusterBusinesses = (
  promoCode: string,
  clusterId: string
) => `/api/v1/offers/${promoCode}/cluster/${clusterId}`;

export const referralUrl = (clusterId: string) =>
  `/api/v1/offers/referrals/gcu/cluster/${clusterId}`;

export const activeReferralUrl = (clusterId: string) =>
  `/api/v1/offers/referrals/cluster/${clusterId}/active`;

export const getOffersForBusinessUrl = (businessId: string) =>
  `/api/v1/businesses/${businessId}/offers`;

export const hubsUrl = '/api/v1/hubs/';

//shipments Url
export const shipmentUrl = '/api/v1/shipments/';
export const shipmentDetailUrl = (shipmentId: string) =>
  `/api/v1/shipments/${shipmentId}`;
export const shipmentPaymentDetailUrl = (shipmentId: string) =>
  `/api/v1/payments/shipment/${shipmentId}`;

// trips Url
export const tripsUrl = '/api/v1/trips/';
export const tripShipmentRelationUrl = (tripId: string) =>
  `/api/v1/trips/${tripId}/shipments`;
export const updateTripShipmentDetailUrl = (
  tripId: string,
  shipmentId: string
) => `/api/v1/trips/${tripId}/shipments/${shipmentId}`;

export const tripDetailUrl = (tripId: string) => `/api/v1/trips/${tripId}`;

// payment Url
export const paymentDetailUrl = (tripId: string) =>
  `/api/v1/payments/trip/${tripId}/stats`;
export const paymentHandoverDetailsUrl = (tripId: string) =>
  `/api/v1/payments/handovers/trip/${tripId}`;

//deliveryAgent Url
export const deliveryAgentUrl = (hubId: string) => `/api/v1/hubs/${hubId}/user`;

//shipment trial Url

export const getShipmentTrailUrl = (shipmentId: string) =>
  `/api/v1/trail/shipment/${shipmentId}`;
export const getAddressListUrl = '/api/v1/address/';
export const getServicablePincodeListUrl = (hubId: string) =>
  `/api/v1/hubs/${hubId}/pincodes`;

//cashDepositUrls

export const cashDepositsListUrl = '/api/v1/payments/deposits';
export const updateCashDepositsDetailUrl = (depositId: string) =>
  `/api/v1/payments/deposits/${depositId}`;

export const depositRequestVerificationUrl = (depositId: string) =>
  `/api/v1/payments/deposits/${depositId}/request-verification`;
export const getPaymentStatsUrl = '/api/v1/payments/stats';

export const depositDetailUrl = (depositId: string) =>
  `/api/v1/payments/deposits/${depositId}`;

export const depositHandoverDetailUrl = (depositId: string) =>
  `/api/v1/payments/deposits/${depositId}/handovers`;

//onboard agent or hub_manager

export const onboardLogisticsUserUrl = '/api/v1/auth/profile/onboard';
