import { Box, IconButton, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { convertPaisaToRupee } from '../../../../../utils/currenty-util';

export function TripCloseDialogBox({
  amountToCollect,
  assignedDa,
  closeDialog,
  cashReceived,
}: {
  amountToCollect: number;
  assignedDa: string;
  closeDialog: () => void;
  cashReceived: (value: boolean) => void;
}) {
  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          End the Trip
        </Typography>
        <IconButton onClick={closeDialog}>
          <ClearOutlinedIcon />
        </IconButton>
      </div>
      <p>
        {`By ending the trip you confirm that you collected `}
        <strong>{convertPaisaToRupee(amountToCollect)}</strong>
        {` from `}
        <strong>{assignedDa}</strong>.
      </p>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          onChange={(e) => {
            cashReceived(e.target.checked);
          }}
        />
        <span style={{ marginLeft: '0.5rem', color: 'rega(104,103,103)' }}>
          {`Yes, I have received `}
          <strong>{convertPaisaToRupee(amountToCollect)}</strong>
        </span>
      </label>
    </Box>
  );
}

export function ConfirmActionContent({
  text,
  closeDialog,
}: {
  text: string;
  closeDialog: () => void;
}) {
  return (
    <Box
      sx={{
        textAlign: 'right',
      }}
    >
      <IconButton onClick={closeDialog}>
        <ClearOutlinedIcon />
      </IconButton>
      <Box height="8px" />
      <Typography
        variant="h5"
        sx={{
          textAlign: 'left',
        }}
      >{`Are you sure you want to ${text} the trip?`}</Typography>
    </Box>
  );
}

export function ShipmentNotCompleteDialogBox({
  assignedDa,
  closeDialog,
}: {
  assignedDa: string;
  closeDialog: () => void;
}) {
  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ color: 'rgba(128,36,29)' }}>
          Shipment not completed
        </Typography>
        <IconButton onClick={closeDialog}>
          <ClearOutlinedIcon />
        </IconButton>
      </div>
      <Box sx={{ color: 'rgba(85,24,19)' }}>
        <p>
          {`Sorry, this trip cannot be completed as `}
          <strong>{assignedDa}</strong>
          {` has not completed all the shipment`}
        </p>
      </Box>
    </Box>
  );
}
