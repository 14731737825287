import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../reusable-components/data-driven-forms';

const schema = {
  fields: [
    {
      label: 'Delivery Agent Name',
      name: 'profile_name',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Phone Number',
      name: 'phone_number',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type AddDeliveryAgentFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
