import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';

const VehicleRoutes: RouteObject = {
  path: 'vehicles/',
  element: <MainLayout />,
  children: [
  ],
};

export default VehicleRoutes;
