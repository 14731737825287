import { createSlice } from '@reduxjs/toolkit';
import {
  setAuthData,
  setLoaded,
  setSelectedBusinessId,
  setSelectedHubId,
  setSelectedHubName,
  setSelectedUserRole,
} from './actions';
import { SharedPrefState } from './type';
import { UserRoleData } from '../../constants/auth/userRoles';
import { clearAction } from '../login/reducer';

const initialState: SharedPrefState = {
  authData: null,
  loaded: false,
  selectedUserRole: UserRoleData.HUB_MANAGER,
  selectedClusterId: null,
  selectedBusinessId: null,
  selectedHubId: null,
  selectedHubName: null,
};

const slice = createSlice({
  name: 'sharedPrefState',
  initialState,
  reducers: {
    setAuthData,
    setLoaded,
    setSelectedUserRole,
    setSelectedBusinessId,
    setSelectedHubId,
    setSelectedHubName,
  },

  extraReducers: (builder) => {
    builder.addCase(clearAction, (state) => {
      return initialState;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;
