import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import CashDepositDetailPage from '../../modules/cash-management/cash-deposit/DepositDetailPage';
import HubCashDepositDetailPage from '../../modules/cash-management/cash-deposit/HubCashDepositPage';

const CashManagementRoutes: RouteObject = {
  path: 'payment/',
  element: <MainLayout />,
  children: [
    {
      path: 'deposits',
      element: <HubCashDepositDetailPage />,
    },
    {
      path: 'deposits/:depositId',
      element: <CashDepositDetailPage />,
    },
  ],
};

export default CashManagementRoutes;
