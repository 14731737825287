import { AxiosResponse } from 'axios';
import { logisticsApiClient } from './logisticsApiClient';
import { ListResponse } from '../model/interfaces';
import { HubDetailEntity } from '../model/trip/hub';
import { hubsUrl, clusterDetailsUrl } from './apiUrls/logisticsApiUrls';

export const searchhub = async (searchString: string) => {
  const axiosResponse: AxiosResponse<ListResponse<HubDetailEntity>> =
    await logisticsApiClient.get(hubsUrl, {
      params: {
        search_query: searchString,
      },
    });
  return axiosResponse.data;
};

export const getClusterById = async (clusterId: string) => {
  const axiosResponse: AxiosResponse<any> = await logisticsApiClient.get(
    clusterDetailsUrl(clusterId)
  );
  return axiosResponse.data;
};
