import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import AssignHubButton from './AssignToHubButton';
import { LoadingStatus } from '../../../data/model/interfaces';
import { ShipmentListEntity } from '../../../data/model/trip/shipment';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { unAssignedShipmentListSelectors } from '../../../redux/unassignedshipment-list/selectors';
import { unAssignedShipmentListThunkActions } from '../../../redux/unassignedshipment-list/thunk-actions';
import PageContainer from '../../../reusable-components/PageContainer';
import ListDataGridToolbar from '../../../reusable-components/data-grid/ListDataGridToolbar';
import { getColumns } from './unassigned-shipment-table-data';

function UnAssignedShipmentListPage() {
  const location = useLocation();
  const forceReload = location.state?.forceReload ?? false;
  const unAssignedShipmentList = useAppSelector(unAssignedShipmentListSelectors.selectPageItems);
  const unAssignedShipmentCount = useAppSelector(unAssignedShipmentListSelectors.selectCount);
  const unAssignedShipmentPageSize = useAppSelector(unAssignedShipmentListSelectors.selectPageSize);
  const unAssignedShipmentPageNumber = useAppSelector(
    unAssignedShipmentListSelectors.selectPageNumber
  );
  const unAssignedShipmentListLoadingStatus = useAppSelector(
    unAssignedShipmentListSelectors.selectLoadingStatus
  );

  const title = 'Unassigned Shipment List';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  useEffect(() => {
    dispatch(unAssignedShipmentListThunkActions.fetchList(forceReload));
  }, [dispatch, forceReload, hubId]);

  const fetchNextPage = () => {
    dispatch(unAssignedShipmentListThunkActions.fetchListNextPage());
  };

  const fetchPreviousPage = () => {
    dispatch(unAssignedShipmentListThunkActions.fetchListPreviousPage());
  };

  const refresh = () => {
    dispatch(unAssignedShipmentListThunkActions.fetchList(true));
  };

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: GridRowParams<ShipmentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > unAssignedShipmentPageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < unAssignedShipmentPageNumber - 1) {
      fetchPreviousPage();
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams<ShipmentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    navigate(`/shipments/${params.id}`);
  };

  return (
    <PageContainer>
      <DataGrid<ShipmentListEntity>
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          if(rowSelectionModel) {
            let selectedRowId: any = newRowSelectionModel.pop();
            setRowSelectionModel(selectedRowId);
          } else {
            setRowSelectionModel(newRowSelectionModel);
          }
        }}
        rowSelectionModel={rowSelectionModel}
        columns={getColumns(
          rowModesModel,
          handleEditClick,
          handleCancelClick,
          handleSaveClick,
        )}
        rows={unAssignedShipmentList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        pagination
        editMode="row"
        getRowId={(row) => row.short_tracking_id}
        paginationModel={{
          page: unAssignedShipmentPageNumber - 1,
          pageSize: unAssignedShipmentPageSize,
        }}
        pageSizeOptions={[]}
        rowCount={unAssignedShipmentCount}
        paginationMode="server"
        loading={unAssignedShipmentListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title,
            refresh,
            prefixRefreshButton: <AssignHubButton selectedShipmentId={rowSelectionModel} refreshList={refresh}/>,
          },
        }}
        onPaginationModelChange={handlePaginationModelChange}
        onRowEditStop={handleRowEditStop}
        rowSelection={true}
        onRowClick={handleRowClick}
        disableColumnFilter
        getRowHeight={() => 'auto'}
      />
    </PageContainer>
  );
}

export default UnAssignedShipmentListPage;
