import { Box, Button, Divider, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

const ListDataGridToolbar = ({
  refresh,
  title,
  titleSuffix,
  filterComponent,
  prefixRefreshButton,
  suffixRefreshButton,
}: {
  currentPageIndex: number;
  totalRows: number;
  rowsPerPage: number;
  refresh?: () => void;
  title?: string;
  titleSuffix?: any;
  filterComponent?: any;
  prefixRefreshButton?: any;
  suffixRefreshButton?: any;
}) => {
  return (
    <Box>
      <GridToolbarContainer
        sx={{
          pl: { xs: 1 },
          pr: { xs: 1 },
          pb: 1,
          pt: 2,
        }}
      >
        {title ? <Typography variant="h6">{title}</Typography> : undefined}
        <Box sx={{ width: '8px' }}></Box>
        {titleSuffix}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ width: '8px' }}></Box>
        {prefixRefreshButton}
        <Box sx={{ width: '8px' }}></Box>
        {refresh ? (
          <Button
            variant="text"
            size="small"
            onClick={refresh}
            startIcon={<CachedOutlinedIcon />}
          >
            Refresh
          </Button>
        ) : undefined}
        <Box sx={{ width: '8px' }}></Box>
        {suffixRefreshButton}
        <Box sx={{ width: '8px' }}></Box>
      </GridToolbarContainer>
      {filterComponent ? filterComponent : undefined}
      <Divider />
    </Box>
  );
};

export default ListDataGridToolbar;
