import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColumns } from './table-data';
import {
  DataGrid,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridRowModesModel,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { TripListEntity } from '../../../../data/model/trip/trip';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { tripListSelectors } from '../../../../redux/trip-list/selectors';
import { tripListThunkActions } from '../../../../redux/trip-list/thunk-actions';
import PageContainer from '../../../../reusable-components/PageContainer';
import ListDataGridToolbar from '../../../../reusable-components/data-grid/ListDataGridToolbar';
import { TripFilters } from './TripFilter';
import { listActions } from '../../../../redux/lists/reducer';
import {
  LogisticsPartnerFilter,
  TripStatus,
  TripStatusFilter,
  TripStatusFilterLabel,
} from '../../../../constants/trips/trip-list';
import { listSateKeys } from '../../../../redux/lists/constants';
import { getQueryParams } from '../../../../utils/qurey-param-util';
import { store } from '../../../../redux/store';
import SelectHubContainer from '../../../dashbord/SelectHubContainer';

function TripListPage() {
  const location = useLocation();
  const forceReload = location.state?.forceReload ?? false;
  const tripList = useAppSelector(tripListSelectors.selectPageItems);
  const tripCount = useAppSelector(tripListSelectors.selectCount);
  const tripPageSize = useAppSelector(tripListSelectors.selectPageSize);
  const tripPageNumber = useAppSelector(tripListSelectors.selectPageNumber);
  const tripListLoadingStatus = useAppSelector(
    tripListSelectors.selectLoadingStatus
  );

  const isFilterSelected = getQueryParams(
    tripListSelectors.selectFilters(store.getState())
  );

  const title = 'Trip List';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);

  useEffect(() => {
    if (isFilterSelected.status === undefined) {
      dispatch(
        listActions.setFilterValue({
          filterValue: {
            label: TripStatusFilterLabel.CREATED,
            value: TripStatus.CREATED,
          },
          filterKey: TripStatusFilter.FILTER_KEY,
          key: listSateKeys.TRIP_LIST,
        })
      );
    }
    dispatch(tripListThunkActions.fetchList(forceReload));
  }, [dispatch, forceReload, hubId]);

  const fetchNextPage = () => {
    dispatch(tripListThunkActions.fetchListNextPage());
  };

  const fetchPreviousPage = () => {
    dispatch(tripListThunkActions.fetchListPreviousPage());
  };

  const refresh = () => {
    dispatch(tripListThunkActions.fetchList(true));
  };

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: GridRowParams<TripListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > tripPageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < tripPageNumber - 1) {
      fetchPreviousPage();
    }
  };

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams<TripListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    navigate(`/trips/${params.id}`);
  };

  return (
    <SelectHubContainer>
      <PageContainer>
        <DataGrid<TripListEntity>
          columns={getColumns(rowModesModel)}
          rows={tripList}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          pagination
          editMode="row"
          getRowId={(row) => row.trip_id ?? ''}
          paginationModel={{
            page: tripPageNumber - 1,
            pageSize: tripPageSize,
          }}
          pageSizeOptions={[]}
          rowCount={tripCount}
          paginationMode="server"
          loading={tripListLoadingStatus === LoadingStatus.LOADING}
          slots={{
            toolbar: ListDataGridToolbar,
          }}
          slotProps={{
            toolbar: {
              title,
              refresh,
              filterComponent: <TripFilters onFilterChange={refresh} />,
            },
          }}
          onPaginationModelChange={handlePaginationModelChange}
          onRowEditStop={handleRowEditStop}
          rowSelection={false}
          onRowClick={handleRowClick}
          disableColumnFilter
          columnVisibilityModel={
            isFilterSelected.status === TripStatus.CREATED
              ? {
                  actions: true,
                }
              : {
                  actions: false,
                }
          }
        />
      </PageContainer>
    </SelectHubContainer>
  );
}

export default TripListPage;
