import { AxiosResponse } from 'axios';
import { ShipmentPaymentDetails } from '../model/trip/shipment';
import { logisticsApiClient } from './logisticsApiClient';
import { PaymentInfo, TripHandoverDetail } from '../model/trip/trip';
import { shipmentPaymentDetailUrl, paymentHandoverDetailsUrl } from './apiUrls/logisticsApiUrls';
import { commerceApiBaseUrl } from '../../environment';
import { commerceApiClient } from './commerceApiClient';

export const getTripPaymentDetails = async (tripId: string) => {
  const axiosResponse: AxiosResponse<PaymentInfo> =
    await logisticsApiClient.get(paymentDetailUrl(tripId));
  return axiosResponse.data;
};

export const getShipmentPaymentDetails = async (shipmentId: string) => {
  const axiosResponse: AxiosResponse<ShipmentPaymentDetails> =
    await logisticsApiClient.get(shipmentPaymentDetailUrl(shipmentId));
  return axiosResponse.data;
};

export const updateShipmentPaymentStatus = async (
  shipmentId: string,
  paymentStatus: string
) => {
  const axiosResponse: AxiosResponse<ShipmentPaymentDetails> =
    await logisticsApiClient.patch(shipmentPaymentDetailUrl(shipmentId), {
      shipment_payment_collection_status: paymentStatus,
    });
  return axiosResponse.data;
};

export const getTripPaymentHandoverDetails = async (tripId: string) => {
  const axiosResponse: AxiosResponse<TripHandoverDetail> =
    await logisticsApiClient.get(paymentHandoverDetailsUrl(tripId));
  return axiosResponse.data;
};
function paymentDetailUrl(tripId: string): string {
  throw new Error('Function not implemented.');
}

