import { MenuItem } from './type';

export const deliveryAgentMenuItems: {
  [key: string]: MenuItem;
} = {
  deliveryAgentList: {
    id: 'deliveryAgentList',
    title: 'Delivery Agent List',
    type: 'item',
    url: '/deliveryAgents',
    target: true,
  },
};

const deliveryAgent: MenuItem = {
  id: 'deliveryAgent',
  title: 'Delivery Agent',
  type: 'group',
  children: [
    deliveryAgentMenuItems.deliveryAgentList,
  ],
};

export default deliveryAgent;
