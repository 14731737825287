import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import {
  fetchShipmentDetailAction,
  getShipmentPaymentDetailsAction,
} from '../../../../redux/shipment-detail/thunk-actions';
import {
  CustomDetailItem,
  DetailItem,
} from '../../../../reusable-components/DetailItem';
import { PageLoading } from '../../../../reusable-components/PageLoading';
import { PageLoadingFailed } from '../../../../reusable-components/PageLoadingFailed';
import { DisplayUtils } from '../../../../utils/display-util';
import { ShipmentPaymentDetails } from '../../../../data/model/trip/shipment';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import { DateUtils } from '../../../../utils/date-util';
import { PaymentStatus } from '../../../../constants/trips/payment';
import { fetchTripShipmentDetailAction } from '../../../../redux/trip-shipment-detail/thunk-actions';
import { tripShipmentDetailSelector } from '../../../../redux/trip-shipment-detail/selectors';
import { shipmentDetailSelector } from '../../../../redux/shipment-detail/selectors';
import ShipmentStatusChip from './TripShipmentStatusTag';
import ShipmentPaymentStatusTag from '../../shipments/ShipmentDetailPage/PaymentStatusTag';
import { ShipmentItemsTable } from '../../shipments/ShipmentDetailPage/ShipmenttemsTable';
import ShipmentTrailListPage from '../../shipments/ShipmentDetailPage/ShipmentTrailPage';

function TripShipmentDetailPage() {
  const { tripId, shipmentId } = useParams();

  const tripShipmentDetail = useAppSelector((state) =>
    tripShipmentDetailSelector(state, shipmentId!)
  );

  const shipmentDetail = useAppSelector((state) =>
    shipmentDetailSelector(state, shipmentId!)
  );

  const [shipmentDetailLoadingStatus, setShipmentDetailLoadingStatus] =
    useState(LoadingStatus.IDLE);
  const [paymentDetails, setPaymentDetails] = useState<ShipmentPaymentDetails | null>(null);
  const [showTrails, setShowTrails] = useState(false);
  const [showInitialPickAndDrop, setShowInitialPickAndDrop] = useState(false);

  const dispatch = useAppDispatch();

  const getTripShipmentDetail = async (
    shipmentId: string,
    forceReload = false
  ) => {
    if (shipmentDetailLoadingStatus === LoadingStatus.LOADING) {
      return;
    }

    setShipmentDetailLoadingStatus(LoadingStatus.LOADING);

    try {
      const [tripShipmentDetailResponse, paymentResponse] = await Promise.all([
        dispatch(
          fetchTripShipmentDetailAction({
            tripId: tripId!,
            shipmentId: shipmentId,
          })
        ),
        dispatch(getShipmentPaymentDetailsAction(shipmentId)),
      ]);

      if (tripShipmentDetailResponse) {
        setShipmentDetailLoadingStatus(LoadingStatus.IDLE);
      } else {
        setShipmentDetailLoadingStatus(LoadingStatus.FAILED);
      }

      setPaymentDetails(paymentResponse!);
    } catch (error) {
      console.error('Error:', error);
      setShipmentDetailLoadingStatus(LoadingStatus.FAILED);
    }
  };

  const getShipmentDetail = async (shipmentId: string) => {
    dispatch(fetchShipmentDetailAction(shipmentId));
  };

  useEffect(() => {
    getTripShipmentDetail(shipmentId!);
  }, [dispatch, shipmentId]);

  useEffect(() => {
    if (showInitialPickAndDrop) {
      getShipmentDetail(shipmentId!);
    }
  }, [dispatch, shipmentId, showInitialPickAndDrop]);

  if (shipmentDetailLoadingStatus === LoadingStatus.LOADING)
    return <PageLoading />;

  if (shipmentDetailLoadingStatus === LoadingStatus.FAILED) {
    return (
      <PageLoadingFailed
        onReload={() => {
          getTripShipmentDetail(shipmentId!, true);
        }}
      />
    );
  }

  if (!tripShipmentDetail) {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6">Unable to fetch details.</Typography>
        </Box>
      </>
    );
  }


  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <Typography variant="h6" component="div">
        Trip Shipment Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <DetailItem
              label="Shipment ID: "
              value={tripShipmentDetail.shipment.short_tracking_id.toString()}
            />
            <CustomDetailItem
              labelComponent={
                <Typography variant="body2" component="div">
                  Trip Shipment Status:
                </Typography>
              }
              valueComponent={
                <ShipmentStatusChip
                  onClick={() => {}}
                  value={tripShipmentDetail.status}
                  rowValue={tripShipmentDetail}
                  setPaymentDetails={setPaymentDetails}
                  paymentStatus={paymentDetails?.status ?? ''}
                />
              }
            />
            <DetailItem
              label="Net Weight: "
              value={DisplayUtils.weightFormatter(
                tripShipmentDetail.shipment.item_details.weight ?? 0
              )}
            />
            <DetailItem
              label="Shipment Created Time: "
              value={
                tripShipmentDetail.shipment.created
                  ? DateUtils.getFormattedDateString(
                      tripShipmentDetail.shipment.created
                    )
                  : ''
              }
            />
            <DetailItem
              label="Product Quantity: "
              value={tripShipmentDetail.shipment.item_details.quantity.toString()}
            />
            <DetailItem
              label="Est. Date Of Delivery: "
              value={
                tripShipmentDetail.scheduled_drop_time
                  ? DateUtils.getFormattedDateString(
                      tripShipmentDetail.scheduled_drop_time
                    )
                  : ''
              }
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Payment Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <CustomDetailItem
              labelComponent={
                <Typography variant="body2" component="div">
                  Payment Status:
                </Typography>
              }
              valueComponent={
                <ShipmentPaymentStatusTag
                  paymentStatus={paymentDetails?.status ?? ''}
                />
              }
            />
            <DetailItem
              label="Payment Mode: "
              value={paymentDetails?.payment_method ?? ''}
            />
            <DetailItem
              label={
                paymentDetails?.status === PaymentStatus.COLLECTED
                  ? 'Amount Collected: '
                  : 'Amount To Be Collected: '
              }
              value={convertPaisaToRupee(paymentDetails?.amount)}
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Location Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <DetailItem
              label="Pickup Detail: "
              value={DisplayUtils.getFullAddress(
                tripShipmentDetail.pick_up_address
              )}
            />
            <DetailItem
              label="Drop Detail: "
              value={DisplayUtils.getFullAddress(
                tripShipmentDetail.drop_address
              )}
            />
            {!showInitialPickAndDrop ? (
                <Button
                  id="basic-button"
                  variant="text"
                  size="medium"
                  onClick={() => {
                    setShowInitialPickAndDrop(true);
                  }}
                  sx={{
                    paddingLeft: '30px',
                    marginTop: '20px',
                  }}
                >
                  <Typography variant="inherit">
                    See Origin and Destination address details
                  </Typography>
                </Button>
            ) : undefined}
            {showInitialPickAndDrop ? (
              <>
                <DetailItem
                  label="Origin Detail: "
                  value={DisplayUtils.getFullAddress(
                    shipmentDetail?.pick_up_address ?? ''
                  )}
                />
                <DetailItem
                  label="Destination Detail: "
                  value={DisplayUtils.getFullAddress(
                    shipmentDetail?.drop_address ?? ''
                  )}
                />
              </>
            ) : undefined}
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Product Details
      </Typography>
      <br></br>
      <Card>
        <ShipmentItemsTable
          shipmentItems={[tripShipmentDetail.shipment.item_details]}
        />
      </Card>
      <br></br>
      {!showTrails ? (
        <Button
          id="basic-button"
          variant="text"
          size="medium"
          onClick={() => {
            setShowTrails(true);
          }}
        >
          <Typography variant="inherit">See Shipments Trip Trails</Typography>
        </Button>
      ) : undefined}

      {showTrails ? (
        <Box>
          <br></br>
          <ShipmentTrailListPage shipmentId={shipmentId!} />
        </Box>
      ) : undefined}
    </Box>
  );
}

export default TripShipmentDetailPage;
