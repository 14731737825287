import { ReactNode } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import SelectHubDialogButton from '../../auth/SelectHubPage/SelectHubDialog';

const SelectHubContainer = ({ children }: { children: ReactNode }) => {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };
  const { selectedHubId } = useAppSelector((state) => state.sharedPref);
  if (selectedHubId) {
    return <>{children}</>;
  }
  return (
    <div style={styles}>
      <SelectHubDialogButton />
    </div>
  );
};

export default SelectHubContainer;
