import { InternalAxiosRequestConfig } from 'axios';
import { store } from '../../redux/store';
import { UserRoleData } from '../../constants/auth/userRoles';
import * as environment from '../../environment';

const authInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const selectedUserRole =
    config.meta?.userRole ?? store.getState().sharedPref.selectedUserRole;
  const token = getToken(selectedUserRole);
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`;
  } else {
    config.headers['tpid'] = `${environment.thirdPartyId}`;
  }
  return config;
};

const getToken = (selectedUserRole: UserRoleData) => {
  const profile = store.getState().sharedPref.authData?.profile;
  return profile?.[selectedUserRole]?.token || null;
};

export default authInterceptor;
