import { listSateKeys } from '../lists/constants';
import { listSelectors } from '../lists/selectors';
import { RootState } from '../store';

const selectListItems = (state: RootState) =>
  listSelectors
    .selectListItemIds(state, listSateKeys.SHIPMENT_TRAIL_LIST)
    .map((item) => state.entities.shipmentTrailList[item]);

// const selectCount = (state: RootState) =>
//   listSelectors.selectCount(state, listSateKeys.SHIPMENT_TRAIL_LIST);

// const selectPageSize = (state: RootState) =>
//   listSelectors.selectPageSize(state, listSateKeys.SHIPMENT_TRAIL_LIST);

// const selectPageNumber = (state: RootState) =>
//   listSelectors.selectPageNumber(state, listSateKeys.SHIPMENT_TRAIL_LIST);

const selectPageItems = (state: RootState) =>
  listSelectors
    .selectPageItemIds(state, listSateKeys.SHIPMENT_TRAIL_LIST)
    .map((item) => state.entities.shipmentTrailList[item]);

const selectLoadingStatus = (state: RootState) =>
  listSelectors.selectLoadingStatus(state, listSateKeys.SHIPMENT_TRAIL_LIST);

  const selectFilterValue = (state: RootState, filterKey: string) =>
  listSelectors.selectFilterValue(state, filterKey, listSateKeys.SHIPMENT_TRAIL_LIST);

const selectFilters = (state: RootState) =>
  listSelectors.selectFilters(state, listSateKeys.SHIPMENT_TRAIL_LIST);


export const shipmentTrailListSelectors = {
  selectListItems,
  selectPageItems,
  // selectCount,
  // selectPageSize,
  // selectPageNumber,
  selectLoadingStatus,
  selectFilterValue,
  selectFilters,
};
