import componentTypes from '@data-driven-forms/react-form-renderer/component-types';
import componentMapper from '@data-driven-forms/mui-component-mapper/component-mapper';
import { CustomImageUploadField } from './CustomImageUploadField';
import FormTemplate from './CustomFromTemplate/CustomFormTemplate';
import CustomLatLonField from './CustomLatLonField';
import CustomSearchableSelectField from './CustomSearchableSelectField';
import { CustomPlainText } from './CustomPlainText';
import { CustomCheckBox } from './CustomCheckBox';
import { CustomChip } from './CustomChip';
import { CustomChangeValueFieldWrapper } from './CustomChangeValueField';
import { CustomTextFieldFetchValue } from './CustomTextFieldFetchValue';

export const customComponentTypes = {
  CUSTOM_IMAGE_UPLOAD_FIELD: 'custom-image-upload-field',
  CUSTOM_LAT_LON_FIELD: 'custom-lat-lon-field',
  CUSTOM_SEARCHABLE_SELECT_FIELD: 'custom-select-field',
  CUSTOM_CHANGE_VALUE_FIELD: 'custom-change-value-field',
  CUSTOM_PLAIN_TEXT: 'custom-plain-text',
  CUSTOM_CHECK_BOX: 'custom-check_box',
  CUSTOM_CHIP: 'custom-chip',
  ...componentTypes,
};

export const customComponentMapper = {
  ...componentMapper,
  [customComponentTypes.CUSTOM_IMAGE_UPLOAD_FIELD]: CustomImageUploadField,
  [customComponentTypes.CUSTOM_LAT_LON_FIELD]: CustomLatLonField,
  [customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD]: CustomSearchableSelectField,
  [customComponentTypes.CUSTOM_CHANGE_VALUE_FIELD]: CustomChangeValueFieldWrapper,
  [customComponentTypes.CUSTOM_PLAIN_TEXT]: CustomPlainText,
  [customComponentTypes.CUSTOM_CHECK_BOX]: CustomCheckBox,
  [customComponentTypes.CUSTOM_CHIP]: CustomChip,
} as any;

export const CustomFormTemplate = FormTemplate;

export * from '@data-driven-forms/react-form-renderer';
