import { Box, Container, Toolbar, Typography } from '@mui/material';
import schema from './assign-to-hub-form-schema';
import { FormRenderer, CustomFormTemplate, customComponentMapper } from '../../../../../reusable-components/data-driven-forms';

const AssignHubForm = ({
  closeDialog,
  handleSubmit,
}: {
  closeDialog: () => void;
  handleSubmit: any;
}) => {
  let data: any = {
    submit_button_text: 'Assign',
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: 1,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Toolbar
          sx={{
            pl: { xs: 0 },
            pr: { xs: 0 },
            pb: 1,
          }}
        >
          <Typography variant="h6"> Assign To Hub</Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          initialValues={data}
          onCancel={() => {
            closeDialog();
          }}
        />
      </Container>
    </Box>
  );
};

export default AssignHubForm;
