import { LoadingStatus } from '../../data/model/interfaces';
import { RootState } from '../store';
import { ListSateKeysType } from './type';

const selectListItemIds = (state: RootState, key: ListSateKeysType) =>
  state.lists[key]?.listItems ?? [];

const selectCount = (state: RootState, key: ListSateKeysType) =>
  state.lists[key]?.count ?? 0;

const selectPageSize = (state: RootState, key: ListSateKeysType) =>
  state.lists[key]?.pageSize ?? 0;

const selectPageNumber = (state: RootState, key: ListSateKeysType) =>
  state.lists[key]?.pageNumber ?? 1;

const selectPageItemIds = (state: RootState, key: ListSateKeysType) => {
  const currentPage = state.lists[key]?.pageNumber ?? 1; //1
  const pageSize = state.lists[key]?.pageSize ?? 0; //10
  const listItems = state.lists[key]?.listItems ?? [];
  const itemsCount = state.lists[key]?.count ?? 0;
  const startPosition = (currentPage - 1) * pageSize;
  let endPosition = startPosition + pageSize;
  if (endPosition > itemsCount) {
    endPosition = itemsCount;
  }
  if (
    listItems.length >= endPosition &&
    endPosition >= pageSize &&
    startPosition >= 0
  ) {
    return listItems.slice(startPosition, endPosition);
  }
  return [];
};

const selectLoadingStatus = (state: RootState, key: ListSateKeysType) =>
  state.lists[key]?.loadingStatus ?? LoadingStatus.IDLE;

const selectFilters = (
  state: RootState,
  key: ListSateKeysType
) => {
  const filters = state.lists[key]?.filters ?? [];
  return filters;
};

const selectFilterValue = (
  state: RootState,
  filterKey: string,
  key: ListSateKeysType
) => {
  const filters = state.lists[key]?.filters ?? [];
  const filter = filters.find((filter) => filter.filterKey === filterKey);
  return filter?.filterValue;
};

export const listSelectors = {
  selectListItemIds,
  selectPageItemIds,
  selectCount,
  selectPageSize,
  selectPageNumber,
  selectLoadingStatus,
  selectFilterValue,
  selectFilters
};
