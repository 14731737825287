import { Box, Container, Toolbar, Typography } from '@mui/material';
import schema from './trip-form-schema';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import {
  FormRenderer,
  CustomFormTemplate,
  customComponentMapper,
} from '../../../../../../reusable-components/data-driven-forms';
import { TripListEntity } from '../../../../../../data/model/trip/trip';

const TripForm = ({
  closeDialog,
  handleSubmit,
  tripDetails,
}: {
  closeDialog: () => void;
  handleSubmit: any;
  tripDetails?: TripListEntity;
}) => {
  let data: any = {
    submit_button_text: tripDetails ? 'Update Trip' : 'Create Trip',
    submit_button_prefix_icon: <ArrowForwardOutlinedIcon />,
    ...tripDetails,
    trip_cost: {
      client_cost: (tripDetails?.trip_cost.client_cost ?? 0) / 100,
      fuel_cost: (tripDetails?.trip_cost.fuel_cost ?? 0) / 100,
      vehicle_rent: (tripDetails?.trip_cost.vehicle_rent ?? 0) / 100,
    },
    delivery_agent_info_object: tripDetails
      ? {
          label: tripDetails?.delivery_agent_info?.name,
          value: {
            phone: tripDetails?.delivery_agent_info?.phone,
            user_id: tripDetails?.delivery_agent_id,
          },
        }
      : undefined,
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: 1,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Toolbar
          sx={{
            pl: { xs: 0 },
            pr: { xs: 0 },
            pb: 1,
          }}
        >
          <Typography variant="h6">
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          initialValues={data}
          onCancel={() => {
            closeDialog();
          }}
        />
      </Container>
    </Box>
  );
};

export default TripForm;
