import React, { useState } from 'react';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { uploadPhoto } from '../../../data/api/profile';
import { Image } from '../../../data/model/interfaces';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { CustomInput } from './CustomInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloseIcon from '@mui/icons-material/Close';
import ViewImageDialogButton from '../../ViewImageDialog';

export const CustomImageUploadField = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const { input, meta, label, isRequired } = useFieldApi<Image>(props);

  async function uploadPhotoAction(newFile: File) {
    const photoFile = newFile;
    let formData = new FormData();
    formData.append('file', photoFile);
    setIsLoading(true);
    const response = await uploadPhoto(formData);
    setIsLoading(false);
    input.onChange(response);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    const files = fileList ? Array.from(fileList) : [];
    uploadPhotoAction(files[0]);
  };

  const handleClearAll = () => {
    input.onChange(undefined);
  };

  return (
    <Grid xs={12} item>
      <TextField
        id={input?.value?.photo_id}
        type="file"
        onChange={handleChange}
        disabled={isLoading}
        fullWidth
        error={meta.touched && meta.error ? meta.error : undefined}
        helperText={meta.touched && meta.error ? meta.error : undefined}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              {input?.value ? (
                <img src={input?.value?.photo_url} height="32px"></img>
              ) : (
                <AttachFileIcon />
              )}
            </InputAdornment>
          ),
          endAdornment: isLoading ? (
            <CircularProgress size={24} />
          ) : input?.value ? (
            <InputAdornment position="start">
              <ViewImageDialogButton imageUrl={input?.value?.photo_url} />
              <IconButton
                aria-label="Clear"
                title="Clear"
                size="small"
                className="MuiFileInput-IconButton"
                onClick={handleClearAll}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
          inputComponent: CustomInput,
          inputProps: {
            text:
              input?.value?.photo_url ?? (isRequired ? `${label} *` : label),
            isPlaceholder: input?.value == null,
          },
        }}
      />
    </Grid>
  );
};
