import { AppThunk } from '../store';
import { SnakbarUtils } from '../../utils/snakbar-util';
import {
  linkPincode,
  servicablePincode,
  unlinkPincode,
} from '../../data/api/pincode';

export const fetchServicablePincodeList = (
  hubId: string
): AppThunk<Promise<string[] | undefined>> => {
  return async (_dispatch, _getState) => {
    try {
      const getPincodeApiResponse = await servicablePincode(hubId);
      return getPincodeApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

export const unlinkPincodeAction = (
  pincode: string
): AppThunk<Promise<boolean | undefined>> => {
  return async (_dispatch, getState) => {
    try {
      const { selectedHubId } = getState().sharedPref;
      await unlinkPincode(selectedHubId!, pincode);
      SnakbarUtils.showSuccessMessage('Pincode Unlinked Successfully');
      return true;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

export const linkPincodeAction = (
  pincode: string[]
): AppThunk<Promise<boolean | undefined>> => {
  return async (_dispatch, getState) => {
    try {
      const { selectedHubId } = getState().sharedPref;
      await linkPincode(selectedHubId!, pincode);
      SnakbarUtils.showSuccessMessage('Pincodes Linked Successfully');
      return true;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};
