import { Box, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  BalanceDetailCard,
  PaymentDetailCard,
} from './PaymentDetailCardPage/PaymentDetailCard';
import { useEffect, useState } from 'react';
import { cashDepositsListThunkActions } from '../../../../redux/cash-deposit-list/thunk-actions';
import { PaymentStatsInfo } from '../../../../data/model/cash-deposit/cashDeposit';
import CashDepositHistoryList from '../CashDepositHistoryList';
import SelectHubContainer from '../../../dashbord/SelectHubContainer';

function HubCashDepositDetailPage() {
  const [paymentStats, setPaymentStats] = useState<
    PaymentStatsInfo | undefined
  >();
  const dispatch = useAppDispatch();
  const { selectedHubId } = useAppSelector((state) => state.sharedPref);

  const fetchData = async (selectedHubId: string) => {
    const statsResponse = await dispatch(
      cashDepositsListThunkActions.getPaymnentStatsAction(selectedHubId)
    );
    setPaymentStats(statsResponse);
  };

  useEffect(() => {
    fetchData(selectedHubId ?? '');
  }, [dispatch, selectedHubId]);

  return (
    <SelectHubContainer>
      <Box
        sx={{
          p: 2,
        }}
      >
        <BalanceDetailCard
          amountTypeText='Hub Balance'
          amountValue={paymentStats?.balance ?? 0}
          tooltipText='Balance for which deposit request is yet to be made.'
        />
        <Box sx={{ height: '64px' }} />
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {[
            {
              amountTypeText: 'Total Amount Collected',
              amountValue: paymentStats?.handover_amount ?? 0,
            },
            {
              amountTypeText: 'Total Amount Deposited',
              amountValue: paymentStats?.verified_deposit_amount ?? 0,
            },
            {
              amountTypeText: 'Deposit Approval Pending',
              amountValue: paymentStats?.pending_deposit_amount ?? 0,
              isPendingValue: true,
            },
          ].map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <PaymentDetailCard {...item} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ height: '64px' }} />
        <CashDepositHistoryList />
      </Box>
    </SelectHubContainer>
  );
}

export default HubCashDepositDetailPage;
