export const listSateKeys = {
  TRIP_LIST: 'tripList',
  SHIPMENT_LIST: 'shipmentList',
  UNASSIGNED_SHIPMENT_LIST: 'unAssignedShipmentList',
  TRIP_SHIPMENT_LIST: 'tripShipmentList',
  SHIPMENT_TRAIL_LIST: 'shipmentTrailList',
  PINCODE_LIST: 'pincodeList',
  DELIVERY_AGENT_LIST: 'deliveryAgentList',
  CASH_DEPOSITS_LIST: 'cashDepositsList',
  REPORTEE_LIST: 'reporteeList',
} as const;
