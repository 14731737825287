import { Button, Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import CashDepositForm from './AddNewDepositForm';
import { CreateDepositFormValues } from './AddNewDepositForm/new-deposit-form-schema';
import { CreateDepositPayload } from '../../../../../data/model/cash-deposit/cashDeposit';
import { cashDepositsListThunkActions } from '../../../../../redux/cash-deposit-list/thunk-actions';
import { AutocompleteOptionType } from '../../../../../reusable-components/data-driven-forms/CustomSearchableSelectField/type';
import useDialogBox from '../../../../../utils/dialogbox-hook';

const AddNewDepositButton = ({ onSubmit }: { onSubmit: () => void }) => {
  const dialogBox = useDialogBox();
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);
  const dispatch = useAppDispatch();

  const loginProfile = useAppSelector(
    (state) => state.sharedPref.authData
  )?.profile;
  const userName =
    loginProfile?.LOGISTICS_ADMIN?.data?.profile_name ||
    loginProfile?.HUB_MANAGER?.data?.profile_name ||
    '';

  const userPhone =
    loginProfile?.LOGISTICS_ADMIN?.data?.user_profile?.phone ||
    loginProfile?.HUB_MANAGER?.data?.user_profile?.phone ||
    '';

  const handleSubmit = async (formValues: CreateDepositFormValues) => {
    let requestBody: CreateDepositPayload = {
      hub_id: hubId ?? '',
      notes: formValues.notes,
      trip_ids: formValues.trip_ids.map(
        (trip: AutocompleteOptionType) => trip.value
      ),
      deposit_by_user_info: {
        name: userName,
        phone: userPhone,
      },
    };

    let response = await dispatch(
      cashDepositsListThunkActions.createNewDepositAction(requestBody)
    );
    if (response) {
      dialogBox.closeDialog();
      onSubmit();
    }
  };

  return (
    <>
      <Button
        id='basic-button'
        variant='contained'
        size='small'
        onClick={dialogBox.openDialog}
      >
        Add new deposit
      </Button>
      <Dialog
        open={dialogBox.open}
        onClose={dialogBox.closeDialog}
        TransitionComponent={dialogTransition}
        scroll='body'
      >
        <DialogContent
          sx={{
            width: '500px',
          }}
        >
          <CashDepositForm
            closeDialog={dialogBox.closeDialog}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewDepositButton;
