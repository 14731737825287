import { useAppDispatch } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import PageContainer from '../../../../reusable-components/PageContainer';
import { getColumns } from './table-data';
import { CheckinListEntity } from '../../../../data/model/geolocation/reportee';
import ListDataGridToolbar from '../../../../reusable-components/data-grid/ListDataGridToolbar';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { DataGrid, GridRowModesModel } from '@mui/x-data-grid';
import { fetchCheckinList } from '../../../../data/api/reportee';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { dialogTransition } from '../../../../utils/transitions';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';

const CheckinListPage = () => {
  const location = useLocation();
  const [checkinList, setCheckinList] = useState<
    {
      date: string;
      check_in_time: string;
      check_out_time: string;
      distance: number;
    }[]
  >([]);

  const [checkinListLoadingStatus, setCheckinListLoadingStatus] = useState(
    LoadingStatus.IDLE
  );

  const [userName, setUserName] = useState(location.state?.userName ?? '');

  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(30, 'days'));

  const [toDate, setToDate] = useState<Dayjs>(dayjs());

  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  let { reporteeId, role } = useParams();

  const title = 'Checkin List';

  const fetchCheckIns = async (reporteeId: string, fromDate: Dayjs, toDate: Dayjs) => {
    try {
      setCheckinListLoadingStatus(LoadingStatus.LOADING);
      const checkInListResponse: CheckinListEntity[] = await fetchCheckinList(
        [reporteeId],
        fromDate.format('YYYY-MM-DD'),
        toDate.format('YYYY-MM-DD'),
        role!
      );
      let user = userName;
      const days = generateDateRange(fromDate, toDate);
      const data = days.map((day) => {
        const checkinEntity = checkInListResponse.find((r) => r.date === day);
        const checkin: {
          date: string;
          check_in_time: string;
          check_out_time: string;
          distance: number;
        } = {
          date: day,
          check_in_time: checkinEntity?.check_in_time ?? '',
          check_out_time: checkinEntity?.check_out_time ?? '',
          distance: checkinEntity?.distance ?? 0,
        };
        user = checkinEntity?.user_info.name ?? user;
        return checkin;
      });
      setCheckinList(data);
      setUserName(user);
      setCheckinListLoadingStatus(LoadingStatus.IDLE);
    } catch (e) {
      setCheckinListLoadingStatus(LoadingStatus.IDLE);
    }
  };

  useEffect(() => {
    fetchCheckIns(reporteeId!, fromDate, toDate);
  }, [dispatch,reporteeId, fromDate, toDate]);

  const refresh = () => {
    fetchCheckIns(reporteeId!, fromDate, toDate);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  return (
    <PageContainer>
      <DataGrid<{
        date: string;
        check_in_time: string;
        check_out_time: string;
        distance: number;
      }>
        columns={getColumns(rowModesModel)}
        rows={checkinList}
        pagination
        rowCount={checkinList.length}
        editMode="row"
        getRowId={(row) => row.date}
        pageSizeOptions={[]}
        loading={checkinListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title: `${title} - ${userName}`,
            refresh,
            filterComponent: (
              <Chip
                variant="outlined"
                onClick={openDialog}
                color="primary"
                icon={<DateRangeOutlinedIcon />}
                label={`${fromDate.format('DD/MM/YYYY')} -
               ${toDate.format('DD/MM/YYYY')}`}
                sx={{ mr: 1, mb: 1 }}
              />
            ),
          },
        }}
        rowSelection={false}
        disableColumnFilter
      />
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <h4>Set duration</h4>
          <div
            style={{
              paddingBottom: '20px',
            }}
          >
            <DatePicker
              label="From"
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={(value) => {
                setFromDate(value!);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div
            style={{
              paddingBottom: '10px',
            }}
          >
            <DatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={(value) => {
                setToDate(value!);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default CheckinListPage;

const generateDateRange = (startDate: Dayjs, endDate: Dayjs) => {
  const dates = [];
  let currentDate = dayjs(startDate);
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }
  dates.reverse();
  return dates;
};
