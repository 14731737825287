import {
  GridColDef,
} from '@mui/x-data-grid';
import { TripShipmentListEntity } from '../../../../../data/model/trip/trip';
import { UpdateDropAddressButton } from '../UpdateDropAddressButton';
import { DisplayUtils } from '../../../../../utils/display-util';
import { UpdateDeliveryDateButton } from '../UpdateEstDeliveryDateButton';
import { DateUtils } from '../../../../../utils/date-util';
import { ShipmentIdButton } from './ShipmentIdButton';
import { Typography } from '@mui/material';

export const getColumns: (
  rowModesModel: any
) => GridColDef<TripShipmentListEntity, any, any>[] = (rowModesModel) => [
  {
    field: 'shipment',
    headerName: 'Shipment ID',
    type: 'string',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    renderCell(params) {
      return (
        <ShipmentIdButton
          tripId={params.row.trip.trip_id}
          shipmentId={params.row.shipment.short_tracking_id}
        />
      );
    },
    hideable: false,
    editable: false,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created Date',
    type: 'string',
    minWidth: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return DateUtils.getFormattedDateTimeString(params.value);
    },
    valueGetter(params) {
      return params.row.shipment.created;
    },
  },
  {
    field: 'pick_up_address.full_address',
    headerName: 'Pickup Location',
    type: 'string',
    minWidth: 250,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    editable: false,
    sortable: false,
    valueFormatter(param) {
      return DisplayUtils.getFullAddress(param.value);
    },
    valueGetter(params) {
      return params.row.pick_up_address;
    },
  },
  {
    field: 'pick_up_address.poc_phone',
    headerName: 'Pickup Contact Details',
    minWidth: 175,
    renderCell(params) {
      const { poc_name, poc_phone } = params.row.pick_up_address;
      return (
        <Typography variant="body2">
          <div>{poc_name}</div>
          <div>{poc_phone}</div>
        </Typography>
      );
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address.full_address',
    headerName: 'Drop Location',
    minWidth: 325,
    renderCell(param) {
      return (
        <UpdateDropAddressButton
          tripId={param.row.trip.trip_id.toString()}
          shipmentId={param.id.toString()}
          dropAddress={param.row.drop_address}
          tripShipmentStatus={param.row.status}
        />
      );
    },
    valueGetter(params) {
      return params.row.drop_address;
    },
    hideable: false,
    editable: false,
    sortable: false,
  },
  {
    field: 'drop_address',
    headerName: 'Drop Contact Details',
    minWidth: 200,
    renderCell(params) {
      const { poc_name, poc_phone } = params.row.drop_address;

      return (
        <Typography variant="body2">
          <div>{poc_name}</div>
          <div>{poc_phone}</div>
        </Typography>
      );
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Shipment Status',
    minWidth: 120,
    valueGetter(params) {
      return params.row.status;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'scheduled_drop_time',
    headerName: 'Est. Date of Delivery',
    minWidth: 150,
    headerAlign: 'center',
    align: 'center',
    renderCell(param) {
      return (
        <UpdateDeliveryDateButton
          tripId={param.row.trip.trip_id.toString()}
          shipmentId={param.id.toString()}
          deliveryDate={param.row.scheduled_drop_time}
          tripShipmentStatus={param.row.status}
        />
      );
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.weight',
    headerName: 'Net Weight',
    minWidth: 50,
    valueFormatter(params) {
      return DisplayUtils.weightFormatter(params.value ?? 0);
    },
    valueGetter(params) {
      return params.row.shipment.item_details.weight;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'item_details.quantity',
    headerName: 'Quantity',
    type: 'number',
    minWidth: 50,
    valueGetter(params) {
      return params.row.shipment.item_details.quantity;
    },
    editable: false,
    sortable: false,
  },
];
