import { useTheme } from '@emotion/react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAppDispatch } from '../../../../redux/hooks';
import { dialogTransition } from '../../../../utils/transitions';
import AddDeliveryAgentForm from './AddDeliveryAgentForm';
import { AddDeliveryAgentFormValues } from './AddDeliveryAgentForm/add-delivery-agent-form-schema';
import { deliveryAgentListThunkActions } from '../../../../redux/delivery-agent-list/thunk-actions';
import { UserRoleData } from '../../../../constants/auth/userRoles';
import { ProfileData } from '../../../../data/model/auth/auth';
import * as environment from '../../../../environment';

const AddDeliveryAgentButton = ({ refresh }: { refresh: () => void }) => {

  const dispatch = useAppDispatch();
  const handleSubmit = async (formValues: AddDeliveryAgentFormValues) => {
    let response: boolean | undefined = await dispatch(
      deliveryAgentListThunkActions.addDeliveryAgentAction({
        phone: formValues.phone_number,
        profile_name: formValues.profile_name,
        role: UserRoleData.AGENT,
        third_party_id: environment.thirdPartyId ?? '',
      })
    );
    if(response) {
      closeDialog();
      refresh();
    }
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        endIcon={<AddOutlinedIcon />}
      >
        Add
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <AddDeliveryAgentForm
            closeDialog={closeDialog}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDeliveryAgentButton;
