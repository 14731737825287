import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { EntityState, EntityStateKeysType } from './type';

const setEntities: CaseReducer<
  EntityState,
  PayloadAction<{
    list: {
      entity: any;
      entityId: string;
    }[];
    key: EntityStateKeysType;
  }>
> = (state, action) => {
  action.payload.list.forEach((item) => {
    state[action.payload.key][item.entityId] = item.entity;
  });
};

const setEntity: CaseReducer<
  EntityState,
  PayloadAction<{
    entity: any;
    entityId: string;
    key: EntityStateKeysType;
  }>
> = (state, action) => {
  state[action.payload.key][action.payload.entityId] = action.payload.entity;
};

export const listCaseReducers = {
  setEntities,
  setEntity,
};
