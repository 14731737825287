import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { activityListCaseReducers } from './actions';
import { IListStateFull } from './type';
import { clearAction } from '../login/reducer';

const initialState: any = {};

const slice = createSlice<IListStateFull, SliceCaseReducers<IListStateFull>>({
  name: 'listState',
  initialState,
  reducers: activityListCaseReducers,
  extraReducers: (builder) => {
    builder.addCase(clearAction, (state) => {
      return initialState;
    });
  },
});

export const listActions = slice.actions;

export default slice.reducer;
