import { createSlice } from '@reduxjs/toolkit';
import { toogleDrawer } from './actions';
import { LayoutState } from './type';
import { clearAction } from '../login/reducer';

const initialState: LayoutState = {
  openDrawer: true,
};

const slice = createSlice({
  name: 'layoutState',
  initialState,
  reducers: {
    toogleDrawer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, (state) => {
      return initialState;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;
