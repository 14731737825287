import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import { tripListSelectors } from './selectors';
import { getQueryParams } from '../../utils/qurey-param-util';
import { getTripList } from '../../data/api/trip';
import { LoadingStatus } from '../../data/model/interfaces';
import { setSelectedHubId } from '../shared-pref/actions';
import { SnakbarUtils } from '../../utils/snakbar-util';

const fetchList = (forceReload = false, searchString = ''): AppThunk => {
  return async (dispatch, getState) => {
    const { selectedHubId } = getState().sharedPref;
    if (
      tripListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.TRIP_LIST));
    }
    if (
      tripListSelectors.selectListItems(getState()).length <
        tripListSelectors.selectPageSize(getState()) *
          tripListSelectors.selectPageNumber(getState()) ||
      tripListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.TRIP_LIST,
          })
        );
        const getTripListApiResponse = await getTripList({
          pageNumber: tripListSelectors.selectPageNumber(getState()),
          hubId: selectedHubId!,
          queryParams: getQueryParams(tripListSelectors.selectFilters(getState())),
        });
        dispatch(
          listActions.setListResponse({
            listItems: getTripListApiResponse.results.map(
              (item) => item.trip_id
            ),
            count: getTripListApiResponse.count,
            key: listSateKeys.TRIP_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: getTripListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.trip_id.toString(),
            })),
            key: entityStateKeys.TRIP_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.TRIP_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.TRIP_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (
      tripListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      tripListSelectors.selectCount(getState()) >
      tripListSelectors.selectPageSize(getState()) *
        tripListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: tripListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.TRIP_LIST,
        })
      );
      dispatch(fetchList());
    }
  };
};

const reloadListCurrentPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: tripListSelectors.selectPageNumber(getState()),
        key: listSateKeys.TRIP_LIST,
      })
    );
    dispatch(fetchList());
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (tripListSelectors.selectPageNumber(getState()) > 1) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: tripListSelectors.selectPageNumber(getState()) - 1,
          key: listSateKeys.TRIP_LIST,
        })
      );
    }
  };
};

export const tripListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
};
