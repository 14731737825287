import dayjs from 'dayjs';

export class DateUtils {
  static getFormattedDateString = (date: string) => {
    return dayjs(date).format('DD MMM, YYYY');
  };

  static getFormattedDateTimeString = (date: string) => {
    if ((date === '')) {
      return '';
    }
    return dayjs(date).format('DD MMM, YYYY | h:mm A');
  };
}
