import { TripShipmentRelationStatus } from "../../../../../constants/trips/trip-list";


export const createdShipmentOptions = [
    { label: 'In Transit', value: TripShipmentRelationStatus.IN_TRANSIT },
  ];
  
  export const inTransitShipmentOptions = [
    { label: 'Delivered', value: TripShipmentRelationStatus.DELIVERED },
    { label: 'Customer Rejected', value: TripShipmentRelationStatus.CUSTOMER_REJECTED },
    { label: 'Not Delivered', value: TripShipmentRelationStatus.NOT_DELIVERED },
  ];
  