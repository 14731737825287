import { PaymentStatus } from '../../../../../../constants/trips/payment';
import { AddressType } from '../../../../../../constants/trips/trip-list';

export const PaymentStatusOptions = [
  {
    label: 'PAID',
    value: PaymentStatus.PAID,
  },
  {
    label: 'UNPAID',
    value: PaymentStatus.UNPAID,
  },
];

export const AddressTypeOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Retailer',
    value: AddressType.RETAILER,
  },
  {
    label: 'Seller',
    value: AddressType.SELLER,
  },
  {
    label: 'BAP',
    value: AddressType.BAP,
  },
  {
    label: 'Hub',
    value: AddressType.HUB,
  },
  {
    label: 'Others',
    value: AddressType.OTHERS,
  },
];
