import { useState } from 'react';
import { CustomButton } from '../../../../../reusable-components/CustomButton';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { dialogTransition } from '../../../../../utils/transitions';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

export const CashDepositImageButton = ({
  buttonText,
  imagesUrl,
}: {
  buttonText: string;
  imagesUrl: string[];
}) => {
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setSelectedImageIndex(0);
    setOpen(false);
  };

  return (
    <div>
      <CustomButton
        variant="text"
        handleClick={openDialog}
        buttonText={buttonText}
      />
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <Stack style={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={imagesUrl.at(selectedImageIndex)}
              style={{ width: '100%' }}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '2%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <IconButton
                onClick={() => {
                  handleClick(selectedImageIndex + 1);
                }}
                disabled={selectedImageIndex === imagesUrl.length - 1}
              >
                <ArrowForwardIosOutlinedIcon
                  sx={{
                    fontSize: '75px',
                    opacity: '0.75',
                  }}
                />
              </IconButton>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '2%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <IconButton
                onClick={() => {
                  handleClick(selectedImageIndex - 1);
                }}
                disabled={selectedImageIndex === 0}
              >
                <ArrowBackIosOutlinedIcon
                  sx={{
                    fontSize: '75px',
                    opacity: '0.75',
                  }}
                />
              </IconButton>
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
