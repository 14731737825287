import { Box, useTheme } from '@mui/material';
import { useAppDispatch } from '../../../../../redux/hooks';
import { SearchShipmentField } from '../SearchShipmentField';

export const ShipmentFilters = ({
  onFilterChange: refreshList,
}: {
  onFilterChange: () => void;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          overflow: 'auto',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          [theme.breakpoints.down('md')]: {
            display: 'flex',
          },
          textAlign: 'right',
        }}
      >
        <SearchShipmentField
          label="Shipment ID"
          placeholder="Search with Shipment ID..."
        />
      </Box>
    </Box>
  );
};
