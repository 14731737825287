import { Button } from '@mui/material';

export const CustomButton = ({
  buttonText,
  handleClick,
  variant = 'contained',
  buttonSize = 'medium',
}: {
  buttonText: string;
  handleClick: () => void;
  variant?: any;
  buttonSize?: any;
}) => {
  return (
    <Button
      id='basic-button'
      variant={variant}
      size={buttonSize}
      onClick={handleClick}
    >
      {buttonText}
    </Button>
  );
};
