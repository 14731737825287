import { AxiosResponse } from 'axios';
import {
  CashDepositsDetailEntity,
  CashDepositsListEntity,
  CreateDepositPayload,
  DepositHandoverDetailEntity,
  PaymentStatsInfo,
  UpdateDepositPayload,
} from '../model/cash-deposit/cashDeposit';
import { ListResponse } from '../model/interfaces';
import { logisticsApiClient } from './logisticsApiClient';
import { UserRoleData } from '../../constants/auth/userRoles';
import {
  cashDepositsListUrl,
  getPaymentStatsUrl,
  updateCashDepositsDetailUrl,
  depositRequestVerificationUrl,
  depositDetailUrl,
  depositHandoverDetailUrl,
} from './apiUrls/logisticsApiUrls';

export const getCashDepositsList = async ({
  pageNumber,
  hubId,
}: {
  pageNumber: number;
  hubId: string;
}) => {
  const axiosResponse: AxiosResponse<ListResponse<CashDepositsListEntity>> =
    await logisticsApiClient.get(cashDepositsListUrl, {
      params: {
        page: pageNumber,
        hub_id: hubId,
      },
    });
  return axiosResponse.data;
};

export const getPaymentStats = async (
  selectedHubId: string,
  tripIds?: number[]
) => {
  const axiosResponse: AxiosResponse<PaymentStatsInfo> =
    await logisticsApiClient.get(getPaymentStatsUrl, {
      params: {
        hub_id: selectedHubId,
        trip_ids: tripIds?.join(','),
      },
    });
  return axiosResponse.data;
};

export const createNewDeposit = async (requestBody: CreateDepositPayload) => {
  const axiosResponse: AxiosResponse<CashDepositsDetailEntity> =
    await logisticsApiClient.post(cashDepositsListUrl, requestBody, {
      meta: {
        userRole: UserRoleData.HUB_MANAGER,
      },
    });
  return axiosResponse.data;
};

export const updateDeposit = async (
  requestBody: UpdateDepositPayload,
  depositId: string
) => {
  const axiosResponse: AxiosResponse<CashDepositsDetailEntity> =
    await logisticsApiClient.patch(
      updateCashDepositsDetailUrl(depositId),
      requestBody,
      {
        meta: { userRole: UserRoleData.HUB_MANAGER },
      }
    );
  return axiosResponse.data;
};

export const depositVerificationRequest = async (
  requestBody: UpdateDepositPayload,
  depositId: string
) => {
  const axiosResponse: AxiosResponse<CashDepositsDetailEntity> =
    await logisticsApiClient.post(
      depositRequestVerificationUrl(depositId),
      requestBody,
      {
        meta: { userRole: UserRoleData.HUB_MANAGER },
      }
    );
  return axiosResponse.data;
};

export const cashDepositDetail = async (depositId: string) => {
  const axiosResponse: AxiosResponse<CashDepositsDetailEntity> =
    await logisticsApiClient.get(depositDetailUrl(depositId));
  return axiosResponse.data;
};

export const depositHandoverDetail = async (depositId: string) => {
  const axiosResponse: AxiosResponse<DepositHandoverDetailEntity[]> =
    await logisticsApiClient.get(depositHandoverDetailUrl(depositId));
  return axiosResponse.data;
};
