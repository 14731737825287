import { entityActions } from '../entities/reducer';
import { AppThunk } from '../store';
import { LoadingStatus } from '../../data/model/interfaces';
import { entityStateKeys } from '../entities/constants';
import { reporteeListSelectors } from './selectors';
import { listActions } from '../lists/reducer';
import { listSateKeys } from '../lists/constants';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { fetchReporteeList } from '../../data/api/reportee';

const fetchList = (mangerId: string, forceReload = false): AppThunk => {
  return async (dispatch, getState) => {
    if (
      reporteeListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.REPORTEE_LIST));
    }
    if (
      reporteeListSelectors.selectListItems(getState()).length <
        reporteeListSelectors.selectPageSize(getState()) *
          reporteeListSelectors.selectPageNumber(getState()) ||
      reporteeListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.REPORTEE_LIST,
          })
        );
        const bapListResponse = await fetchReporteeList(
          mangerId,
          reporteeListSelectors.selectPageNumber(getState())
        );
        dispatch(
          listActions.setListResponse({
            listItems: bapListResponse.results.map(
              (item) => item.reportee_id + item.reportee_role
            ),
            count: bapListResponse.count,
            key: listSateKeys.REPORTEE_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: bapListResponse.results.map((result) => ({
              entity: result,
              entityId: result.reportee_id + result.reportee_role,
            })),
            key: entityStateKeys.REPORTEE_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.REPORTEE_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.REPORTEE_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (managerId: string): AppThunk => {
  return async (dispatch, getState) => {
    if (
      reporteeListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      reporteeListSelectors.selectCount(getState()) >
      reporteeListSelectors.selectPageSize(getState()) *
        reporteeListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: reporteeListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.REPORTEE_LIST,
        })
      );
      dispatch(fetchList(managerId));
    }
  };
};

const reloadListCurrentPage = (managerId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: reporteeListSelectors.selectPageNumber(getState()),
        key: listSateKeys.REPORTEE_LIST,
      })
    );
    dispatch(fetchList(managerId));
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: reporteeListSelectors.selectPageNumber(getState()) - 1,
        key: listSateKeys.REPORTEE_LIST,
      })
    );
  };
};

export const reporteeListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
};
