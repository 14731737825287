import { useTheme } from '@emotion/react';
import { Button, Chip, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import { AssignHubFormValues } from './AssignToHubForm/assign-to-hub-form-schema';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { UpdateShipmentPayload, ShipmentDetailEntity } from '../../../../data/model/trip/shipment';
import { useAppDispatch } from '../../../../redux/hooks';
import { unAssignedShipmentListThunkActions } from '../../../../redux/unassignedshipment-list/thunk-actions';
import { dialogTransition } from '../../../../utils/transitions';
import AssignHubForm from './AssignToHubForm';

const AssignHubButton = ({
  selectedShipmentId,
  refreshList,
}: {
  selectedShipmentId: GridRowSelectionModel;
  refreshList: () => void
}) => {
  const dispatch = useAppDispatch();
  const handleSubmit = async (formValues: AssignHubFormValues) => {

    let requestData: UpdateShipmentPayload = {
      current_hub_id: formValues.hub_id.value,
    }
    let response: ShipmentDetailEntity | undefined = await dispatch(
      unAssignedShipmentListThunkActions.updateShipmentAction({
        requestData: requestData,
        shipmentId: selectedShipmentId.toString(),
      })
    );
    if (response) {
      refreshList();
      closeDialog();
    }
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        disabled={selectedShipmentId ? selectedShipmentId.length === 0 : false}
      >
        Assign to Hub
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <AssignHubForm
            closeDialog={closeDialog}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignHubButton;
