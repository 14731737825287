import { AxiosResponse } from 'axios';
import { SaveAddressPayload, AddressDetailEntity } from '../model/trip/address';
import { logisticsApiClient } from './logisticsApiClient';
import { getAddressListUrl } from './apiUrls/logisticsApiUrls';

export const saveAddress = async (requestBody: SaveAddressPayload) => {
  const axiosResponse: AxiosResponse<AddressDetailEntity> =
    await logisticsApiClient.post(getAddressListUrl, requestBody);
  return axiosResponse.data;
};

export const updateAddress = async (requestBody: SaveAddressPayload) => {
  const axiosResponse: AxiosResponse<AddressDetailEntity> =
    await logisticsApiClient.patch(getAddressListUrl, requestBody);
  return axiosResponse.data;
};
