import { IFilterValue } from '../../data/model/interfaces';

export enum ShipmentStatus {
  // Hub manager will be able to see Shipment and can plan trip in advance
  CREATED = 'CREATED',
  //Once item is ready for pickup, commerce will update status to READY_FOR_PICKUP
  //On this status, Hub manager has to initaite trip for pickup
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  //Once item is picked, shipment status will move to IN_TRANSIT
  IN_TRANSIT = 'IN_TRANSIT',
  //Once item is delivered, shipment status will move to DELIVERED
  DELIVERED = 'DELIVERED',
  //If customer rejected to accept order
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  //If somehow shipment need to be cancelled, then status will move to cancelled
  CANCELLED = 'CANCELLED',
}

export enum ShipmentTrailConstants {
  SHIPMENT_CREATED = 'SHIPMENT_CREATED',
  SHIPMENT_READY_FOR_PICKUP = 'SHIPMENT_READY_FOR_PICKUP',
  SHIPMENT_OUT_FOR_PICKUP = 'SHIPMENT_OUT_FOR_PICKUP',
  SHIPMENT_PICKED = 'SHIPMENT_PICKED',
  REACHED_HUB = 'REACHED_HUB',
  LEFT_HUB = 'LEFT_HUB',
  SHIPMENT_OUT_FOR_DELIVERY = 'SHIPMENT_OUT_FOR_DELIVERY',
  SHIPMENT_DELIVERED = 'SHIPMENT_DELIVERED',
  SHIPMENT_CANCELLED = 'SHIPMENT_CANCELLED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
}

export enum TripStatus {
  // Default Status is CREATED
  // Trip is created by hub manager, but is not started yet
  CREATED = 'CREATED',
  // Once DA reached hub and ready to start his trip, will move status to STARTED
  STARTED = 'STARTED',
  // Once all deliveries are done by DA, will move status to COMPLETED
  COMPLETED = 'COMPLETED',
  // If somehow trip need to be canncelled, status will move to CANCELLED
  CANCELLED = 'CANCELLED',
}

export enum TripShipmentRelationStatus {
  // Default Status is CREATED
  // DA will be able to see assigned TripShipmentRelations
  CREATED = 'CREATED',
  // Once DA is out for pick up, status will change to OUT_FOR_PICKUP
  OUT_FOR_PICKUP = 'OUT_FOR_PICKUP',
  // Once item is picked, status will move to IN_TRANSIT
  IN_TRANSIT = 'IN_TRANSIT',
  // Once item is delivered, shipment status will move to DELIVERED
  DELIVERED = 'DELIVERED',
  // If customer rejected to accept shipment, status will move to CUSTOMER_REJECTED
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  // If somehow DA is unable to deliver while completing trip,
  // status will move to NOT_DELIVERED
  NOT_DELIVERED = 'NOT_DELIVERED',
}

export class TripStatusFilter {
  static readonly FILTER_KEY = 'status';
}

export class OneBridgeTripFilter {
  static readonly FILTER_KEY = 'SELF';
}
export class TPLTripFilter {
  static readonly FILTER_KEY = 'TPL';
}
export class LocalTripFilter {
  static readonly FILTER_KEY = 'LOCAL';
}

export class LogisticsPartnerFilter {
  static readonly FILTER_KEY = 'logistics_partner';
}

export enum TripStatusFilterLabel {
  CREATED = 'Created',
  STARTED = 'Ongoing',
  COMPLETED = 'Completed',
}

export enum TripLogisticsPartnerFilterLabel {
  ONE_BRIDGE = '1Bridge',
  TPL = '3PL',
  LOCAL = 'Local',
}

export enum LogisticsPartner {
  ONE_BRIDGE = 'SELF',
  TPL = 'TPL',
  LOCAL = 'LOCAL',
}

export class ShipmentIDFilter {
  static readonly FILTER_KEY = 'status';
}

export class ShipmentTripAssignedFilter {
  static readonly FILTER_KEY = 'trip_created';
  static readonly FILTER_OPTIONS: IFilterValue[] = [
    { label: 'Unassigned', value: 'false' },
    { label: 'Assigned', value: 'true' },
  ];
}

export enum ShipmentTripAssignedLabel {
  ASSIGNED = 'Assigned',
  UNASSIGNED = 'Unassigned',
}

export enum AddressType {
  ALL = 'ALL',
  RETAILER = 'RETAILER',
  SELLER = 'SELLER',
  BAP = 'BAP',
  HUB = 'HUB',
  OTHERS = 'OTHERS',
}
