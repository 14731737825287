import { AxiosResponse } from 'axios';
import {
  CreateShipmentPayLoad,
  ShipmentDetailEntity,
  ShipmentListEntity,
  ShipmentTrailListEntity,
  UpdateShipmentPayload,
} from '../model/trip/shipment';
import { logisticsApiClient } from './logisticsApiClient';
import { ListResponse } from '../model/interfaces';
import { AddressListEntity } from '../model/trip/address';
import {
  getAddressListUrl,
  getShipmentTrailUrl,
  shipmentDetailUrl,
  shipmentUrl,
} from './apiUrls/logisticsApiUrls';

export const getShipmentList = async ({
  hubIds,
  pageNumber = 1,
  queryParams,
}: {
  hubIds: string[];
  pageNumber?: number;
  queryParams?: {
    [key: string]: any;
  };
}) => {
  const axiosResponse: AxiosResponse<ListResponse<ShipmentListEntity>> =
    await logisticsApiClient.get(shipmentUrl, {
      params: {
        hub_ids: hubIds.join(','),
        page: pageNumber,
        ...queryParams,
        // upcoming_shipment: true,
        hub_assigned: true,
      },
    });
  return axiosResponse.data;
};

export const getShipmentDetailById = async (shipmentId: string) => {
  const axiosResponse: AxiosResponse<ShipmentDetailEntity> =
    await logisticsApiClient.get(shipmentDetailUrl(shipmentId));
  return axiosResponse.data;
};

export const getUnAssignedShipmentList = async ({
  pageNumber = 1,
}: {
  pageNumber?: number;
}) => {
  const axiosResponse: AxiosResponse<ListResponse<ShipmentListEntity>> =
    await logisticsApiClient.get(shipmentUrl, {
      params: {
        page: pageNumber,
        hub_assigned: false,
      },
    });
  return axiosResponse.data;
};

export const updateShipment = async ({
  requestData,
  shipmentId,
}: {
  requestData: UpdateShipmentPayload;
  shipmentId: string;
}) => {
  const axiosResponse: AxiosResponse<ShipmentDetailEntity> =
    await logisticsApiClient.patch(shipmentDetailUrl(shipmentId), requestData);
  return axiosResponse.data;
};

export const createShipment = async (requestBody: CreateShipmentPayLoad) => {
  const axiosResponse: AxiosResponse<ShipmentListEntity> =
    await logisticsApiClient.post(shipmentUrl, requestBody);
  return axiosResponse.data;
};

export const getShipmentTrails = async (shipmentId: string) => {
  const axiosResponse: AxiosResponse<ShipmentTrailListEntity[]> =
    await logisticsApiClient.get(getShipmentTrailUrl(shipmentId));
  return axiosResponse.data;
};

export const getAddressList = async (
  addressType: string,
  searchString?: string
) => {
  const axiosResponse: AxiosResponse<ListResponse<AddressListEntity>> =
    await logisticsApiClient.get(getAddressListUrl, {
      params: {
        address_type: addressType,
        search_query: searchString,
      },
    });
  return axiosResponse.data;
};
