import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddAddressForm from '../AddOrUpdateAddressForm';
import { dialogTransition } from '../../../../../../utils/transitions';
import { useState } from 'react';
import { AddressDetailEntity } from '../../../../../../data/model/trip/address';
import EditIcon from '@mui/icons-material/Edit';

const AddOrEditAddressButton = ({
  addressDetails,
  setSelectedAddress,
  setSelectedAddressType,
  setNewAddress,
}: {
  addressDetails?: AddressDetailEntity;
  setSelectedAddress: any;
  setSelectedAddressType: any;
  setNewAddress: any;
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Typography variant="h6" sx={{ paddingTop: '12px' }}>
        {!addressDetails && !isMobileScreen && 'or'}
      </Typography>
      <Box sx={{ width: '12px' }}></Box>
      <Button
        id="basic-button"
        variant="text"
        size="large"
        onClick={openDialog}
      >
        {addressDetails ? <EditIcon /> : 'Add New Address'}
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <AddAddressForm
            addressDetails={addressDetails}
            closeDialog={closeDialog}
            setSelectedAddress={setSelectedAddress}
            setSelectedAddressType={setSelectedAddressType}
            setNewAddress={setNewAddress}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddOrEditAddressButton;
