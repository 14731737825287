import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FormFieldGrid } from '@data-driven-forms/mui-component-mapper';
import { useFieldApi } from '..';
import { dialogTransition } from '../../../utils/transitions';
import MarkerIcon from '@mui/icons-material/FmdGood';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import getLocation, { LocationInfo } from './get-location';
import * as environment from '../../../environment';

export default function CustomLatLonField(props: any) {
  const { input, meta, label, isRequired } =
    useFieldApi<LocationInfo>(props);

  const [open, setOpen] = useState(false);
  const [hasLocationAccess, setHasLocationAccess] = useState(false);

  const [locationValue, setLocationValue] = useState<LocationInfo>({
    lat: 0,
    lon: 0,
  });

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lon: 0,
  });

  if ('permissions' in navigator) {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        // Location access is granted or pending user response
        setHasLocationAccess(true);
      } else {
        // Location access is denied
        setHasLocationAccess(false);
      }

      // Listen for changes in permission status
      result.onchange = () => {
        if (result.state === 'granted') {
          setHasLocationAccess(true);
        } else {
          setHasLocationAccess(false);
        }
      };
    });
  } else {
    // Geolocation is not available in this browser
    setHasLocationAccess(false);
  }

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handelOnDone = () => {
    setSelectedLocation(locationValue);
    input.onChange(locationValue);
    closeDialog();
  };

  useEffect(() => {
    getLocation(
      (locationData) => {
        setSelectedLocation({
          lat: locationData.lat,
          lon: locationData.lon,
        });
      },
      input,
      setHasLocationAccess
    );
  }, []);

  return (
    <FormFieldGrid>
      <TextField
      label={label}
        fullWidth
        error={meta.touched && meta.error ? meta.error : undefined}
        helperText={meta.touched && meta.error ? meta.error : undefined}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <span style={{ color: 'black', opacity: '1.0' }}>
                {selectedLocation.lat !== 0 && selectedLocation.lon !== 0
                  ? `Lat:${selectedLocation.lat.toFixed(4)}   Lng: ${selectedLocation.lon.toFixed(4)}`
                  : undefined}
              </span>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="Get Location"
                title="Get Location"
                size="medium"
                className="MuiFileInput-IconButton"
                onClick={openDialog}
                sx={{
                    fontSize: '16px',
                    lineHeight: '1.5',
                    fontWeight: 'bold',
                    color: 'black', 
                    opacity: '0.7',

                  '&:hover': {
                    backgroundColor: 'rgba(95, 58, 159, 0.2)',
                    borderRadius: '0',
                  },
                }}
              >
                CHANGE
                <Box sx={{ width: '8px' }} />
                <MarkerIcon />
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            text: input?.value ?? (isRequired ? `${label} *` : label),
          },
        }}
      />
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <GoogleMapReact
            onDragEnd={(map: any) =>
              setLocationValue({ lat: map.center.lat(), lon: map.center.lng() })
            }
            onDrag={(map) => {
              setLocationValue({
                lat: map.center.lat(),
                lon: map.center.lng(),
              });
            }}
            draggable={true}
            bootstrapURLKeys={{
              key: environment.googleApiKey ?? '',
            }}
            defaultCenter={{
              lat: selectedLocation.lat,
              lng: selectedLocation.lon,
            }}
            zoom={15}
            style={{
              width: 600,
              height: 300,
            }}
          >
            <Marker lat={locationValue.lat} lng={locationValue.lon} />
          </GoogleMapReact>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton onClick={handelOnDone} variant="contained">
            Done
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormFieldGrid>
  );
}

const Marker = ({ lat, lng }: any) => (
  <div>
    <LocationOnIcon style={{ fontSize: '36px', color: 'purple' }} />
  </div>
);
