import { UserRoleData } from '../constants/auth/userRoles';
import admin from './admin';
import cashManagement from './cash-management';
import dashboard from './dashboard';
import serviceAbility from './serviceAbility';
import trips from './trips';
import deliveryAgent from './delivery-agent';
import employeeMangement from './employeee-management';

export function getMenuItems(userRole: UserRoleData) {
  const commonItems = [dashboard, admin, trips, cashManagement, deliveryAgent, serviceAbility, employeeMangement];

  if (userRole === UserRoleData.LOGISTICS_ADMIN) {
    return commonItems;
  } else {
    return commonItems.filter(item => item !== admin);
  }
}
