import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../../../../redux/store';
import { UserRoleData } from '../../../../../../constants/auth/userRoles';

export const TripIdButton = ({ tripId }: { tripId: number }) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    if(tripId) {
      navigate(`/trips/${tripId}`);
    }
  };
  const { selectedUserRole } = store.getState().sharedPref;

  return (
    <>
      {
        selectedUserRole === UserRoleData.LOGISTICS_ADMIN ? (
          <Button
            id="basic-button"
            variant="text"
            size="medium"
            onClick={handleSubmit}
          >
            {tripId ?? '--'}
          </Button>
        ) : (
          tripId
        )
      }
    </>
  );
};
