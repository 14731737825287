import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { dialogTransition } from '../../utils/transitions';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

export function CustomDialogBox({
  open,
  closeDialog,
  handleSubmit,
  isSubmitting,
  buttonText,
  messageBody,
  boldMessageBody,
  hasIcon = false,
  icon,
}: {
  open: boolean;
  closeDialog: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  buttonText: string;
  messageBody: string;
  hasIcon?: boolean;
  icon?: React.ReactNode;
  boldMessageBody?: string
}) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={dialogTransition}
      scroll="body"
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 400,
        }}
      >
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          {hasIcon
            ? icon ?? (
                <WarningAmberRoundedIcon
                  sx={{
                    fontSize: '64px',
                    color: theme.palette.warning.dark,
                    mb: 1,
                  }}
                />
              )
            : undefined}
        </Box>

        <p>{messageBody}
        <strong>
          {boldMessageBody}
        </strong>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={handleSubmit}
          variant="contained"
        >
          {buttonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialogBox;