import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuRounded';
import { ProfileSection } from './ProfileSection';
import { MouseEventHandler } from 'react';
import SelectHubDialogButton from '../../../modules/auth/SelectHubPage/SelectHubDialog';
import { useAppSelector } from '../../../redux/hooks';

const Header = ({
  handleLeftDrawerToggle,
}: {
  handleLeftDrawerToggle: MouseEventHandler;
}) => {
  const theme = useTheme();
  const { selectedHubId } = useAppSelector((state) => state.sharedPref);
  return (
    <>
      <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
        <Avatar
          variant="rounded"
          sx={{
            transition: 'all .2s ease-in-out',
            background: theme.palette.action.disabledBackground,
            color: theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          }}
          onClick={handleLeftDrawerToggle}
          color="inherit"
        >
          <MenuOpenIcon />
        </Avatar>
      </ButtonBase>
      <Box
        sx={{
          ml: 2,
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'block' },
            flexGrow: 1,
            color: theme.palette.secondary.dark,
          }}
        >
          <h4>
            <b>Ruban Logistics Console</b>
          </h4>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {
        selectedHubId
        ? <SelectHubDialogButton />
        : undefined
      }
      <Box sx={{ flexGrow: 1 }} />
      <ProfileSection />
    </>
  );
};
export default Header;
