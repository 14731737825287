import { Grid } from '@mui/material';

import { getMenuItems } from '../../../menu-items';
import { MenuCard } from './MenuCard';
import { MenuItem } from '../../../menu-items/type';
import { useAppSelector } from '../../../redux/hooks';

function HomePage() {
  const userRole = useAppSelector((state) => state.sharedPref.selectedUserRole);

  let menuItems = getMenuItems(userRole);
  const menuItemChildren = menuItems.reduce<MenuItem[]>(
    (accumulator, currentValue) => {
      if (currentValue.children && currentValue.id !== 'dashboard') {
        return [...accumulator, ...currentValue.children];
      }
      return accumulator;
    },
    []
  );

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{
        p: '2rem',
      }}
    >
      {menuItemChildren.map((menuItem, index) => (
        <Grid item xs={12} sm={4} md={4} key={index}>
          <MenuCard menuItem={menuItem} />
        </Grid>
      ))}
    </Grid>
  );
}

export default HomePage;
