import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import ShipmentDetailPage from '../../modules/trip/shipments/ShipmentDetailPage';
import UnAssignedShipmentListPage from '../../modules/admin-access/UnAssignedShipmentList';

const AdminRoutes: RouteObject = {
  element: <MainLayout />,
  children: [
    {
      path: 'unassigned-shipments',
      element: <UnAssignedShipmentListPage />,
    },
    {
      path: 'shipments/:shipmentId',
      element: <ShipmentDetailPage />,
    },
  ],
};

export default AdminRoutes;
