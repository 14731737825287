import { LoadingStatus } from '../../data/model/interfaces';
import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import { shipmentListSelectors } from './selectors';
import { getShipmentList } from '../../data/api/shipment';
import { getQueryParams } from '../../utils/qurey-param-util';
import { SnakbarUtils } from '../../utils/snakbar-util';

const fetchList = (forceReload = false): AppThunk => {
  return async (dispatch, getState) => {
    const hubId = getState().sharedPref.selectedHubId;
    if (
      shipmentListSelectors.selectLoadingStatus(getState()) ===
        LoadingStatus.LOADING ||
      hubId == null
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.SHIPMENT_LIST));
    }
    if (
      shipmentListSelectors.selectListItems(getState()).length <
        shipmentListSelectors.selectPageSize(getState()) *
          shipmentListSelectors.selectPageNumber(getState()) ||
      shipmentListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.SHIPMENT_LIST,
          })
        );
        const shipmentListApiResponse = await getShipmentList({
          hubIds: [hubId],
          pageNumber: shipmentListSelectors.selectPageNumber(getState()),
          queryParams: getQueryParams(shipmentListSelectors.selectFilters(getState())),
        });
        dispatch(
          listActions.setListResponse({
            listItems: shipmentListApiResponse.results.map(
              (item) => item.short_tracking_id
            ),
            count: shipmentListApiResponse.count,
            key: listSateKeys.SHIPMENT_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: shipmentListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.short_tracking_id.toString(),
            })),
            key: entityStateKeys.SHIPMENT_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.SHIPMENT_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.SHIPMENT_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (
      shipmentListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      shipmentListSelectors.selectCount(getState()) >
      shipmentListSelectors.selectPageSize(getState()) *
        shipmentListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: shipmentListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.SHIPMENT_LIST,
        })
      );
      dispatch(fetchList());
    }
  };
};

const reloadListCurrentPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: shipmentListSelectors.selectPageNumber(getState()),
        key: listSateKeys.SHIPMENT_LIST,
      })
    );
    dispatch(fetchList());
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (shipmentListSelectors.selectPageNumber(getState()) > 1) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: shipmentListSelectors.selectPageNumber(getState()) - 1,
          key: listSateKeys.SHIPMENT_LIST,
        })
      );
    }
  };
};

export const shipmentListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
};
