import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { drawerWidth } from '../constant';
import { actions } from '../../redux/layout/reducer';
import { useEffect } from 'react';
import { authenticationStatusSelector } from '../../redux/login/selectors';
import { AuthenticationStatus } from '../../redux/login/type';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginTop: '65px',
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -drawerWidth,
    flex: '1 1 100%',
    width: 0,
    marginRight: '0px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
    marginRight: '0px',
    flex: '1 1 100%',
    width: 0,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
    flex: '1 1 100%',
    padding: '0px',
    marginRight: '0px',
    width: 0,
  },
}));

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const leftDrawerOpened = useAppSelector((state) => state.layout.openDrawer);
  const dispatch = useAppDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch(actions.toogleDrawer(!leftDrawerOpened));
  };

  const authenticationStatus = useAppSelector(authenticationStatusSelector);

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.UNAUTHENTICATED) {
      navigate('/login');
    }
  }, [authenticationStatus]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={1}
        sx={{
          bgcolor: theme.palette.tonalOffset,
          transition: leftDrawerOpened
            ? theme.transitions.create('width')
            : 'none',
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main open={leftDrawerOpened}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
