import { Button, Dialog, DialogContent } from '@mui/material';
import { useAppDispatch } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import {
  CashDepositsListEntity,
  UpdateDepositPayload,
} from '../../../../../data/model/cash-deposit/cashDeposit';
import { cashDepositsListThunkActions } from '../../../../../redux/cash-deposit-list/thunk-actions';
import CashDepositForm from '../AddNewDepositButton/AddNewDepositForm';
import { CreateDepositFormValues } from '../AddNewDepositButton/AddNewDepositForm/new-deposit-form-schema';
import { Image } from '../../../../../data/model/interfaces';
import { SnakbarUtils } from '../../../../../utils/snakbar-util';
import { CashDepositStatus } from '../../../../../constants/cashDeposit/cashDeposit';
import useDialogBox from '../../../../../utils/dialogbox-hook';

const UpdateDepositButton = ({
  depositDetail,
}: {
  depositDetail: CashDepositsListEntity;
}) => {
  const dispatch = useAppDispatch();
  const dialogBox = useDialogBox();

  const handleSubmit = async (formValues: CreateDepositFormValues) => {
    const imagesUrlList = formValues.transaction_images.filter(
      (image: Image) => image !== undefined
    );
    if (imagesUrlList.length === 0) {
      return SnakbarUtils.showErrorMessage('Transaction image is Mandatory');
    }
    let requestBody: UpdateDepositPayload = {
      transaction_id: formValues.transaction_id,
      transaction_proof_img_url: imagesUrlList.map(
        (image: Image) => image.photo_url
      ),
      deposit_date_time: formValues.deposit_date_time,
      notes: formValues.notes,
    };

    let response: any;
    dialogBox.setIsSubmitting(true);
    if (depositDetail.status === CashDepositStatus.DRAFTED) {
      response = await dispatch(
        cashDepositsListThunkActions.depositVerificationRequestAction(
          requestBody,
          depositDetail.deposit_id
        )
      );
    } else {
      response = await dispatch(
        cashDepositsListThunkActions.updateDepositAction(
          requestBody,
          depositDetail.deposit_id
        )
      );
    }

    if (response) {
      dialogBox.closeDialog();
    }
    dialogBox.setIsSubmitting(false);
  };

  return (
    <>
      <Button
        id='basic-button'
        variant='contained'
        size='small'
        onClick={dialogBox.openDialog}
      >
        Update
      </Button>
      <Dialog
        open={dialogBox.open}
        onClose={dialogBox.closeDialog}
        TransitionComponent={dialogTransition}
        scroll='body'
      >
        <DialogContent
          sx={{
            width: '500px',
          }}
        >
          <CashDepositForm
            closeDialog={dialogBox.closeDialog}
            handleSubmit={handleSubmit}
            depositDetail={depositDetail}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateDepositButton;
