import { Box, Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import { Address } from '../../../../../data/model/trip/shipment';
import { DisplayUtils } from '../../../../../utils/display-util';
import UpdateDropAddressForm from './AddressForm';
import { SelectHubFormValues } from '../../../../auth/SelectHubPage/select-hub-form-schema';
import {
  TripShipmentListEntity,
  UpdateTripShipmentDetailPayload,
} from '../../../../../data/model/trip/trip';
import { TripShipmentRelationStatus } from '../../../../../constants/trips/trip-list';
import { tripShipmentListThunkActions } from '../../../../../redux/trip-shipment-relation-list/thunk-actions';

export const UpdateDropAddressButton = ({
  dropAddress,
  tripId,
  shipmentId,
  tripShipmentStatus,
}: {
  dropAddress: Address;
  tripId: string;
  shipmentId: string;
  tripShipmentStatus: string;
}) => {
  const dispatch = useAppDispatch();
  const handleSubmit = async (formValues: SelectHubFormValues) => {
    let requestBody: UpdateTripShipmentDetailPayload = {
      next_hub_id: formValues.hub_object.value.id,
    };

    let response: TripShipmentListEntity | undefined = await dispatch(
      tripShipmentListThunkActions.updateTripShipmentDetailAction({
        tripId: tripId,
        shipmentId: shipmentId,
        requestBody: requestBody,
      })
    );
    if (response) {
      closeDialog();
    }
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return tripShipmentStatus === TripShipmentRelationStatus.CUSTOMER_REJECTED ||
    tripShipmentStatus === TripShipmentRelationStatus.DELIVERED ? (
    <Box>{DisplayUtils.getFullAddress(dropAddress)}</Box>
  ) : (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={openDialog}
      >
        {DisplayUtils.getFullAddress(dropAddress)}
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <UpdateDropAddressForm
            closeDialog={closeDialog}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
