import { Button } from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { unlinkPincodeAction } from '../../../../redux/pincode/thunk-actions';
import CustomDialogBox from '../../../../reusable-components/CustomDialogBox';

export const RemovePincodeButton = ({
  value,
  onClick,
}: {
  value: any;
  onClick: MouseEventHandler;
}) => {
  const { selectedHubName } = useAppSelector((state) => state.sharedPref);
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let response: boolean | undefined = await dispatch(
      unlinkPincodeAction(value)
    );

    if (response) {
      onClick(value);
      closeDialog();
    }
    setIsSubmitting(false);
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={openDialog}
      >
        Unlink
      </Button>
      <CustomDialogBox
        open={open}
        closeDialog={closeDialog}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        buttonText="Yes, Unlink"
        messageBody={`Are you sure, you want to unlink this pincode from ${selectedHubName}?`}
        hasIcon
      />
    </>
  );
};
