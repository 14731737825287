import { GridColDef, GridRowModesModel } from '@mui/x-data-grid';
import { DeliveryAgentListEntity } from '../../../data/model/deliveryAgent/deliveryAgent';

export const getColumns: (
  rowModesModel: GridRowModesModel
) => GridColDef<DeliveryAgentListEntity, any, any>[] = (rowModesModel) => [
  {
    field: 'delivery_agent_id',
    headerName: 'Delivery Agent ID',
    type: 'string',
    minWidth: 300,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.row.user_id;
    },
  },
  {
    field: 'profile_name',
    headerName: 'Delivery Agent Name',
    type: 'string',
    minWidth: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.row.user_info.name;
    },
  },
  // {
  //   field: 'license_number',
  //   headerName: 'License Number',
  //   type: 'string',
  //   minWidth: 120,
  //   align: 'left',
  //   headerAlign: 'left',
  //   editable: false,
  //   sortable: false,
  //   valueFormatter(params) {
  //     return DateUtils.getFormattedDateString(params.value);
  //   },
  // },
  {
    field: 'phone',
    headerName: 'Mobile Number',
    type: 'string',
    minWidth: 150,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.row.user_info.phone;
    },
  },
  // {
  //   field: 'trip_completed',
  //   headerName: 'No. Of Trips Completed',
  //   minWidth: 120,
  //   align: 'left',
  //   headerAlign: 'left',
  //   editable: false,
  //   sortable: false,
  //   valueFormatter(params) {
  //     return DateUtils.getFormattedDateString(params.value);
  //   },
  // },
  // {
  //   field: 'total_earned',
  //   headerName: 'Total Money Earned',
  //   type: 'string',
  //   minWidth: 120,
  //   align: 'left',
  //   headerAlign: 'left',
  //   editable: false,
  //   sortable: false,
  //   valueFormatter(params) {
  //     return DateUtils.getFormattedDateString(params.value);
  //   },
  // },
];
