import { Paper } from '@mui/material';
import SimpleTable from '../../../../reusable-components/table/SimpleTable';
import { getTableRows, tableHead } from './table-data';

export const BulkUploadPreviewTable = ({ skuItems }: { skuItems: any[] }) => {
  return (
    <SimpleTable
      tableHead={tableHead}
      tableRows={getTableRows(skuItems)}
      isRowClickable
    />
  );
};
