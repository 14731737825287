import { RouteObject } from 'react-router-dom';
import UnAuthenticatedLayout from '../../layout/UnAuthenticatedLayout';
import LoginPage from '../../modules/auth/LoginPage';
import SelectHubPage from '../../modules/auth/SelectHubPage';

const AuthenticationRoutes: RouteObject = {
  path: '/',
  element: <UnAuthenticatedLayout />,
  children: [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/selectHub',
      element: <SelectHubPage />,
    },
  ],
};

export default AuthenticationRoutes;
