import { MenuItem } from './type';

export const tripsMenuItems: {
  [key: string]: MenuItem;
} = {
  tripsList: {
    id: 'trips',
    title: 'Trips',
    type: 'item',
    url: '/trips',
    target: true,
  },

  shipmentsList: {
    id: 'shipmentsList',
    title: 'Shipments List',
    type: 'item',
    url: '/shipments',
    target: true,
  },
};

const trips: MenuItem = {
  id: 'trips',
  title: 'Trip',
  type: 'group',
  children: [tripsMenuItems.tripsList, tripsMenuItems.shipmentsList],
};

export default trips;
