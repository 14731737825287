import { Box, Container, Toolbar, Typography } from '@mui/material';
import schema, { AddAddressFormValues } from './address-form-schema';
import {
  FormRenderer,
  CustomFormTemplate,
  customComponentMapper,
} from '../../../../../../reusable-components/data-driven-forms';
import { DisplayUtils } from '../../../../../../utils/display-util';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { addAddressAction } from '../../../../../../redux/address/thunk-actions';
import {
  AddressDetailEntity,
  SaveAddressPayload,
} from '../../../../../../data/model/trip/address';

const AddAddressForm = ({
  closeDialog,
  addressDetails,
  setSelectedAddress,
  setSelectedAddressType,
  setNewAddress,
}: {
  closeDialog: () => void;
  addressDetails?: AddressDetailEntity;
  setSelectedAddress?: any;
  setSelectedAddressType?: any;
  setNewAddress?: any;
}) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async (formValues: AddAddressFormValues & any) => {
    let requestBody: SaveAddressPayload = {
      address_type: formValues.address_type,
      address: {
        poc_name: formValues.address.poc_name,
        poc_phone: formValues.address.poc_phone,
        address_name: formValues.address.address_name,
        full_address: formValues.address.full_address,
        pincode: formValues.address.pincode,
        state: formValues.address.state,
        district: formValues.address.district,
        country: formValues.address.country,
        lat: formValues.address.lat_lon_object?.lat,
        lon: formValues.address.lat_lon_object?.lon,
      },
      save_address: formValues.save_address ?? false,
    };

    if (formValues.save_address) {
      let response: AddressDetailEntity | undefined = await dispatch(
        addAddressAction(requestBody)
      );
      if (response) closeDialog();
    } else {
      closeDialog();
    }
    setSelectedAddressType(requestBody.address_type);
    setSelectedAddress({
      label: `${
        formValues.address.address_name
      } >> ${DisplayUtils.getFullAddress(requestBody.address!)}`,
      value: requestBody,
    });
    setNewAddress(true);
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: 1,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Toolbar
          sx={{
            pl: { xs: 0 },
            pr: { xs: 0 },
            pb: 1,
          }}
        >
          <Typography variant="h6">
            {addressDetails ? 'Update Address' : 'Add Address'}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          initialValues={addressDetails}
          onCancel={() => {
            closeDialog();
          }}
        />
      </Container>
    </Box>
  );
};

export default AddAddressForm;
