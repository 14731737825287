import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AuthData } from '../../data/model/auth/auth';
import { SharedPrefState } from './type';
import { UserRoleData } from '../../constants/auth/userRoles';

export const setAuthData: CaseReducer<
  SharedPrefState,
  PayloadAction<AuthData | null>
> = (state, action) => {
  state.authData = action.payload;
};

export const setLoaded: CaseReducer<SharedPrefState> = (state) => {
  state.loaded = true;
};

export const setSelectedUserRole: CaseReducer<
  SharedPrefState,
  PayloadAction<UserRoleData>
> = (state, action) => {
  state.selectedUserRole = action.payload;
};

export const setSelectedClusterId: CaseReducer<
  SharedPrefState,
  PayloadAction<string | null>
> = (state, action) => {
  state.selectedClusterId = action.payload;
};

export const setSelectedBusinessId: CaseReducer<
  SharedPrefState,
  PayloadAction<string | null>
> = (state, action) => {
  state.selectedBusinessId = action.payload;
};

export const setSelectedHubId: CaseReducer<
  SharedPrefState,
  PayloadAction<string | null>
> = (state, action) => {
  state.selectedHubId = action.payload;
};
export const setSelectedHubName: CaseReducer<
  SharedPrefState,
  PayloadAction<string | null>
> = (state, action) => {
  state.selectedHubName = action.payload;
};