import Autocomplete from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { getAddressList } from '../../../../../../data/api/shipment';
import { DisplayUtils } from '../../../../../../utils/display-util';
import { AutocompleteOptionType } from './type';
import ClearOutlined from '@mui/icons-material/ClearOutlined';
import parse from 'autosuggest-highlight/parse';
import { AddressType } from '../../../../../../constants/trips/trip-list';
import AddOrEditAddressButton from '../AddOrEditAddressButton';

export default function AddressTextField({
  headingText,
  onSubmit,
}: {
  headingText: string;
  onSubmit: (selectedAddressData: AutocompleteOptionType) => void;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [fetchingData, setFethingData] = useState(false);

  const isLoading = fetchingData;

  const [inputValue, setInputValue] = useState('');
  const [newAddress, setNewAddress] = useState(false);

  const [options, setOptions] = useState<AutocompleteOptionType[]>([]);
  const [selectedAddress, setSelectedAddress] =
    useState<AutocompleteOptionType>();

  const [selectedAddressType, setSelectedAddressType] = useState('ALL');

  const handleOptionChange = (event: any) => {
    if (selectedAddressType !== event.target.value) {
      setSelectedAddressType(event.target.value);
      setSelectedAddress(undefined);
    }
  };

  const saveAddress = selectedAddress?.value.save_address;

  const fetchOptions = async () => {
    setFethingData(true);
    try {
      const response = await getAddressList(
        selectedAddressType !== AddressType.ALL ? selectedAddressType : ''
      );
      const options = response.results.map((item) => ({
        label: `${item.address.address_name} >> ${DisplayUtils.getFullAddress(
          item.address
        )}`,
        value: item,
      }));
      setFethingData(false);
      setOptions(options);
      if (selectedAddress) {
        onSubmit(selectedAddress);
      }
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, [selectedAddressType, inputValue, saveAddress]);

  return (
    <Box>
      <Typography variant="h6"> {headingText}</Typography>
      <br></br>
      <Stack spacing={2} direction={isMobileScreen ? 'column' : 'row'}>
        <>
          <TextField
            style={{ width: '35%' }}
            select
            value={selectedAddressType}
            disabled={newAddress}
            onChange={handleOptionChange}
          >
            <MenuItem value={AddressType.ALL}>All</MenuItem>
            <MenuItem value={AddressType.RETAILER}>Retailers</MenuItem>
            <MenuItem value={AddressType.SELLER}>Sellers</MenuItem>
            <MenuItem value={AddressType.BAP}>Bap</MenuItem>
            <MenuItem value={AddressType.HUB}>Hub</MenuItem>
            <MenuItem value={AddressType.OTHERS}>Others</MenuItem>
          </TextField>
          {newAddress ? (
            <TextField
              sx={{ width: '100%' }}
              value={selectedAddress?.label}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Edit Address"
                      title="Edit Address"
                      size="small"
                      className="MuiFileInput-IconButton"
                      onClick={() => {
                        setSelectedAddress(undefined);
                        setNewAddress(false);
                      }}
                    >
                      <ClearOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <Autocomplete
              options={options}
              autoComplete
              sx={{ width: '100%' }}
              noOptionsText="No Options"
              getOptionLabel={(option) => {
                return selectedAddress?.label ?? '';
              }}
              value={selectedAddress}
              onChange={(event, value) => {
                if (value) {
                  setSelectedAddress(value);
                  onSubmit(value);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.label, matches);
                return (
                  <li {...props} key={JSON.stringify(option.value)}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
            />
          )}
        </>
        {!inputValue && (
          <AddOrEditAddressButton
            setNewAddress={setNewAddress}
            setSelectedAddress={setSelectedAddress}
            setSelectedAddressType={setSelectedAddressType}
          />
        )}
      </Stack>
    </Box>
  );
}
