import { useAppDispatch } from '../../../redux/hooks';
import { Box, Container } from '@mui/material';
import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import {
  CustomFormTemplate,
  customComponentMapper,
} from '../../../reusable-components/data-driven-forms';
import {
  saveSelectedHubId, saveSelectedHubName,
} from '../../../redux/shared-pref/thunk-actions';
import schema, { SelectHubFormValues } from './select-hub-form-schema';

function SelectHubPage() {
  const dispatch = useAppDispatch();

  const handleSubmit = async ({ hub_object }: SelectHubFormValues) => {
    const { id, address } = hub_object.value;
    
    await Promise.all([
      dispatch(saveSelectedHubName(address.address_name)),
      dispatch(saveSelectedHubId(id)),
    ]);
  };
  

  return (
    <Box sx={{ width: '100%', height: '600px', mt: '100px' }}>
      <Container maxWidth="sm">
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
        />
      </Container>
    </Box>
  );
}

export default SelectHubPage;
