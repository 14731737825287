import { IFilter } from '../data/model/interfaces';

export const getQueryParams = (
  filters: IFilter[]
): {
  [key: string]: any;
} => {
  return filters.reduce((accumulator, currentValue) => {
    if (Array.isArray(currentValue.filterValue)) {
      if (currentValue.filterValue.length > 0) {
        return {
          ...accumulator,
          [currentValue.filterKey]: currentValue.filterValue
            .map((value) => value.value)
            .join(','),
        };
      }
    } else if (
      currentValue.filterValue !== null &&
      currentValue.filterValue !== undefined
    ) {
      return {
        ...accumulator,
        [currentValue.filterKey]: currentValue.filterValue.value,
      };
    }
    return accumulator;
  }, {});
};
