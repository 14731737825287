import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getColumns } from './trip-shipment-list-table-data';
import {
  DataGrid,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { TripShipmentListEntity } from '../../../../../data/model/trip/trip';
import PageContainer from '../../../../../reusable-components/PageContainer';
import { LoadingStatus } from '../../../../../data/model/interfaces';
import ListDataGridToolbar from '../../../../../reusable-components/data-grid/ListDataGridToolbar';
import RemoveShipmentButton from './RemoveShipmentsButton';
import { TripStatus } from '../../../../../constants/trips/trip-list';
import { tripShipmentListSelectors } from '../../../../../redux/trip-shipment-relation-list/selectors';
import { tripShipmentListThunkActions } from '../../../../../redux/trip-shipment-relation-list/thunk-actions';

function TripShipmentListPage({
  tripId,
  tripStatus,
}: {
  tripId: string;
  tripStatus: string;
}) {
  const tripShipmentList = useAppSelector(
    tripShipmentListSelectors.selectPageItems
  );
  const tripShipmentCount = useAppSelector(
    tripShipmentListSelectors.selectCount
  );
  const tripShipmentListLoadingStatus = useAppSelector(
    tripShipmentListSelectors.selectLoadingStatus
  );

  const title = 'Shipment List For Selected Trip';

  const dispatch = useAppDispatch();
  const [selectedRowsIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    dispatch(tripShipmentListThunkActions.fetchList({ tripId: tripId!, forceReload: true }));
  }, [dispatch, tripId]);

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: GridRowParams<TripShipmentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  return (
    <PageContainer dynamicHeight={true}>
      <DataGrid<TripShipmentListEntity>
        checkboxSelection={tripStatus === TripStatus.CREATED ? true : false}
        onRowSelectionModelChange={(selectedRow: GridRowId[]) =>
          setSelectedRowIds(selectedRow)
        }
        columns={getColumns(
          rowModesModel,
        )}
        rows={tripShipmentList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        pagination
        keepNonExistentRowsSelected
        editMode="row"
        getRowId={(row) => row.shipment.short_tracking_id}
        pageSizeOptions={[]}
        rowCount={tripShipmentCount}
        paginationMode="server"
        loading={tripShipmentListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title,
            prefixRefreshButton:
              tripStatus === TripStatus.CREATED ? (
                <RemoveShipmentButton
                  selectedRowsIds={selectedRowsIds}
                  tripId={tripId!}
                  setSelectedRowIds={setSelectedRowIds}
                />
              ) : undefined,
          },
        }}
        onRowEditStop={handleRowEditStop}
        rowSelection={true}
        disableColumnFilter
        getRowHeight={() => 'auto'}
      />
    </PageContainer>
  );
}

export default TripShipmentListPage;
