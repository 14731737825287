import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { dialogTransition } from '../../../../utils/transitions';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import {
  CustomFormTemplate,
  customComponentMapper,
} from '../../../../reusable-components/data-driven-forms';
import { Chip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import { switchHubThunkAction } from '../../../../redux/login/thunk-actions';
import { useState } from 'react';
import schema, { SelectHubFormValues } from '../select-hub-form-schema';

export default function SelectHubDialogButton() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const selectedHubName = useAppSelector(
    (state) => state.sharedPref.selectedHubName
  );

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async ({ hub_object }: SelectHubFormValues) => {
    const { id, address } = hub_object.value;
    const { address_name } = address;

    await dispatch(switchHubThunkAction(id, address_name));
    closeDialog();
  };

  return (
    <>
      <Chip
        onClick={openDialog}
        variant="outlined"
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={openDialog}
        label={selectedHubName ?? 'Select Hub'}
        icon={<ShareLocationIcon />}
        sx={{ mr: '1rem' }}
      />

      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent
          sx={{
            width: '300px',
            paddingTop: '50px'
          }}
        >
          <FormRenderer
            FormTemplate={CustomFormTemplate}
            componentMapper={customComponentMapper}
            schema={schema}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
