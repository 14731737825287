import FormFieldGrid from '@data-driven-forms/mui-component-mapper/form-field-grid/form-field-grid';
import Typography from '@mui/material/Typography/Typography';
import { useFieldApi, useFormApi } from '..';
import { useEffect, useMemo } from 'react';
import { SnakbarUtils } from '../../../utils/snakbar-util';
import { debounce } from '@mui/material';
import { AutocompleteOptionType } from '../CustomSearchableSelectField/type';

export function CustomPlainText(props: any) {
  const { label, input, loadOptions, formatter } =
    useFieldApi<AutocompleteOptionType>(props);

  const formOptions = useFormApi();

  const fetchResponse = useMemo(
    () =>
      debounce(
        (onSuccess: (value: any) => any, onError: (reason: any) => any) => {
          if (loadOptions) {
            loadOptions(formOptions.getState().values)
              .then(onSuccess)
              .catch(onError);
          }
        },
        200
      ),
    []
  );

  useEffect(() => {
      fetchResponse(
        (result: number) => {
          input.onChange(result);
        },
        (e) => {
          console.log(e);
          SnakbarUtils.showAxiosErrorMessage(e);
        }
      );
  }, []);

  return (
    <FormFieldGrid>
        <Typography
          variant="body1"
          sx={{ paddingBottom: '10px', fontWeight: 'bold' }}
        >{
          formatter(input?.value) ?? label
        }
        </Typography>
    </FormFieldGrid>
  );
}
