import { Typography } from '@mui/material';
import { TripStatus } from '../../../../../constants/trips/trip-list';

function TripStatusTag({ tripStatus }: { tripStatus: string }) {
  return (
      <Typography
        variant="subtitle2"
        component="div"
        style={{
          color: tripStatus === TripStatus.CREATED || tripStatus === TripStatus.COMPLETED
          ? 'rgba(1, 157, 89, 0.7)' 
          : tripStatus === TripStatus.STARTED
          ? 'rgba(0, 104, 165, 0.7)'
          : 'rgba(255, 0, 0, 0.7)',
          background: tripStatus === TripStatus.CREATED  || tripStatus === TripStatus.COMPLETED
          ? 'rgba(1, 157, 89, 0.2)' 
          : tripStatus === TripStatus.STARTED
          ? 'rgba(0, 104, 165, 0.2)'
          : 'rgba(255, 0, 0, 0.2)',
          display: 'inline-block',
          padding: '4px',
        }}
      >
        {tripStatus}
      </Typography>
  );
}

export default TripStatusTag;
