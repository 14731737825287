import { PaymentStatus } from '../../../../../../constants/trips/payment';
import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';
import { PaymentStatusOptions } from './create-shipment-options';

const schema = {
  fields: [
    {
      label: 'Product Details',
      name: 'item_details_text',
      component: customComponentTypes.PLAIN_TEXT,
      variant: 'h6',
    },
    {
      label: 'Product Name',
      name: 'item_details.item_name',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Product Price',
      name: 'item_details.cost',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Product Quantity(In nos)',
      name: 'item_details.quantity',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Product Weight(In gm)',
      name: 'item_details.weight',
      dataType: 'number',
      component: customComponentTypes.TEXT_FIELD,
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Product Length(In cm)',
      name: 'item_details.length',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Product Width(In cm)',
      name: 'item_details.width',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Product Height(In cm)',
      name: 'item_details.height',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
      FormFieldGridProps: { xs: 4 },
    },
    {
      label: 'Shipment has fragile content',
      name: 'item_details.handle_with_care',
      component: customComponentTypes.CUSTOM_CHECK_BOX,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'Note',
      name: 'note',
      component: customComponentTypes.TEXT_FIELD,
    },
    {
      label: 'Payment Status',
      name: 'shipment_payment_collection_status',
      component: customComponentTypes.RADIO,
      condition: {
        when: 'shipment_payment_collection_status',
        isNotEmpty: false,
        then: {
          set: () => {
            return { shipment_payment_collection_status: PaymentStatus.PAID };
          },
        },
        else: { visible: true },
      },
      options: PaymentStatusOptions,
    },
    {
      label: 'Amount',
      name: 'shipment_payment_collection_amount',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
      isRequired: true,
      condition: {
        when: 'shipment_payment_collection_status',
        is: PaymentStatus.UNPAID,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Est. Date of Delivery',
      name: 'scheduled_end_time',
      component: customComponentTypes.DATE_PICKER,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type CreateShipmentFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
