import FormFieldGrid from '@data-driven-forms/mui-component-mapper/form-field-grid/form-field-grid';
import { useFieldApi, useFormApi } from '..';
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export function CustomCheckBox(props: any) {
  const { input, label } = useFieldApi(props);
  const { getState } = useFormApi();

  let tooltip_text = getState().initialValues.tooltip_text;

  const handleChange = (value: boolean) => {
    input.onChange(value);
  };
  return (
    <FormFieldGrid>
      <FormControlLabel
        control={
          <MUICheckbox
            onChange={(value) => handleChange(value.target.checked)}
          />
        }
        label={label}
      />
      <Tooltip title={tooltip_text}>
        <IconButton size="small">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </FormFieldGrid>
  );
}
