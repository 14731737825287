import { useEffect, useState } from 'react';
import { getColumns } from './shipment-trail-list-table-data';
import { DataGrid, GridRowModesModel } from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import PageContainer from '../../../../../reusable-components/PageContainer';
import { LoadingStatus } from '../../../../../data/model/interfaces';
import ListDataGridToolbar from '../../../../../reusable-components/data-grid/ListDataGridToolbar';
import { shipmentTrailListSelectors } from '../../../../../redux/shipment-trails/selectors';
import { ShipmentTrailListEntity } from '../../../../../data/model/trip/shipment';
import { getShipmentTrails } from '../../../../../data/api/shipment';
import { PageLoading } from '../../../../../reusable-components/PageLoading';
import { PageLoadingFailed } from '../../../../../reusable-components/PageLoadingFailed';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box/Box';

function ShipmentTrailListPage({ shipmentId }: { shipmentId: string }) {
  const shipmentTrailListLoadingStatus = useAppSelector(
    shipmentTrailListSelectors.selectLoadingStatus
  );

  const [shipmentTripTrailsLoadingStatus, setShipmentTripTrailsLoadingStatus] =
    useState(LoadingStatus.IDLE);
  const [shipmentTrailList, setShipmentTrailList] = useState<ShipmentTrailListEntity[]>([{
    created: '',
    status: '',
    trip: {
      trip_id: 0,
      created: '',
      status: '',
    },
    hub: {
      poc_name: '',
      poc_phone: '',
      address_name: '',
      full_address: '',
      pincode: '',
      district: '',
      state: '',
      country: '',
      lat: 0,
      lon: 0,
    }
  }]);

  const title = 'Shipment Trail';

  const dispatch = useAppDispatch();

  const getShipmentTripTrails = async (selectedshipmentId: string) => {
    if (shipmentTripTrailsLoadingStatus === LoadingStatus.LOADING) {
      return;
    }
    setShipmentTripTrailsLoadingStatus(LoadingStatus.LOADING);
    let response = await getShipmentTrails(selectedshipmentId);
    setShipmentTrailList(response);
    if(response) {
      setShipmentTripTrailsLoadingStatus(LoadingStatus.IDLE);
    } else  {
      setShipmentTripTrailsLoadingStatus(LoadingStatus.FAILED);
    }
  };

  useEffect(() => {
    getShipmentTripTrails(shipmentId);
  }, [dispatch, shipmentId]);

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  if (shipmentTripTrailsLoadingStatus === LoadingStatus.LOADING)
  return <PageLoading />;

if (shipmentTripTrailsLoadingStatus === LoadingStatus.FAILED) {
  return (
    <PageLoadingFailed
      onReload={() => {
        getShipmentTripTrails(shipmentId!);
      }}
    />
  );
}

if (!shipmentTrailList) {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6">Unable to fetch details.</Typography>
      </Box>
    </>
  );
}

  return (
    <PageContainer dynamicHeight={true}>
      <DataGrid<ShipmentTrailListEntity>
        columns={getColumns(rowModesModel)}
        rows={shipmentTrailList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        editMode="row"
        getRowId={(row) => shipmentTrailList.indexOf(row)}
        pageSizeOptions={[]}
        loading={shipmentTrailListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title,
          },
        }}
        rowSelection={false}
        disableColumnFilter
        hideFooter={false}
        getRowHeight={() => 'auto'}
      />
    </PageContainer>
  );
}

export default ShipmentTrailListPage;
