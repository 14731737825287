import { AuthenticationStatus } from './type';
import * as environment from '../../environment';
import { actions, clearAction } from './reducer';
import { AppThunk } from '../store';
import { AuthData, UserData, UserRoles } from '../../data/model/auth/auth';
import { AxiosError, AxiosResponse } from 'axios';
import {
  loadSharedPrefData,
  saveAuthData,
  saveSelectedHubId,
  saveSelectedHubName,
  saveSelectedUserRole,
} from '../shared-pref/thunk-actions';
import { UserRoleData } from '../../constants/auth/userRoles';

import { actions as authActions } from './reducer';
import { LoadingStatus } from '../../data/model/interfaces';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { commerceApiClient } from '../../data/api/commerceApiClient';
import {
  authTokenUrl,
  refreshTokenUrl,
  userRolesUrl,
} from '../../data/api/apiUrls/commerceApiUrls';

export const loginFromApiThunkAction = (
  phone: string,
  password: string
): AppThunk => {
  return async (dispatch) => {
    dispatch(authActions.setloginApiLoadingStatus(LoadingStatus.LOADING));
    try {
      const userDataResponse: AxiosResponse<UserData> =
        await commerceApiClient.post(authTokenUrl, {
          phone: phone,
          password: password,
          third_party_id: environment.thirdPartyId,
        });
      if (userDataResponse.status === 200) {
        const userRolesResponse: AxiosResponse<UserRoles> =
          await commerceApiClient.get(userRolesUrl, {
            headers: {
              Authorization: `JWT ${userDataResponse.data.token}`,
            },
          });
        if (
          userRolesResponse.data.LOGISTICS_ADMIN ||
          userRolesResponse.data.HUB_MANAGER
        ) {
          dispatch(
            loginThunkAction(
              {
                profile: userRolesResponse.data,
                user: userDataResponse.data,
              },
              userRolesResponse.data.LOGISTICS_ADMIN
                ? UserRoleData.LOGISTICS_ADMIN
                : UserRoleData.HUB_MANAGER
            )
          );
        } else {
          dispatch(logoutThunkAction());
          SnakbarUtils.showErrorMessage(
            'Permission error. You do not have the required permissions to log in. Please contact the administrator for assistance.'
          );
        }
      }
      dispatch(authActions.setloginApiLoadingStatus(LoadingStatus.IDLE));
    } catch (error) {
      let errorData: any = (error as AxiosError).response?.data;
      console.log(error);

      errorData['method'] === 'password'
        ? SnakbarUtils.showErrorMessage('Incorrect Password')
        : SnakbarUtils.showAxiosErrorMessage(error);
      dispatch(authActions.setloginApiLoadingStatus(LoadingStatus.IDLE));
    }
  };
};

export const loginFromCpConsoleThunkAction = (authToken: string): AppThunk => {
  return async (dispatch) => {
    dispatch(actions.setAtuthenticationStatus(AuthenticationStatus.LOADING));
    const userRolesResponse: AxiosResponse<UserRoles> =
      await commerceApiClient.get(userRolesUrl, {
        headers: {
          Authorization: `JWT ${authToken}`,
        },
      });
    if (userRolesResponse.status === 200) {
      const refreshTokenResponse: AxiosResponse<AuthData> =
        await commerceApiClient.post(refreshTokenUrl, {
          token: userRolesResponse.data.LOGISTICS_ADMIN
            ? userRolesResponse.data.LOGISTICS_ADMIN?.token
            : userRolesResponse.data.HUB_MANAGER?.token,
          user_id: userRolesResponse.data.LOGISTICS_ADMIN
            ? userRolesResponse.data.LOGISTICS_ADMIN?.data?.user_profile
                ?.user_id
            : userRolesResponse.data.HUB_MANAGER?.data?.user_profile?.user_id,
        });
      if (refreshTokenResponse.status === 200) {
        dispatch(
          loginThunkAction(
            refreshTokenResponse.data,
            userRolesResponse.data.LOGISTICS_ADMIN
              ? UserRoleData.LOGISTICS_ADMIN
              : UserRoleData.HUB_MANAGER
          )
        );
      } else {
        dispatch(logoutThunkAction());
      }
    } else {
      dispatch(logoutThunkAction());
    }
  };
};

export const loginFromSharedPref = (): AppThunk => {
  return async (dispatch, getState) => {
    if (getState().sharedPref.authData) {
      dispatch(
        actions.setAtuthenticationStatus(AuthenticationStatus.AUTHENTICATED)
      );
    } else {
      dispatch(logoutThunkAction());
    }
  };
};

export const loginThunkAction = (
  authData: AuthData,
  userRole: UserRoleData,
  selectedHubId?: string | null,
  selectedHubName?: string | null
): AppThunk => {
  return async (dispatch) => {
    dispatch(saveAuthData(authData));
    dispatch(saveSelectedUserRole(userRole));
    if (selectedHubId) {
      dispatch(saveSelectedHubId(selectedHubId!));
      dispatch(saveSelectedHubName(selectedHubName!));
    }

    dispatch(
      actions.setAtuthenticationStatus(AuthenticationStatus.AUTHENTICATED)
    );
  };
};

export const logoutThunkAction = (): AppThunk => {
  return async (dispatch) => {
    dispatch(clearAction());
    localStorage.clear();
    dispatch(loadSharedPrefData());
    dispatch(
      actions.setAtuthenticationStatus(AuthenticationStatus.UNAUTHENTICATED)
    );
  };
};

export const switchHubThunkAction = (
  selectedHubId: string,
  selectedHubName: string
): AppThunk => {
  return async (dispatch, getState) => {
    const authData = getState().sharedPref.authData;
    const userRoleData = getState().sharedPref.selectedUserRole;
    dispatch(logoutThunkAction());
    dispatch(
      loginThunkAction(authData!, userRoleData, selectedHubId, selectedHubName)
    );
  };
};
