import { Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import { CreateTripFormValues } from '../../../shipments/ShipmentList/CreateTripButton/CreateTripForm/trip-form-schema';
import TripForm from '../../../shipments/ShipmentList/CreateTripButton/CreateTripForm';
import {
  CreateTripPayload,
  TripDetailEntity,
  TripListEntity,
} from '../../../../../data/model/trip/trip';
import { updateTripDetailsAction } from '../../../../../redux/trip-detail/thunk-actions';
import { LogisticsPartner } from '../../../../../constants/trips/trip-list';
import { SnakbarUtils } from '../../../../../utils/snakbar-util';

const EditTripButton = ({
  tripId,
  tripDetails,
}: {
  tripId: string;
  tripDetails: TripListEntity;
}) => {
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);
  const dispatch = useAppDispatch();
  const handleSubmit = async (formValues: CreateTripFormValues & any) => {
    let requestPayload: CreateTripPayload = {
      hub_id: hubId ?? '',
      client: formValues.logistics_partner,
      scheduled_start_time: undefined,
      scheduled_end_time: undefined,
      trip_cost: {
        client_cost: formValues.trip_cost.client_cost * 100,
        fuel_cost: formValues.trip_cost.fuel_cost * 100,
        vehicle_rent: formValues.trip_cost.vehicle_rent * 100,
      },
    };
    if (formValues.logistics_partner === LogisticsPartner.TPL) {
      requestPayload.client_tpl = {
        delivery_partner_name:
          formValues.client_tpl_details.delivery_partner_name,
        tracking_number: formValues.client_tpl_details.tracking_number,
      };
    } else if (formValues.logistics_partner === LogisticsPartner.LOCAL) {
      requestPayload.client_local = {
        driver_name: formValues.client_local_details.driver_name,
        driver_phone: formValues.client_local_details.driver_phone,
      };
    } else {
      requestPayload.delivery_agent_id =
        formValues.delivery_agent_info_object?.value.user_id;
        requestPayload.delivery_agent_info = {
          name: formValues.delivery_agent_info_object?.value.user_info?.name,
          phone: formValues.delivery_agent_info_object?.value.user_info?.phone,
        };
    }
    
    let response: TripDetailEntity | undefined | boolean = await dispatch(
      updateTripDetailsAction({
        tripId: tripId as string,
        requestPayload: requestPayload,
      })
    );
    if (response) {
      SnakbarUtils.showSuccessMessage('Trip Updated Successfully');
      closeDialog();
    }
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        endIcon={<ArrowForwardOutlinedIcon />}
      >
        Edit Trip
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body" 
      >
        <DialogContent
          sx={{
            width: '500px',
          }}
        >
          <TripForm
            closeDialog={closeDialog}
            handleSubmit={handleSubmit}
            tripDetails={tripDetails}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditTripButton;

// {open && (
//   <DialogContent
//     sx={{
//       width: '500px',
//     }}
//   >
//     <TripForm
//       closeDialog={closeDialog}
//       handleSubmit={handleSubmit}
//       tripDetails={tripDetails}
//     />
//   </DialogContent>
// )}