import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import {
  fetchShipmentDetailAction,
  getShipmentPaymentDetailsAction,
} from '../../../../redux/shipment-detail/thunk-actions';
import {
  CustomDetailItem,
  DetailItem,
} from '../../../../reusable-components/DetailItem';
import { PageLoading } from '../../../../reusable-components/PageLoading';
import { PageLoadingFailed } from '../../../../reusable-components/PageLoadingFailed';
import { DisplayUtils } from '../../../../utils/display-util';
import { ShipmentItemsTable } from './ShipmenttemsTable';
import ShipmentStatusTag from './ShipmentStatusTag';
import { ShipmentPaymentDetails } from '../../../../data/model/trip/shipment';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import ShipmentPaymentStatusTag from './PaymentStatusTag';
import { DateUtils } from '../../../../utils/date-util';
import { PaymentStatus } from '../../../../constants/trips/payment';
import { shipmentDetailSelector } from '../../../../redux/shipment-detail/selectors';
import { ShipmentStatus } from '../../../../constants/trips/trip-list';
import ShipmentTrailListPage from './ShipmentTrailPage';

function ShipmentDetailPage() {
  const { shipmentId } = useParams();

  const shipmentDetail = useAppSelector((state) =>
    shipmentDetailSelector(state, shipmentId!)
  );

  const [shipmentDetailLoadingStatus, setShipmentDetailLoadingStatus] =
    useState(LoadingStatus.IDLE);
  const [paymentDetails, setPaymentDetails] = useState<ShipmentPaymentDetails | null>(null);
  const [showTrails, setShowTrails] = useState(false);

  const dispatch = useAppDispatch();

  const getShipmentDetail = async (shipmentId: string, forceReload = false) => {
    if (shipmentDetailLoadingStatus === LoadingStatus.LOADING) {
      return;
    }

    setShipmentDetailLoadingStatus(LoadingStatus.LOADING);

    try {
      const [shipmentDetailResponse, paymentResponse] = await Promise.all([
        dispatch(fetchShipmentDetailAction(shipmentId, forceReload)),
        dispatch(getShipmentPaymentDetailsAction(shipmentId)),
      ]);

      if (shipmentDetailResponse) {
        setShipmentDetailLoadingStatus(LoadingStatus.IDLE);
      } else {
        setShipmentDetailLoadingStatus(LoadingStatus.FAILED);
      }

      setPaymentDetails(paymentResponse!);
    } catch (error) {
      console.error('Error:', error);
      setShipmentDetailLoadingStatus(LoadingStatus.FAILED);
    }
  };

  useEffect(() => {
    getShipmentDetail(shipmentId!);
  }, [dispatch, shipmentId]);

  if (shipmentDetailLoadingStatus === LoadingStatus.LOADING)
    return <PageLoading />;

  if (shipmentDetailLoadingStatus === LoadingStatus.FAILED) {
    return (
      <PageLoadingFailed
        onReload={() => {
          getShipmentDetail(shipmentId!, true);
        }}
      />
    );
  }

  if (!shipmentDetail) {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6">Unable to fetch details.</Typography>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <Typography variant="h6" component="div">
        Shipment Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <DetailItem
              label="Shipment ID: "
              value={shipmentDetail.short_tracking_id.toString()}
            />
            <CustomDetailItem
              labelComponent={
                <Typography variant="body2" component="div">
                  Shipment Status:
                </Typography>
              }
              valueComponent={
                <ShipmentStatusTag shipmentStatus={shipmentDetail.status} />
              }
            />
            <DetailItem
              label="Net Weight: "
              value={DisplayUtils.weightFormatter(
                shipmentDetail.item_details.weight ?? 0
              )}
            />
            <DetailItem
              label="Shipment Created Time: "
              value={
                shipmentDetail.created
                  ? DateUtils.getFormattedDateString(shipmentDetail.created)
                  : ''
              }
            />
            <DetailItem
              label="Product Quantity: "
              value={shipmentDetail.item_details.quantity.toString()}
            />
            <DetailItem
              label="Est. Date Of Delivery: "
              value={
                shipmentDetail.scheduled_drop_time
                  ? DateUtils.getFormattedDateString(
                      shipmentDetail.scheduled_drop_time
                    )
                  : ''
              }
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Payment Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <CustomDetailItem
              labelComponent={
                <Typography variant="body2" component="div">
                  Payment Status:
                </Typography>
              }
              valueComponent={
                <ShipmentPaymentStatusTag
                  paymentStatus={paymentDetails?.status ?? ''}
                />
              }
            />
            <DetailItem
              label="Payment Mode: "
              value={paymentDetails?.payment_method ?? ''}
            />
            <DetailItem
              label={
                paymentDetails?.status === PaymentStatus.COLLECTED
                  ? 'Amount Collected: '
                  : 'Amount To Be Collected: '
              }
              value={convertPaisaToRupee(paymentDetails?.amount)}
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Location Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <DetailItem
              label="Pickup Detail: "
              value={DisplayUtils.getFullAddress(
                shipmentDetail.status === ShipmentStatus.CREATED ||
                  shipmentDetail.status === ShipmentStatus.READY_FOR_PICKUP ||
                  shipmentDetail.status === ShipmentStatus.DELIVERED
                  ? shipmentDetail.pick_up_address
                  : shipmentDetail.current_hub?.address
              )}
            />
            <DetailItem
              label="Drop Detail: "
              value={DisplayUtils.getFullAddress(
                shipmentDetail.next_hub?.address ?? shipmentDetail.drop_address
              )}
            />
            <DetailItem
              label="Origin Detail: "
              value={DisplayUtils.getFullAddress(
                shipmentDetail.pick_up_address
              )}
            />
            <DetailItem
              label="Destination Detail: "
              value={DisplayUtils.getFullAddress(shipmentDetail.drop_address)}
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Product Details
      </Typography>
      <br></br>
      <Card>
        <ShipmentItemsTable shipmentItems={[shipmentDetail.item_details]} />
      </Card>
      <br></br>
      {!showTrails ? (
        <Button
          id="basic-button"
          variant="text"
          size="medium"
          disabled={showTrails}
          onClick={() => {
            setShowTrails(true);
          }}
        >
          <Typography variant="inherit">See Shipments Trip Trails</Typography>
        </Button>
      ) : undefined}

      {showTrails ? (
        <Box>
          <br></br>
          <ShipmentTrailListPage shipmentId={shipmentId!} />
        </Box>
      ) : undefined}
    </Box>
  );
}

export default ShipmentDetailPage;
