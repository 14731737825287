import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { Chip } from '@mui/material';
import { useState } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { dialogTransition } from '../../../../../utils/transitions';
import { useNavigate } from 'react-router-dom';

export const SearchShipmentField = ({
  label,
  placeholder,
}: {
  label: string;
  placeholder: string;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState<string | null>(null);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSubmitFilter = () => {
    closeDialog();
    navigate(`/shipments/${searchText}`);
  };

  return (
    <>
      <Chip
        variant="outlined"
        onClick={openDialog}
        icon={<SearchIcon />}

        label={placeholder}
        sx={{ mr: 1, mb: 1 }}
      />
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <br />
          <TextField
            label={label}
            sx={{ width: 300 }}
            value={searchText}
            placeholder={placeholder}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchText(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitFilter} variant="contained">
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
