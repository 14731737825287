import { MenuItem } from './type';

export const adminsMenuItems: {
  [key: string]: MenuItem;
} = {
  unAssignedShipmentsList: {
    id: 'unAssignedShipments',
    title: 'Unassigned Shipment List',
    type: 'item',
    url: '/unassigned-shipments',
    target: true,
  },
};

const admin: MenuItem = {
  id: 'adminAccess',
  title: 'Admin Access',
  type: 'group',
  children: [
    adminsMenuItems.unAssignedShipmentsList,
  ],
};

export default admin;
