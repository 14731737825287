import { actions } from './reducer';
import { AuthData } from '../../data/model/auth/auth';
import { AppThunk } from '../store';
import {
  UserRoleData,
  getUserRoleFromString,
} from '../../constants/auth/userRoles';
import { SharedPrefKeys } from '../../constants/sharedPref/sharedPrefKeys';

export const loadSharedPrefData = (): AppThunk => {
  return async (dispatch) => {
    // Load authData starts
    const authDataString = localStorage.getItem(SharedPrefKeys.authData);
    if (authDataString) {
      try {
        const authData = JSON.parse(authDataString);
        dispatch(actions.setAuthData(authData));
      } catch (error) {
        localStorage.clear();
        dispatch(actions.setAuthData(null));
        console.error(error);
      }
    } else {
      dispatch(actions.setAuthData(null));
    }
    // Load authData ends
    // Load selected Cluster Id starts
    const selectedHubIdString = localStorage.getItem(
      SharedPrefKeys.selectedHubId
    );
    if (selectedHubIdString) {
      dispatch(actions.setSelectedHubId(selectedHubIdString));
    } else {
      dispatch(actions.setSelectedHubId(null));
    }
    // Load selected Cluster Id ends
    // Load selected HubName starts
    const selectedHubName = localStorage.getItem(SharedPrefKeys.selectedHubName);
    if (selectedHubName) {
      dispatch(actions.setSelectedHubName(selectedHubName));
    } else {
      dispatch(actions.setSelectedHubName(null));
    }
    // Load selected HubName ends
    // Load selected User Role starts
    const selectedUserRoleString = localStorage.getItem(
      SharedPrefKeys.selectedUserRole
    );
    dispatch(
      actions.setSelectedUserRole(getUserRoleFromString(selectedUserRoleString))
    );
    // Load selected User Role ends
    dispatch(actions.setLoaded());
  };
};

export const saveAuthData = (authData: AuthData): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.authData, JSON.stringify(authData));
    dispatch(actions.setAuthData(authData));
  };
};

export const saveSelectedUserRole = (userRole: UserRoleData): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.selectedUserRole, userRole.toString());
    dispatch(actions.setSelectedUserRole(userRole));
  };
};

export const saveSelectedClusterId = (clusterId: string): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.selectedHubId, clusterId);
    dispatch(actions.setSelectedHubId(clusterId));
  };
};

export const saveSelectedBusinessId = (businessId: string): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.selectedBusinessId, businessId);
    dispatch(actions.setSelectedBusinessId(businessId));
  };
};

export const saveSelectedHubId = (hubId: string): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.selectedHubId, hubId);
    dispatch(actions.setSelectedHubId(hubId));
  };
};
export const saveSelectedHubName = (hubName: string): AppThunk => {
  return async (dispatch) => {
    localStorage.setItem(SharedPrefKeys.selectedHubName, hubName);
    dispatch(actions.setSelectedHubName(hubName));
  };
};
