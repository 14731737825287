import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';

const UserRoutes: RouteObject = {
  path: 'users/',
  element: <MainLayout />,
  children: [
  ],
};

export default UserRoutes;
