import { MenuItem } from './type';

const pages: MenuItem = {
  id: 'employeeManagement',
  title: 'Employee Management',
  type: 'group',
  children: [
    {
      id: 'reporteeList',
      title: 'Reportee List',
      type: 'item',
      url: '/employee-management/reportees',
      target: true,
    },
    {
      id: 'linkReportees',
      title: 'Link Reportees',
      type: 'item',
      url: '/employee-management/link',
      target: true,
    },
  ],
};

export default pages;
