import { Chip } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { IFilterValue } from '../../../data/model/interfaces';

export const SelectFilter = ({
  label,
  oldValue,
  newValue,
  setValue,
}: {
  label: string;
  oldValue: IFilterValue[];
  newValue: IFilterValue;
  setValue: (value: IFilterValue[] | null) => void;
}) => {
  const openDialog = () => {
    let data = oldValue;
    if (data !== undefined) {
      data = [...oldValue, newValue];
      setValue(data);
    } else {
      setValue([newValue]);
    }
  };

  let filterPreSelected = oldValue?.some(
    (item) => item.value === newValue.value
  );

  return (
    <>
      <Chip
        variant="outlined"
        onClick={!filterPreSelected ? openDialog : undefined}
        color={filterPreSelected ? 'primary' : undefined}
        deleteIcon={filterPreSelected ? <ClearOutlinedIcon /> : undefined}
        onDelete={
          filterPreSelected
            ? () => {
                setValue(
                  oldValue?.filter((item) => item.value !== newValue.value)
                );
              }
            : undefined
        }
        label={label}
        sx={{
          mb: 0.5,
          ml: 1,
          fontSize: '15px',
        }}
      />
    </>
  );
};
