import { Card, CardContent, Typography } from '@mui/material';
import { convertPaisaToRupee } from '../../../../../utils/currenty-util';
import { CustomButton } from '../../../../../reusable-components/CustomButton';
import { useNavigate } from 'react-router-dom';

function TripDetailCard({
  tripId,
  tripAmount,
}: {
  tripId: number;
  tripAmount: number;
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/trips/${tripId}`);
  };
  return (
    <Card>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton
            variant="text"
            handleClick={handleClick}
            buttonText={`Trip Id: ${tripId}`}
            buttonSize='large'
          />
          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', paddingTop: '8px' }}
          >{`${convertPaisaToRupee(tripAmount)}`}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}
export default TripDetailCard;
