import { AxiosResponse } from 'axios';
import { logisticsApiClient } from './logisticsApiClient';
import { ListResponse } from '../model/interfaces';
import {
  AddToExistingTripPayload,
  TripDetailEntity,
  TripShipmentListEntity,
  UpdateTripShipmentDetailPayload,
} from '../model/trip/trip';
import { TripShipmentDetailEntity } from '../model/trip/shipment';
import {
  tripShipmentRelationUrl,
  updateTripShipmentDetailUrl,
} from './apiUrls/logisticsApiUrls';

export const getTripShipmentList = async ({
  pageNumber = 1,
  tripId,
}: {
  pageNumber?: number;
  tripId: string;
}) => {
  const axiosResponse: AxiosResponse<ListResponse<TripShipmentListEntity>> =
    await logisticsApiClient.get(tripShipmentRelationUrl(tripId), {
      params: {
        page: pageNumber,
      },
    });
  return axiosResponse.data;
};

export const getShipmentListForTrip = async (tripId: string) => {
  const axiosResponse: AxiosResponse<TripDetailEntity> =
    await logisticsApiClient.get(tripShipmentRelationUrl(tripId));
  return axiosResponse.data;
};

export const removeShipmentFromTrip = async ({
  tripId,
  shipmentIds,
}: {
  tripId: string;
  shipmentIds: string[];
}) => {
  const axiosResponse: AxiosResponse<any> = await logisticsApiClient.delete(
    tripShipmentRelationUrl(tripId),
    {
      data: { shipment_ids: shipmentIds },
    }
  );
  return axiosResponse.data;
};

export const addTripShipmentRelation = async (
  requestBody: AddToExistingTripPayload,
  tripId: string
) => {
  const createTripShipmentRelationResponse: AxiosResponse<
    ListResponse<TripShipmentListEntity>
  > = await logisticsApiClient.post(
    tripShipmentRelationUrl(tripId),
    requestBody
  );
  return createTripShipmentRelationResponse.data;
};

export const updateTripShipmentDetail = async ({
  tripId,
  shipmentId,
  requestBody,
}: {
  tripId: string;
  shipmentId: string;
  requestBody: UpdateTripShipmentDetailPayload;
}) => {
  const axiosResponse: AxiosResponse<TripShipmentListEntity> =
    await logisticsApiClient.patch(
      updateTripShipmentDetailUrl(tripId, shipmentId),
      requestBody
    );
  return axiosResponse.data;
};

export const getTripShipmentDetail = async ({
  tripId,
  shipmentId,
}: {
  tripId: string;
  shipmentId: string;
}) => {
  const axiosResponse: AxiosResponse<TripShipmentDetailEntity> =
    await logisticsApiClient.get(
      updateTripShipmentDetailUrl(tripId, shipmentId)
    );
  return axiosResponse.data;
};
