import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import {
  AddToExistingTripPayload,
  TripShipmentListEntity,
  UpdateTripShipmentDetailPayload,
} from '../../data/model/trip/trip';
import { ListResponse, LoadingStatus } from '../../data/model/interfaces';
import { tripShipmentListSelectors } from './selectors';
import {
  addTripShipmentRelation,
  getTripShipmentList,
  removeShipmentFromTrip,
  updateTripShipmentDetail,
} from '../../data/api/tripShipmentRelation';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { updateShipmentPaymentStatus } from '../../data/api/payment';
import { PaymentStatus } from '../../constants/trips/payment';
import { ShipmentPaymentDetails } from '../../data/model/trip/shipment';

const fetchList = ({
  forceReload = false,
  tripId,
}: {
  forceReload?: boolean;
  tripId: string;
}): AppThunk => {
  return async (dispatch, getState) => {
    if (
      tripShipmentListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.TRIP_SHIPMENT_LIST));
    }
    if (
      tripShipmentListSelectors.selectListItems(getState()).length <
        tripShipmentListSelectors.selectPageSize(getState()) *
          tripShipmentListSelectors.selectPageNumber(getState()) ||
      tripShipmentListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.TRIP_SHIPMENT_LIST,
          })
        );
        const tripShipmentListApiResponse = await getTripShipmentList({
          pageNumber: tripShipmentListSelectors.selectPageNumber(getState()),
          tripId: tripId,
        });
        dispatch(
          listActions.setListResponse({
            listItems: tripShipmentListApiResponse.results.map(
              (item) => item.shipment.short_tracking_id
            ),
            count: tripShipmentListApiResponse.count,
            key: listSateKeys.TRIP_SHIPMENT_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: tripShipmentListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.shipment.short_tracking_id.toString(),
            })),
            key: entityStateKeys.TRIP_SHIPMENT_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.TRIP_SHIPMENT_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.TRIP_SHIPMENT_LIST,
          })
        );
      }
    }
  };
};

export const removeShipmentFromTripAction = ({
  tripId,
  shipmentIds,
}: {
  tripId: string;
  shipmentIds: string[];
}): AppThunk<Promise<boolean | undefined>> => {
  return async (dispatch, getState) => {
    try {
      const updateTripApiResponse = await removeShipmentFromTrip({
        tripId: tripId,
        shipmentIds: shipmentIds,
      });

      shipmentIds.map((shipmentId) => {
        dispatch(
          listActions.removeDeletedItem({
            removedItemId: shipmentId,
            key: listSateKeys.TRIP_SHIPMENT_LIST,
          })
        );
      });

      return true;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

export const addTripShipmentRelationAction = (
  requestBody: AddToExistingTripPayload,
  tripId: string
): AppThunk<Promise<ListResponse<TripShipmentListEntity> | undefined>> => {
  return async (dispatch, getState) => {
    try {
      const updateTripApiResponse = await addTripShipmentRelation(
        requestBody,
        tripId
      );

      SnakbarUtils.showSuccessMessage('Shipments Added To Trip Successfully');
      return updateTripApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};
export const updateTripShipmentDetailAction = ({
  tripId,
  shipmentId,
  requestBody,
}: {
  tripId: string;
  shipmentId: string;
  requestBody: UpdateTripShipmentDetailPayload;
}): AppThunk<Promise<TripShipmentListEntity | undefined>> => {
  return async (dispatch, getState) => {
    try {
      const updateShipmentApiResponse = await updateTripShipmentDetail({
        tripId: tripId,
        shipmentId: shipmentId,
        requestBody: requestBody,
      });

      setEntities(updateShipmentApiResponse, dispatch);
      SnakbarUtils.showSuccessMessage(
        `Trip Shipment status marked ${updateShipmentApiResponse.status} Successfully`
      );
      return updateShipmentApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

export const markPaymentCompleteAndUpdateStatusAction = ({
  tripId,
  shipmentId,
  requestBody,
}: {
  tripId: string;
  shipmentId: string;
  requestBody: UpdateTripShipmentDetailPayload;
}): AppThunk<Promise<ShipmentPaymentDetails | undefined>> => {
  return async (dispatch, _getState) => {
    try {
      const updateShipmentPaymentStatusApiResponse =
        await updateShipmentPaymentStatus(shipmentId, PaymentStatus.COLLECTED);
      if (updateShipmentPaymentStatusApiResponse) {
        await dispatch(
          updateTripShipmentDetailAction({
            tripId: tripId,
            shipmentId: shipmentId,
            requestBody: requestBody,
          })
        );
      }
      return updateShipmentPaymentStatusApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

function setEntities(detailEntity: TripShipmentListEntity, dispatch: any) {
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.shipment.short_tracking_id.toString(),
      key: entityStateKeys.TRIP_SHIPMENT_LIST,
    })
  );
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.shipment.short_tracking_id.toString(),
      key: entityStateKeys.SHIPMENT_LIST,
    })
  );
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.shipment.short_tracking_id.toString(),
      key: entityStateKeys.TRIP_SHIPMENT_DETAIL,
    })
  );
}

export const tripShipmentListThunkActions = {
  fetchList,
  removeShipmentFromTripAction,
  addTripShipmentRelationAction,
  updateTripShipmentDetailAction,
  markPaymentCompleteAndUpdateStatusAction,
};
