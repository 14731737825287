import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';

const schema = {
  fields: [
    {
      label: 'Est. Delivery Date',
      name: 'scheduled_drop_time',
      component: customComponentTypes.DATE_PICKER,
      isRequired: true,
      validate:[
        {
          type: validatorTypes.REQUIRED,
        },
      ]
    },
  ],
} as const;

export type UpdateDeliveryDateFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
