import { entityActions } from '../entities/reducer';
import { AppThunk } from '../store';
import { entityStateKeys } from '../entities/constants';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { CreateShipmentPayLoad, ShipmentDetailEntity, ShipmentListEntity, ShipmentPaymentDetails, ShipmentTrailListEntity } from '../../data/model/trip/shipment';
import { createShipment, getShipmentDetailById, getShipmentTrails } from '../../data/api/shipment';
import { getShipmentPaymentDetails } from '../../data/api/payment';

export const fetchShipmentDetailAction =
  (
    shipmentId: string,
    forceReload = false
  ): AppThunk<Promise<ShipmentDetailEntity | undefined>> =>
  async (dispatch, getState) => {
    const selectedShipmentDetail = getState().entities.shipmentDetail[shipmentId];
    if (!selectedShipmentDetail || forceReload) {
      try {
        const shipmentDetailResponse = await getShipmentDetailById(shipmentId);
        setEntities(shipmentDetailResponse, dispatch);
        return shipmentDetailResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    }
    return selectedShipmentDetail;
  };

  export const createShipmentAction = (
    requestBody: CreateShipmentPayLoad
  ): AppThunk<Promise<ShipmentListEntity | undefined>> => {
    return async (dispatch, getState) => {
      try {
        const createShipmentApiResponse = await createShipment(requestBody);
        SnakbarUtils.showSuccessMessage("Shipment Created Successfully");
        return createShipmentApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    };
  };

  export const getShipmentPaymentDetailsAction = (
    shipmentId: string
  ): AppThunk<Promise<ShipmentPaymentDetails | undefined>> => {
    return async (dispatch, getState) => {
      try {
        const shipmentPaymentDetailsApiResponse = await getShipmentPaymentDetails(shipmentId);
        return shipmentPaymentDetailsApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    };
  };

  export const getShipmentTrailsAction = (
    shipmentId: string
  ): AppThunk<Promise<ShipmentTrailListEntity[] | undefined>> => {
    return async (dispatch, getState) => {
      try {
        const shipmentTrailsApiResponse = await getShipmentTrails(shipmentId);
        return shipmentTrailsApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    };
  };

function setEntities(detailEntity: ShipmentDetailEntity, dispatch: any) {
  const acivityListEntity: ShipmentListEntity = {
    ...detailEntity
  };
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.short_tracking_id.toString(),
      key: entityStateKeys.SHIPMENT_DETAIL,
    })
  );
  dispatch(
    entityActions.setEntity({
      entity: acivityListEntity,
      entityId: acivityListEntity.short_tracking_id.toString(),
      key: entityStateKeys.SHIPMENT_LIST,
    })
  );
}
