import { Dispatch, SetStateAction } from 'react';
import SimpleTable from '../../../../reusable-components/table/SimpleTable';
import { getTableRows, tableHead } from './table-data';

export const PincodeListTable = ({
  linkedPincode,
  setLinkedPincodeList,
}: {
  linkedPincode: string[];
  setLinkedPincodeList: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <SimpleTable
      tableHead={tableHead}
      tableRows={getTableRows(linkedPincode)}
      onCellClicked={(value, index) => {
        const updatedList = linkedPincode.filter((pincode) => pincode !== value);
        if(index === 1){
          setLinkedPincodeList(updatedList);
        }
      }}
    />
  );
};
