import { createAction, createSlice } from '@reduxjs/toolkit';
import { setAtuthenticationStatus, setloginApiLoadingStatus } from './actions';
import { AuthenticationStatus, AuthState } from './type';
import { LoadingStatus } from '../../data/model/interfaces';

const initialState: AuthState = {
  authenticationStatus: AuthenticationStatus.LOADING,
  loginApiLoadingStatus: LoadingStatus.IDLE,
};

export const clearAction = createAction('clear');

const slice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setAtuthenticationStatus,
    setloginApiLoadingStatus,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, (state) => {
      return initialState;
    });
  },
});

export const actions = slice.actions;

export default slice.reducer;
