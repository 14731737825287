import { Box, Container, Toolbar } from '@mui/material';
import schema, {
  CreateShipmentFormValues,
} from './create-shipment-form-schema';
import { useNavigate } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useAppDispatch } from '../../../../../../redux/hooks';
import {
  FormRenderer,
  CustomFormTemplate,
  customComponentMapper,
} from '../../../../../../reusable-components/data-driven-forms';
import {
  CreateShipmentPayLoad,
  ShipmentListEntity,
} from '../../../../../../data/model/trip/shipment';
import { createShipmentAction } from '../../../../../../redux/shipment-detail/thunk-actions';
import dayjs from 'dayjs';
import { useState } from 'react';
import AddressTextField from '../AddressTextFieldComponent';
import { AutocompleteOptionType } from '../AddressTextFieldComponent/type';
import { SnakbarUtils } from '../../../../../../utils/snakbar-util';
import { tripsMenuItems } from '../../../../../../menu-items/trips';

const CreateShipmentForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let data: any = {
    submit_button_text: 'Create a Shipment',
    submit_button_prefix_icon: <ArrowForwardOutlinedIcon />,
    tooltip_text:
      'Example of fragile goods: glass items, marble, tiles, porcelains, optical instruments, tech, accessories etc.',
  };

  const [pickupAddressData, setPickupAddressData] =
    useState<AutocompleteOptionType>();
  const [dropAddressData, setDropAddressData] =
    useState<AutocompleteOptionType>();

  const handleSubmit = async (formValues: CreateShipmentFormValues & any) => {
    if (pickupAddressData === undefined || dropAddressData === undefined) {
      return SnakbarUtils.showErrorMessage(
        'Pickup and Drop Address are Mandatory'
      );
    }
    let requestBody: CreateShipmentPayLoad = {
      shipment_payment_collection_status:
        formValues.shipment_payment_collection_status,
      shipment_payment_collection_amount:
        formValues.shipment_payment_collection_amount * 100,
      pick_up_address: {
        poc_name: pickupAddressData.value.address.poc_name,
        poc_phone: pickupAddressData.value.address.poc_phone,
        address_name: pickupAddressData.value.address.address_name,
        full_address: pickupAddressData.value.address.full_address,
        pincode: pickupAddressData.value.address.pincode,
        state: pickupAddressData.value.address.state,
        district: pickupAddressData.value.address.district,
        country: pickupAddressData.value.address.country,
        lat: pickupAddressData.value.address.lat,
        lon: pickupAddressData.value.address.lon,
      },
      drop_address: {
        poc_name: dropAddressData.value.address.poc_name,
        poc_phone: dropAddressData.value.address.poc_phone,
        address_name: dropAddressData.value.address.address_name,
        full_address: dropAddressData.value.address.full_address,
        pincode: dropAddressData.value.address.pincode,
        state: dropAddressData.value.address.state,
        district: dropAddressData.value.address.district,
        country: dropAddressData.value.address.country,
        lat: dropAddressData.value.address.lat,
        lon: dropAddressData.value.address.lon,
      },
      item_details: {
        item_name: formValues.item_details.item_name,
        weight: formValues.item_details?.weight ?? 0,
        length: formValues.item_details.length,
        width: formValues.item_details.width,
        height: formValues.item_details.height,
        cost: (formValues.item_details.cost ?? 0) * 100,
        quantity: formValues.item_details.quantity,
        handle_with_care: formValues.item_details.handle_with_care,
      },
      scheduled_drop_time: dayjs(formValues.scheduled_drop_time).format(),
      notes: formValues.notes,
    };

    let response: ShipmentListEntity | undefined = await dispatch(
      createShipmentAction(requestBody)
    );
    if (response) {
      navigate(tripsMenuItems.shipmentsList.url ?? '', {
        state: {
          forceReload: true,
        }
      });
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: 1,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Toolbar
          sx={{
            pl: { xs: 0 },
            pr: { xs: 0 },
            pb: 1,
          }}
        ></Toolbar>
        <AddressTextField
          headingText="Pickup Details"
          onSubmit={(address) => {
            setPickupAddressData(address);
          }}
        />
        <br />
        <AddressTextField
          headingText="Drop Details"
          onSubmit={(address) => {
            setDropAddressData(address);
          }}
        />
        <br></br>
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          initialValues={data}
          onCancel={() => {
            navigate(-1);
          }}
        />
      </Container>
    </Box>
  );
};

export default CreateShipmentForm;
