import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';

const schema = {
  fields: [
    {
      label: 'Address Name',
      name: 'address.address_name',
      component: customComponentTypes.TEXT_FIELD,
    },
    {
      label: 'Full Address',
      name: 'address.full_address',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'District',
      name: 'address.district',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 6 },
    },
    {
      label: 'State',
      name: 'address.state',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 6 },
    },
    {
      label: 'Pincode',
      name: 'address.pincode',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 6 },
    },
    {
      label: 'Country',
      name: 'address.country',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      FormFieldGridProps: { xs: 6 },
    },
    {
      label: 'Location',
      name: 'address.lat_lon_object',
      component: customComponentTypes.CUSTOM_LAT_LON_FIELD,
    },
    {
      label: 'Contact Person Name',
      name: 'address.poc_name',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Contact Number',
      name: 'address.poc_phone',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'I Want To Save This',
      name: 'save_address',
      component: customComponentTypes.CHECKBOX,
      dataType: 'boolean'
    },
    {
      label: 'Chips',
      name: 'address_type',
      component: customComponentTypes.CUSTOM_CHIP,
      condition:{
        when: 'save_address',
        is: true,
      },
      options: [
        {
          label: 'Retailer',
          value: 'RETAILER',
        },
        {
          label: 'Seller',
          value: 'SELLER',
        },
        {
          label: 'BAP',
          value: 'BAP',
        },
        {
          label: 'Others',
          value: 'OTHERS',
        },
      ],
    },
  ],
} as const;

export type AddAddressFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
