import { Box, Container, Toolbar, Typography } from '@mui/material';
import {
  FormRenderer,
  CustomFormTemplate,
  customComponentMapper,
} from '../../../../../../reusable-components/data-driven-forms';
import schema from './new-deposit-form-schema';
import { CashDepositsListEntity } from '../../../../../../data/model/cash-deposit/cashDeposit';

const CashDepositForm = ({
  closeDialog,
  handleSubmit,
  depositDetail,
}: {
  closeDialog: () => void;
  handleSubmit: any;
  depositDetail?: CashDepositsListEntity;
}) => {
  let updatedDepositDetail: any = {
    submit_button_text: depositDetail ? 'Send For Verification' : 'Save as Draft',
    fetch_handover_amount: true,
    ...depositDetail,
  };

  return (
    <Box
      sx={{
        width: '100%',
        pt: 1,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Toolbar
          sx={{
            pl: { xs: 0 },
            pr: { xs: 0 },
            pb: 1,
          }}
        >
          <Typography variant="h6">
            {depositDetail ? 'Update deposit' : 'Add new deposit'}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
          initialValues={updatedDepositDetail}
          onCancel={() => {
            closeDialog();
          }}
        />
      </Container>
    </Box>
  );
};

export default CashDepositForm;
