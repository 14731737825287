import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useState, MouseEvent } from 'react';
import { logoutThunkAction } from '../../../../redux/login/thunk-actions';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import PersonRoundedIcon from '@mui/icons-material/Person2Outlined';

export const ProfileSection = () => {
  const loginProfile = useAppSelector(
    (state) => state.sharedPref.authData
  )?.profile;
  const userName =
    loginProfile?.LOGISTICS_ADMIN?.data?.profile_name ||
    loginProfile?.HUB_MANAGER?.data?.profile_name ||
    '';

  const userPhone =
    loginProfile?.LOGISTICS_ADMIN?.data?.user_profile?.phone ||
    loginProfile?.HUB_MANAGER?.data?.user_profile?.phone ||
    '';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutThunkAction());
    handleClose();
  };

  return (
    <>
      {isMobileScreen ? undefined : (
        <Typography variant="subtitle1"> Hello {userName}</Typography>
      )}
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isMobileScreen ? (
          <Box>
            <Box
              sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PersonRoundedIcon sx={{ fontSize: '24px' }} />
              <Box sx={{ width: '16px' }} />
              <div>
                <div>{userName}</div>
                <div
                  style={{
                    fontSize: '14px',
                    color: 'grey',
                    textAlign: 'right',
                  }}
                >
                  {userPhone}
                </div>
              </div>
            </Box>
            <Divider />
          </Box>
        ) : undefined}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
