import { GridColDef, GridRowModesModel } from '@mui/x-data-grid';
import { TripListEntity } from '../../../../data/model/trip/trip';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import StartAndStopTripButton from './StartAndStopTripButton';
import { DateUtils } from '../../../../utils/date-util';
import { LogisticsPartner } from '../../../../constants/trips/trip-list';
import { DisplayUtils } from '../../../../utils/display-util';
import { useParams } from 'react-router-dom';

export const getColumns: (
  rowModesModel: GridRowModesModel,
) => GridColDef<TripListEntity, any, any>[] = (
  rowModesModel,
) => [
  {
    field: 'trip_id',
    headerName: 'Trip ID',
    type: 'string',
    minWidth: 100,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    editable: true,
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created Time',
    type: 'string',
    minWidth: 175,
    align: 'left',
    headerAlign: 'left',
    hideable: false,
    valueFormatter(params) {
      return DateUtils.getFormattedDateTimeString(params.value);
    },
    valueGetter(params) {
      return params.value;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'client_TPL_details',
    headerName: 'Logistics Partner',
    type: 'string',
    minWidth: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter(params) {
      return params.row.logistics_partner === LogisticsPartner.TPL
      ? params.row.client_tpl_details?.delivery_partner_name
      : params.row.logistics_partner === LogisticsPartner.ONE_BRIDGE
      ? '1BRIDGE'
      : params.row.logistics_partner
    },
    editable: false,
    sortable: false,
    hideable: false,
  },
  {
    field: 'client_local_details',
    headerName: 'DA Name',
    type: 'string',
    minWidth: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter(params) {
      return params.row.logistics_partner === LogisticsPartner.LOCAL
        ? params.row.client_local_details?.driver_name
        : params.row.delivery_agent_info?.name ?? '--';
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'scheduled_start_time',
    headerName: '~Est. Start Time',
    type: 'number',
    minWidth: 150,
    valueFormatter(params) {
      return params.value
        ? DateUtils.getFormattedDateString(params.value)
        : '--';
    },
    valueGetter(params) {
      return params.value;
    },
    align: 'left',
    headerAlign: 'left',
    editable: true,
    sortable: false,
  },
  {
    field: 'trip_cost',
    headerName: 'Trip Cost',
    type: 'number',
    minWidth: 120,
    valueFormatter(params) {
      return convertPaisaToRupee(params.value);
    },
    valueGetter(params) {
      return (
        params.value.client_cost +
        params.value.fuel_cost +
        params.value.vehicle_rent
      );
    },
    align: 'left',
    headerAlign: 'left',
    editable: true,
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    hideable: false,
    width: 120,
    cellClassName: 'actions',
    getActions: (param) => {
      return [
        <StartAndStopTripButton
          tripStatus={param.row.status}
          tripId={param.id}
          assignedDa=''
          logisticsPartner=''
          refresh={() => {}}
        />,
      ];
    },
  },
];
