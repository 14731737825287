import { GridColDef } from '@mui/x-data-grid';
import { CashDepositsListEntity } from '../../../../data/model/cash-deposit/cashDeposit';
import { DateUtils } from '../../../../utils/date-util';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import { CashDepositImageButton } from './CashDepositImageButton';
import CashDepositStatusTag from './CashDepositStatusTag';
import { Image } from './../../../../data/model/interfaces';
import { CashDepositStatus } from '../../../../constants/cashDeposit/cashDeposit';
import UpdateDepositButton from './UpdateDepositButton';
import DepositIdButton from './DepositIdButton';

export const getColumns: (
  rowModesModel: any
) => GridColDef<CashDepositsListEntity, any, any>[] = (rowModesModel) => [
  {
    field: 'created',
    headerName: 'Date Created',
    type: 'string',
    minWidth: 175,
    align: 'left',
    headerAlign: 'left',
    valueFormatter(params) {
      return DateUtils.getFormattedDateTimeString(params.value);
    },
    valueGetter(params) {
      return params.value;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'deposit_id',
    headerName: 'Deposit ID',
    minWidth: 120,
    align: 'right',
    headerAlign: 'right',
    editable: false,
    sortable: false,
    renderCell(params) {
      return <DepositIdButton depositId={params.value} />;
    },
    valueGetter(params) {
      return params.value;
    },
  },
  {
    field: 'transaction_id',
    headerName: 'Transaction ID',
    type: 'string',
    minWidth: 175,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.value;
    },
  },
  {
    field: 'amount',
    headerName: 'Amount Deposited',
    minWidth: 175,
    align: 'right',
    headerAlign: 'right',
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.value;
    },
    valueFormatter(params) {
      return convertPaisaToRupee(params.value);
    },
  },
  {
    field: 'transaction_proof_img_url',
    headerName: 'Attachements',
    type: 'string',
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    editable: false,
    sortable: false,
    renderCell(params) {
      return (
        <CashDepositImageButton imagesUrl={params.value} buttonText={'View'} />
      );
    },
  },
  {
    field: 'notes',
    headerName: 'Remarks/Note',
    type: 'string',
    minWidth: 150,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueGetter(params) {
      return params.value;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    renderCell(params) {
      return <CashDepositStatusTag cashDepositStatus={params.value} />;
    },
  },
  {
    field: 'action',
    headerName: 'Action',
    type: 'string',
    minWidth: 125,
    align: 'center',
    headerAlign: 'center',
    editable: false,
    sortable: false,
    renderCell(params) {
      let data: CashDepositsListEntity & {
        transaction_images: Image[];
      } = {
        ...params.row,
        transaction_images:
          params.row.transaction_proof_img_url.length > 0
            ? params.row.transaction_proof_img_url.map(
                (imageUrl: string, index) => ({
                  photo_id: index.toString(),
                  photo_url: imageUrl,
                  content_type: 'image/jpeg',
                })
              )
            : [],
        amount: params.row.amount,
      };

      return params.row.status === CashDepositStatus.CREATED ||
        params.row.status === CashDepositStatus.DRAFTED ? (
        <UpdateDepositButton depositDetail={data} />
      ) : (
        <></>
      );
    },
  },
];
