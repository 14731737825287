import { UserRoleData } from '../../../../../../constants/auth/userRoles';
import { LogisticsPartner } from '../../../../../../constants/trips/trip-list';
import { getDeliveryAgentList } from '../../../../../../data/api/deliveryAgent';
import { DeliveryAgentListEntity } from '../../../../../../data/model/deliveryAgent/deliveryAgent';
import { ListResponse } from '../../../../../../data/model/interfaces';
import { store } from '../../../../../../redux/store';
import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';
import { ClientTypeOptions, TPL, TPLOptions } from './trip-form-options-type';

const schema = {
  fields: [
    {
      label: 'Logistic Partner',
      name: 'logistics_partner',
      component: customComponentTypes.SELECT,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      options: ClientTypeOptions,
      condition: {
        when: 'logistics_partner',
        isNotEmpty: false,
        then: {
          set: () => {
            return { logistics_partner: LogisticsPartner.ONE_BRIDGE };
          },
        },
        else: { visible: true },
      },
    },
    {
      label: 'Name Of 3PL',
      name: 'client_tpl_details.delivery_partner_name',
      component: customComponentTypes.SELECT,
      isRequired: true,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.TPL,
      },
      options: TPLOptions,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Others',
      name: 'client_tpl_details.others',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      condition: {
        when: 'client_tpl_details.delivery_partner_name',
        is: TPL.OTHERS,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Tracking ID',
      name: 'client_tpl_details.tracking_number',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.TPL,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    // {
    //   label: 'Type Of Vehicle/Mode Of Transport',
    //   name: 'business_name',
    //   component: customComponentTypes.SELECT,
    //   isRequired: true,
    //   condition: {
    //     or: [
    //       {
    //         when: 'client',
    //         is: ClientType.LOCAL,
    //       },
    //       {
    //         when: 'client',
    //         is: ClientType.ONE_BRIDGE,
    //       },
    //     ],
    //   },
    //   validate: [
    //     {
    //       type: validatorTypes.REQUIRED,
    //     },
    //   ],
    // },
    // {
    //   label: 'Vehicle Number',
    //   name: 'business_name',
    //   component: customComponentTypes.TEXT_FIELD,
    //   isRequired: true,
    //   condition: {
    //     or: [
    //       {
    //         when: 'client',
    //         is: ClientType.LOCAL,
    //       },
    //       {
    //         when: 'client',
    //         is: ClientType.ONE_BRIDGE,
    //       },
    //     ],
    //   },
    //   validate: [
    //     {
    //       type: validatorTypes.REQUIRED,
    //     },
    //   ],
    // },
    {
      label: 'DA Name',
      name: 'client_local_details.driver_name',
      component: customComponentTypes.TEXT_FIELD,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.LOCAL,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Select DA',
      name: 'delivery_agent_info_object',
      component: customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.ONE_BRIDGE,
      },
      loadOptions: (currentSearchValue: string) =>
        new Promise((resolve, reject) => {
          const { selectedHubId } = store.getState().sharedPref;
          getDeliveryAgentList({
            searchString: currentSearchValue,
            hubId: selectedHubId ?? '',
            role: UserRoleData.AGENT,
          })
            .then((response: ListResponse<DeliveryAgentListEntity>) => {
              resolve(
                response.results.map((item: DeliveryAgentListEntity) => ({
                  label: item.user_info.name,
                  value: item,
                }))
              );
            })
            .catch(reject);
        }),
    },
    {
      label: 'DA Number',
      name: 'client_local_details.driver_phone',
      component: customComponentTypes.TEXT_FIELD,
      isRequired: true,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.LOCAL,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Estimated Date Of Delivery',
      name: 'scheduled_end_time',
      component: customComponentTypes.DATE_PICKER,
      isRequired: true,
      condition: {
        when: 'logistics_partner',
        is: LogisticsPartner.TPL,
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Est. Trip Start Time',
      name: 'scheduled_start_time',
      component: customComponentTypes.DATE_PICKER,
      isRequired: true,
      condition: {
        or: [
          {
            when: 'logistics_partner',
            is: LogisticsPartner.ONE_BRIDGE,
          },
          {
            when: 'logistics_partner',
            is: LogisticsPartner.LOCAL,
          },
        ],
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Client Cost(In Rs)',
      name: 'trip_cost.client_cost',
      component: customComponentTypes.TEXT_FIELD,
      dataType: 'number',
    },
    // {
    //   label: 'Fuel Cost(In Rs)',
    //   name: 'trip_cost.fuel_cost',
    //   component: customComponentTypes.TEXT_FIELD,
    //   dataType: 'number',
    //   isRequired: true,
    //   validate: [
    //     {
    //       type: validatorTypes.REQUIRED,
    //     },
    //   ],
    // },
    // {
    //   label: 'Vehicle Rent(In Rs)',
    //   name: 'trip_cost.vehicle_rent',
    //   component: customComponentTypes.TEXT_FIELD,
    //   dataType: 'number',
    //   isRequired: true,
    //   validate: [
    //     {
    //       type: validatorTypes.REQUIRED,
    //     },
    //   ],
    // },
  ],
} as const;

export type CreateTripFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
