import { Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import { AddTripFormValues } from './AddToExistingTripForm/add-to-existing-trip-form-schema';
import {
  AddToExistingTripPayload,
  TripShipmentListEntity,
} from '../../../../../data/model/trip/trip';
import AddToExistingTripForm from './AddToExistingTripForm';
import { useNavigate } from 'react-router-dom';
import { ListResponse } from '../../../../../data/model/interfaces';
import { tripShipmentListThunkActions } from '../../../../../redux/trip-shipment-relation-list/thunk-actions';

const AddToExistingTripButton = ({
  selectedIds,
  refresh,
}: {
  selectedIds: number[];
  refresh: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (formValues: AddTripFormValues) => {
    let requestBody: AddToExistingTripPayload = {
      shipment_ids: selectedIds,
    };

    let response: ListResponse<TripShipmentListEntity> | undefined =
      await dispatch(
        tripShipmentListThunkActions.addTripShipmentRelationAction(
          requestBody,
          formValues.trip_id.value
        )
      );
    if (response) {
      refresh();
      closeDialog();
      navigate(`/trips/${formValues.trip_id.value}`, {
        state: {
          forceReload: true,
        },
      });
    }
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        disabled={selectedIds.length === 0}
      >
        Add To Existing
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <AddToExistingTripForm
            closeDialog={closeDialog}
            handleSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddToExistingTripButton;
