import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
  dynamicHeight?: boolean;
}

const PageContainer: FC<PageContainerProps> = ({ children, dynamicHeight }) => {
  return (
    <Box
      sx={{
        height: !dynamicHeight ? 'calc(100vh - 66px)' : undefined,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
};

export default PageContainer;
