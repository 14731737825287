import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { dialogTransition } from '../../utils/transitions';
import { useState } from 'react';

export default function ViewImageDialogButton({
  imageUrl,
}: {
  imageUrl: string;
}) {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="text" onClick={openDialog}>
        View
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          <img src={imageUrl} />
        </DialogContent>
      </Dialog>
    </>
  );
}
