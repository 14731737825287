import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import DeliveryAgentListPage from '../../modules/delivery-agent/DeliveryAgentList';

const DeliveryAgentRoutes: RouteObject = {
  element: <MainLayout />,
  children: [
    {
      path: 'deliveryAgents',
      element: <DeliveryAgentListPage />,
    },
  ],
};

export default DeliveryAgentRoutes;
