import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import ShipmentListPage from '../../modules/trip/shipments/ShipmentList';
import CreateShipmentForm from '../../modules/trip/shipments/ShipmentList/CreateShipmentButton/CreateShipmentForm';
import ShipmentDetailPage from '../../modules/trip/shipments/ShipmentDetailPage';
import TripListPage from '../../modules/trip/trips/TripList';
import TripDetailPage from '../../modules/trip/trips/TripDetailPage';
import TripShipmentDetailPage from '../../modules/trip/trips/TripShipmentDetailPage';

const TripRoutes: RouteObject = {
  element: <MainLayout />,
  children: [
    {
      path: 'trips',
      element: <TripListPage />,
    },
    {
      path: 'trips/:tripId',
      element: <TripDetailPage />,
    },
    {
      path: 'trips/:tripId/shipments/:shipmentId',
      element: <TripShipmentDetailPage />,
    },
    {
      path: 'shipments',
      element: <ShipmentListPage />,
    },
    {
      path: 'shipments/create',
      element: <CreateShipmentForm />,
    },
    {
      path: 'shipments/:shipmentId',
      element: <ShipmentDetailPage />,
    },
  ],
};

export default TripRoutes;
