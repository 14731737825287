import { Typography } from '@mui/material';
import { ShipmentStatus } from '../../../../../constants/trips/trip-list';

function ShipmentStatusTag({ shipmentStatus }: { shipmentStatus: string }) {
  return (
    <Typography
      variant="subtitle2"
      component="div"
      style={{
        color:
          shipmentStatus === ShipmentStatus.CREATED ||
          shipmentStatus === ShipmentStatus.DELIVERED
            ? 'rgba(1, 157, 89, 0.7)'
            : shipmentStatus === ShipmentStatus.CANCELLED ||
              shipmentStatus === ShipmentStatus.CUSTOMER_REJECTED
            ? 'rgba(255, 0, 0, 0.7)'
            : 'rgba(0, 104, 165, 0.7)',
        background:
          shipmentStatus === ShipmentStatus.CREATED ||
          shipmentStatus === ShipmentStatus.DELIVERED
            ? 'rgba(1, 157, 89, 0.2)'
            : shipmentStatus === ShipmentStatus.CANCELLED ||
              shipmentStatus === ShipmentStatus.CUSTOMER_REJECTED
            ? 'rgba(255, 0, 0, 0.2)'
            : 'rgba(0, 104, 165, 0.2)',
        display: 'inline-block',
        padding: '4px',
      }}
    >
      {shipmentStatus}
    </Typography>
  );
}

export default ShipmentStatusTag;
