import { LoadingStatus } from '../../data/model/interfaces';
import { AuthenticationStatus, AuthState } from './type';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export const setAtuthenticationStatus: CaseReducer<
  AuthState,
  PayloadAction<AuthenticationStatus>
> = (state, action) => {
  state.authenticationStatus = action.payload;
};

export const setloginApiLoadingStatus: CaseReducer<
  AuthState,
  PayloadAction<LoadingStatus>
> = (state, action) => {
  state.loginApiLoadingStatus = action.payload;
};
