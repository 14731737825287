import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { dialogTransition } from '../../../../../utils/transitions';
import { Chip } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../../redux/hooks';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DisplayUtils } from '../../../../../utils/display-util';
import {
  CustomFormTemplate,
  FormRenderer,
  customComponentMapper,
} from '../../../../../reusable-components/data-driven-forms';
import {
  ShipmentPaymentDetails,
  TripShipmentDetailEntity,
} from '../../../../../data/model/trip/shipment';
import schema, {
  UpdateTripShipmentStatusFormValues,
} from './trip-shipment-status-form-schema';
import {
  TripShipmentListEntity,
  UpdateTripShipmentDetailPayload,
} from '../../../../../data/model/trip/trip';
import { tripShipmentListThunkActions } from '../../../../../redux/trip-shipment-relation-list/thunk-actions';
import { useState } from 'react';
import { TripShipmentRelationStatus } from '../../../../../constants/trips/trip-list';

const ShipmentStatusChip = ({
  value,
  onClick,
  rowValue,
  setPaymentDetails,
  paymentStatus,
}: {
  value: any;
  onClick: React.MouseEventHandler;
  rowValue: TripShipmentDetailEntity;
  setPaymentDetails: any;
  paymentStatus: string;
}) => {
  const dispatch = useAppDispatch();

  const initialValues: {
    status: string;
    is_last_trip: boolean;
    payment_status: string;
  } = {
    status: rowValue.status,
    is_last_trip: rowValue.is_last_trip,
    payment_status: paymentStatus,
  };

  const handleSubmit = async (
    formValues: UpdateTripShipmentStatusFormValues
  ) => {
    let requestBody: UpdateTripShipmentDetailPayload = {
      status: formValues.status,
      cancellation_note: formValues.cancellation_note,
    };

    if (formValues.status === TripShipmentRelationStatus.DELIVERED) {
      let response: ShipmentPaymentDetails | undefined = await dispatch(
        tripShipmentListThunkActions.markPaymentCompleteAndUpdateStatusAction({
          tripId: rowValue.trip.trip_id.toString(),
          shipmentId: rowValue.shipment.short_tracking_id.toString(),
          requestBody: requestBody,
        })
      );
      if (response) {
        setPaymentDetails(response);
        closeDialog();
      }
    } else {
      let response: TripShipmentListEntity | undefined = await dispatch(
        tripShipmentListThunkActions.updateTripShipmentDetailAction({
          tripId: rowValue.trip.trip_id.toString(),
          shipmentId: rowValue.shipment.short_tracking_id.toString(),
          requestBody: requestBody,
        })
      );
      if (response) {
        closeDialog();
      }
    }
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const canUpdateStatus =
    value === TripShipmentRelationStatus.CREATED ||
    value === TripShipmentRelationStatus.IN_TRANSIT;

  const handleClick = () => {
    if (canUpdateStatus) {
      openDialog();
    }
  };

  return (
    <>
      <Chip
        onClick={handleClick}
        label={DisplayUtils.removeUnderscoreConvertInUppercase(value)}
        color="primary"
        variant="outlined"
        deleteIcon={canUpdateStatus ? <ArrowDropDownIcon /> : undefined}
        onDelete={canUpdateStatus ? handleClick : undefined}
      />
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <FormRenderer
            FormTemplate={CustomFormTemplate}
            componentMapper={customComponentMapper}
            initialValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
            onCancel={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShipmentStatusChip;
