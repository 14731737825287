import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { useEffect, useState } from 'react';
import {
  cashDepositDetailAction,
  fetchDepositHandoverDetailAction,
} from '../../../../redux/deposit-detail/thunk-actions';
import { PageLoading } from '../../../../reusable-components/PageLoading';
import { PageLoadingFailed } from '../../../../reusable-components/PageLoadingFailed';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { DetailItem } from '../../../../reusable-components/DetailItem';
import { DateUtils } from '../../../../utils/date-util';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import TripDetailCard from './TripDetailCard';
import { depositDetailSelector } from '../../../../redux/deposit-detail/selectors';
import { TripData } from './type';
import { CashDepositStatus } from '../../../../constants/cashDeposit/cashDeposit';
import CustomDetailPage from '../../../../reusable-components/CustomDetailPage';

function CashDepositDetailPage() {
  const { depositId } = useParams();
  const dispatch = useAppDispatch();
  let depositDetail = useAppSelector((state) =>
    depositDetailSelector(state, depositId!)
  );

  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.IDLE);
  const [trips, setTrips] = useState<TripData[]>([]);

  const getDepositDetail = async (depositId: string, forceReload = false) => {
    if (loadingStatus === LoadingStatus.LOADING) {
      return;
    }
    setLoadingStatus(LoadingStatus.LOADING);
    let [depositDetailResponse, depositHandover] = await Promise.all([
      dispatch(
        cashDepositDetailAction({
          depositId: depositId,
          forceReload: forceReload,
        })
      ),
      dispatch(fetchDepositHandoverDetailAction(depositId)),
    ]);

    if (depositDetailResponse && depositHandover) {
      const tripList = depositHandover.map((item) => ({
        trip_id: item.trip,
        amount: item.amount,
      }));
      setTrips(tripList);
      setLoadingStatus(LoadingStatus.IDLE);
    } else {
      setLoadingStatus(LoadingStatus.FAILED);
    }
  };

  useEffect(() => {
    getDepositDetail(depositId!);
  }, [depositId]);

  return (
    <CustomDetailPage
      loadingStatus={loadingStatus}
      detail={depositDetail}
      onReload={() => {
        getDepositDetail(depositId!, true);
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: 'rgba(240,246,255)',
          minHeight: '100vh',
        }}
      >
        <Typography variant='h6' component='div'>
          Deposit Details
        </Typography>
        <br />
        <Card>
          <CardContent>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <DetailItem
                label='Deposit Date:'
                value={DateUtils.getFormattedDateTimeString(
                  depositDetail?.deposit_date_time ?? ''
                )}
              />
              <DetailItem
                label='Transaction ID:'
                value={depositDetail?.transaction_id}
              />
              <DetailItem
                label={
                  depositDetail?.status === CashDepositStatus.DRAFTED
                    ? 'Amount to be Deposited:'
                    : 'Total Amount Deposited:'
                }
                value={convertPaisaToRupee(depositDetail?.amount)}
              />
              <DetailItem
                label='Hub:'
                value={depositDetail?.deposit_by_hub?.address?.address_name}
              />
            </Grid>
          </CardContent>
        </Card>
        <br />
        {trips.length > 0
          ? trips.map((tripData, index) => (
              <div key={index}>
                <TripDetailCard
                  tripId={tripData.trip_id}
                  tripAmount={tripData.amount}
                />
                <br />
              </div>
            ))
          : undefined}
      </Box>
    </CustomDetailPage>
  );
}

export default CashDepositDetailPage;
