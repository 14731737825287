import { entityActions } from '../entities/reducer';
import { AppThunk } from '../store';
import { entityStateKeys } from '../entities/constants';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { getTripShipmentDetail } from '../../data/api/tripShipmentRelation';
import { TripShipmentDetailEntity } from '../../data/model/trip/shipment';

export const fetchTripShipmentDetailAction =
  ({
    tripId,
    shipmentId,
  }: {
    tripId: string;
    shipmentId: string;
  }): AppThunk<Promise<TripShipmentDetailEntity | undefined>> =>
  async (dispatch, getState) => {
    try {
      const tripDetailResponse = await getTripShipmentDetail({
        tripId: tripId,
        shipmentId: shipmentId,
      });
      setEntities(tripDetailResponse, dispatch);
      return tripDetailResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };

function setEntities(detailEntity: TripShipmentDetailEntity, dispatch: any) {
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.shipment.short_tracking_id.toString(),
      key: entityStateKeys.TRIP_SHIPMENT_DETAIL,
    })
  );
}
