import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded';
import { MenuItem } from './type';

export const dashboardMenuItems: {
  [key: string]: MenuItem;
} = {
  dashboard: {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/',
    icon: SpaceDashboardRoundedIcon,
    breadcrumbs: false,
  },
};

const dashboard: MenuItem = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [dashboardMenuItems.dashboard],
};

export default dashboard;
