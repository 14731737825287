import { LogisticsPartner } from '../../../../../../constants/trips/trip-list';

export enum TPL {
  DELHIVERY = 'DELHIVERY',
  PROFESSIONAL = 'PROFESSIONAL',
  DTDC = 'DTDC',
  OTHERS = 'OTHERS',
}

export const ClientTypeOptions = [
  {
    label: '1Bridge',
    value: LogisticsPartner.ONE_BRIDGE,
  },
  {
    label: '3PL',
    value: LogisticsPartner.TPL,
  },
  {
    label: 'LOCAL',
    value: LogisticsPartner.LOCAL,
  },
];

export const TPLOptions = [
  {
    label: 'DELHIVERY',
    value: TPL.DELHIVERY,
  },
  {
    label: 'Professional',
    value: TPL.PROFESSIONAL,
  },
  {
    label: 'Dtdc',
    value: TPL.DTDC,
  },
  {
    label: 'Others',
    value: TPL.OTHERS,
  },
];
