import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { MenuItem } from '../../../../menu-items/type';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const MenuCard = ({ menuItem }: { menuItem: MenuItem }) => {
  const navigate = useNavigate();
  const ItemIcon = () => {
    if (menuItem.icon) {
      const Icon = menuItem.icon;
      return <Icon fontSize={'large'} />;
    }
    return <FiberManualRecordIcon />;
  };
  const itemHandler = () => {
    if (menuItem.type === 'item') {
      if (menuItem.url) {
        navigate(menuItem.url);
      }
    } else if (menuItem.type === 'collapse') {
      if (menuItem.children) {
        if (menuItem.children[0].url) {
          navigate(menuItem.children[0].url);
        }
      }
    }
  };

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent>
          <ItemIcon />
          <Typography sx={{ fontSize: 18 }} gutterBottom>
            {menuItem.title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={itemHandler}>
            View
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
