import { LoadingStatus } from '../../data/model/interfaces';
import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import {
  ShipmentDetailEntity,
  ShipmentListEntity,
  UpdateShipmentPayload,
} from '../../data/model/trip/shipment';
import { getUnAssignedShipmentList, updateShipment } from '../../data/api/shipment';
import { unAssignedShipmentListSelectors } from './selectors';
import { SnakbarUtils } from '../../utils/snakbar-util';

const fetchList = (forceReload = false): AppThunk => {
  return async (dispatch, getState) => {

    if (
      unAssignedShipmentListSelectors.selectLoadingStatus(getState()) ===
        LoadingStatus.LOADING
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.UNASSIGNED_SHIPMENT_LIST));
    }
    if (
      unAssignedShipmentListSelectors.selectListItems(getState()).length <
      unAssignedShipmentListSelectors.selectPageSize(getState()) *
      unAssignedShipmentListSelectors.selectPageNumber(getState()) ||
      unAssignedShipmentListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
          })
        );
        const shipmentListApiResponse = await getUnAssignedShipmentList({
          pageNumber: unAssignedShipmentListSelectors.selectPageNumber(getState()),
        });
        dispatch(
          listActions.setListResponse({
            listItems: shipmentListApiResponse.results.map(
              (item) => item.short_tracking_id
            ),
            count: shipmentListApiResponse.count,
            key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: shipmentListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.short_tracking_id.toString(),
            })),
            key: entityStateKeys.UNASSIGNED_SHIPMENT_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (
      unAssignedShipmentListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      unAssignedShipmentListSelectors.selectCount(getState()) >
      unAssignedShipmentListSelectors.selectPageSize(getState()) *
      unAssignedShipmentListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: unAssignedShipmentListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
        })
      );
      dispatch(fetchList());
    }
  };
};

const reloadListCurrentPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: unAssignedShipmentListSelectors.selectPageNumber(getState()),
        key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
      })
    );
    dispatch(fetchList());
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (unAssignedShipmentListSelectors.selectPageNumber(getState()) > 1) {
      dispatch(
        listActions.setPageNumber({
          pageNumber: unAssignedShipmentListSelectors.selectPageNumber(getState()) - 1,
          key: listSateKeys.UNASSIGNED_SHIPMENT_LIST,
        })
      );
    }
  };
};

const updateShipmentAction = ({
  requestData,
  shipmentId,
}: {
  requestData: UpdateShipmentPayload;
  shipmentId: string;
}): AppThunk<Promise<ShipmentDetailEntity | undefined>> => {
  return async (dispatch, getState) => {
    try {
      const updateShipmentApiResponse = await updateShipment({requestData: requestData, shipmentId: shipmentId,});
      SnakbarUtils.showSuccessMessage("Details Updated Successfully");
      setEntities(updateShipmentApiResponse, dispatch);
      return updateShipmentApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

function setEntities(detailEntity: ShipmentListEntity, dispatch: any) {
  dispatch(
    listActions.addCreatedItem({
      entity: detailEntity,
      createdItemId: detailEntity.short_tracking_id.toString(),
      key: listSateKeys.SHIPMENT_LIST,
    })
  );

  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.short_tracking_id.toString(),
      key: entityStateKeys.UNASSIGNED_SHIPMENT_LIST,
    })
  );
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.short_tracking_id.toString(),
      key: entityStateKeys.SHIPMENT_LIST,
    })
  );
}

export const unAssignedShipmentListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
  updateShipmentAction,
};
