import { GridColDef } from '@mui/x-data-grid';
import { DateUtils } from '../../../../utils/date-util';

const UNCOUNTED_DISTANCE_VALUE = 9999999;

export const getColumns: (rowModesModel: any) => GridColDef<
  {
    date: string;
    check_in_time: string;
    check_out_time: string;
    distance: number;
  },
  any,
  any
>[] = (rowModesModel) => [
  {
    field: 'date',
    headerName: 'Date',
    valueGetter: (v) => {
      return DateUtils.getFormattedDateString(v.row.date);
    },

    sortable: false,
    align: 'left',
    headerAlign: 'left',
    minWidth: 220,
  },

  {
    field: 'check_in_time',
    headerName: 'Checkin Time',
    valueGetter(params) {
      if (params.row.check_in_time) {
        return DateUtils.getFormattedDateTimeString(params.row.check_in_time);
      } else return '-';
    },
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    minWidth: 220,
  },
  {
    field: 'check_out_time',
    headerName: 'Checkout Time',
    valueGetter(params) {
      if (params.row.check_out_time) {
        return DateUtils.getFormattedDateTimeString(params.row.check_out_time);
      } else return '-';
    },
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    minWidth: 220,
  },
  {
    field: 'distance',
    headerName: 'Distance (KM)',
    valueGetter: (v) => {
      if (v.row.distance === UNCOUNTED_DISTANCE_VALUE) {
        return '-';
      }
      return ((v.row.distance ?? 0) / 1000).toLocaleString(`en-IN`, {
        minimumFractionDigits: 3,
      });
    },
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    minWidth: 220,
  },
];
