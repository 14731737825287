import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import ReporteeListPage from '../../modules/employee-management/reportees/ReporteeListPage';
import ReporteeBulkUploadComponent from '../../modules/employee-management/bulk-upload';
import CheckinListPage from '../../modules/employee-management/reportees/CheckinListPage';

const EmployeeManagementRoutes: RouteObject = {
  path: 'employee-management/',
  element: <MainLayout />,
  children: [
    {
      path: 'reportees',
      element: <ReporteeListPage />,
    },
    {
      path: ':managerId/reportees',
      element: <ReporteeListPage />,
    },
    {
      path: 'link',
      element: <ReporteeBulkUploadComponent />,
    },
    {
      path: ':role/:reporteeId/checkins',
      element: <CheckinListPage />,
    },
  ],
};

export default EmployeeManagementRoutes;
