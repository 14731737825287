export interface IListState {
  filters: IFilter[];
  listItems: string[];
  pageNumber: number;
  loadingStatus: LoadingStatus;
  count: number;
  pageSize: number;
}

export interface IFilter {
  filterKey: string;
  filterValue: IFilterValue | IFilterValue[];
}

export interface IFilterValue {
  label: string;
  value: string | number;
}

export interface IDetailState {
  markForReload: boolean;
  selectedItemId: string | number | null;
  loadingStatus: {
    [key: string | number]: LoadingStatus;
  };
}

export enum LoadingStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
}

export interface ListResponse<E> {
  count: number;
  next: string | null;
  previous: string | null;
  results: E[];
}
export interface Image {
  photo_id: string;
  photo_url: string;
  content_type: string;
}
