export const entityStateKeys = {
  TRIP_LIST: 'tripList',
  SHIPMENT_LIST: 'shipmentList',
  SHIPMENT_DETAIL: 'shipmentDetail',
  TRIP_DETAIL: 'tripDetail',
  UNASSIGNED_SHIPMENT_LIST: 'unAssignedShipmentList',
  TRIP_SHIPMENT_LIST: 'tripShipmentList',
  SHIPMENT_TRAIL_LIST: 'shipmentTrailList',
  TRIP_SHIPMENT_DETAIL: 'tripShipmentDetail',
  PINCODE_LIST: 'pincodeList',
  DELIVERY_AGENT_LIST: 'deliveryAgentList',
  CASH_DEPOSITS_LIST: 'cashDepositsList',
  CASH_DEPOSIT_DETAIL: 'cashDepositDetail',
  REPORTEE_LIST: 'reporteeList'
} as const;
