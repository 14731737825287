import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import PageContainer from '../../../../reusable-components/PageContainer';
import { getColumns } from './table-data';
import { reporteeListSelectors } from '../../../../redux/reportee-list/selectors';
import { reporteeListThunkActions } from '../../../../redux/reportee-list/thunk-actions';
import { ReporteeListEntity } from '../../../../data/model/geolocation/reportee';
import ListDataGridToolbar from '../../../../reusable-components/data-grid/ListDataGridToolbar';
import { LoadingStatus } from '../../../../data/model/interfaces';
import {
  DataGrid,
  GridPaginationModel,
  GridRowModesModel,
} from '@mui/x-data-grid';

const ReporteeListPage = () => {
  const location = useLocation();
  const reporteeList = useAppSelector(reporteeListSelectors.selectPageItems);
  const reporteeCount = useAppSelector(reporteeListSelectors.selectCount);
  const reporteePageSize = useAppSelector(reporteeListSelectors.selectPageSize);
  const reporteePageNumber = useAppSelector(
    reporteeListSelectors.selectPageNumber
  );
  const reporteeListLoadingStatus = useAppSelector(
    reporteeListSelectors.selectLoadingStatus
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userName = location.state?.userName ?? '';

  let { managerId } = useParams();

  const managerIdFromState =
    useAppSelector(
      (appstate) => appstate.sharedPref.authData?.user.user.user_id
    ) ?? '';

  if (!managerId) {
    managerId = managerIdFromState;
  }

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const title = userName ? `Reportee List - ${userName}` : 'Reportee List';

  useEffect(() => {
    dispatch(reporteeListThunkActions.fetchList(managerId!, true));
  }, [dispatch, managerId]);

  const fetchNextPage = () => {
    dispatch(reporteeListThunkActions.fetchListNextPage(managerId!));
  };

  const fetchPreviousPage = () => {
    dispatch(reporteeListThunkActions.fetchListPreviousPage());
  };

  const refresh = () => {
    dispatch(reporteeListThunkActions.fetchList(managerId!, true));
  };

  const reloadCurrentPage = () => {
    dispatch(reporteeListThunkActions.fetchList(managerId!));
  };

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > reporteePageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < reporteePageNumber - 1) {
      fetchPreviousPage();
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <PageContainer>
      <DataGrid<ReporteeListEntity>
        columns={getColumns(rowModesModel, refresh)}
        rows={reporteeList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        pagination
        editMode="row"
        getRowId={(row) => row.reportee_id + row.reportee_role}
        paginationModel={{
          page: reporteePageNumber - 1,
          pageSize: reporteePageSize,
        }}
        pageSizeOptions={[]}
        rowCount={reporteeCount}
        paginationMode="server"
        loading={reporteeListLoadingStatus === LoadingStatus.LOADING}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title,
            refresh,
          },
        }}
        onPaginationModelChange={handlePaginationModelChange}
        rowSelection={false}
        disableColumnFilter
      />
    </PageContainer>
  );
};

export default ReporteeListPage;
