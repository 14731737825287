import { RouteObject } from 'react-router-dom';
import MainLayout from '../../layout/MainLayout';
import HomePage from '../../modules/dashbord/HomePage';

const MainRoutes: RouteObject = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />,
    },
  ],
};

export default MainRoutes;
