import { entityStateKeys } from '../entities/constants';
import { entityActions } from '../entities/reducer';
import { listSateKeys } from '../lists/constants';
import { listActions } from '../lists/reducer';
import { AppThunk } from '../store';
import { LoadingStatus } from '../../data/model/interfaces';
import { cashDepositsListSelectors } from './selectors';
import {
  createNewDeposit,
  depositVerificationRequest,
  getCashDepositsList,
  getPaymentStats,
  updateDeposit,
} from '../../data/api/cashDeposit';
import {
  CashDepositsDetailEntity,
  CashDepositsListEntity,
  CreateDepositPayload,
  PaymentStatsInfo,
  UpdateDepositPayload,
} from '../../data/model/cash-deposit/cashDeposit';
import { SnakbarUtils } from '../../utils/snakbar-util';

const fetchList = (forceReload = false): AppThunk => {
  return async (dispatch, getState) => {
    const { selectedHubId } = getState().sharedPref;
    if (
      cashDepositsListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (forceReload) {
      dispatch(listActions.resetListState(listSateKeys.CASH_DEPOSITS_LIST));
    }
    if (
      cashDepositsListSelectors.selectListItems(getState()).length <
        cashDepositsListSelectors.selectPageSize(getState()) *
          cashDepositsListSelectors.selectPageNumber(getState()) ||
      cashDepositsListSelectors.selectPageSize(getState()) === 0
    ) {
      try {
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.LOADING,
            key: listSateKeys.CASH_DEPOSITS_LIST,
          })
        );
        const getCashDepositListApiResponse = await getCashDepositsList({
          pageNumber: cashDepositsListSelectors.selectPageNumber(getState()),
          hubId: selectedHubId!,
        });
        dispatch(
          listActions.setListResponse({
            listItems: getCashDepositListApiResponse.results.map(
              (item) => item.deposit_id
            ),
            count: getCashDepositListApiResponse.count,
            key: listSateKeys.CASH_DEPOSITS_LIST,
          })
        );
        dispatch(
          entityActions.setEntities({
            list: getCashDepositListApiResponse.results.map((result) => ({
              entity: result,
              entityId: result.deposit_id.toString(),
            })),
            key: entityStateKeys.CASH_DEPOSITS_LIST,
          })
        );
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.IDLE,
            key: listSateKeys.CASH_DEPOSITS_LIST,
          })
        );
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
        dispatch(
          listActions.setLoadingStatus({
            loadingStatus: LoadingStatus.FAILED,
            key: listSateKeys.CASH_DEPOSITS_LIST,
          })
        );
      }
    }
  };
};

const fetchListNextPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (
      cashDepositsListSelectors.selectLoadingStatus(getState()) ===
      LoadingStatus.LOADING
    ) {
      return;
    }
    if (
      cashDepositsListSelectors.selectCount(getState()) >
      cashDepositsListSelectors.selectPageSize(getState()) *
        cashDepositsListSelectors.selectPageNumber(getState())
    ) {
      dispatch(
        listActions.setPageNumber({
          pageNumber:
            cashDepositsListSelectors.selectPageNumber(getState()) + 1,
          key: listSateKeys.CASH_DEPOSITS_LIST,
        })
      );
      dispatch(fetchList());
    }
  };
};

const reloadListCurrentPage = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      listActions.setPageNumber({
        pageNumber: cashDepositsListSelectors.selectPageNumber(getState()),
        key: listSateKeys.CASH_DEPOSITS_LIST,
      })
    );
    dispatch(fetchList());
  };
};

const fetchListPreviousPage = (): AppThunk => {
  return async (dispatch, getState) => {
    if (cashDepositsListSelectors.selectPageNumber(getState()) > 1) {
      dispatch(
        listActions.setPageNumber({
          pageNumber:
            cashDepositsListSelectors.selectPageNumber(getState()) - 1,
          key: listSateKeys.CASH_DEPOSITS_LIST,
        })
      );
    }
  };
};

export const getPaymnentStatsAction =
  (selectedHubId: string): AppThunk<Promise<PaymentStatsInfo | undefined>> =>
  async (_dispatch, _getState) => {
    if (selectedHubId) {
      try {
        const paymentStatsApiResponse = await getPaymentStats(selectedHubId);
        return paymentStatsApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    }
  };

export const createNewDepositAction =
  (
    requestBody: CreateDepositPayload
  ): AppThunk<Promise<CashDepositsDetailEntity | undefined>> =>
  async (dispatch, _getState) => {
    try {
      const createNewDepositApiResponse = await createNewDeposit(requestBody);
      const depositDetail: CashDepositsListEntity = {
        deposit_id: createNewDepositApiResponse.deposit_id,
        amount: createNewDepositApiResponse.amount,
        created: createNewDepositApiResponse.created,
        deposit_date_time: createNewDepositApiResponse.deposit_date_time,
        status: createNewDepositApiResponse.status,
        transaction_id: createNewDepositApiResponse.transaction_id,
        transaction_proof_img_url:
          createNewDepositApiResponse.transaction_proof_img_url,
        notes: createNewDepositApiResponse.notes,
        finance_admin_remarks:
          createNewDepositApiResponse.finance_admin_remarks,
      };
      setEntities(depositDetail, dispatch);
      return createNewDepositApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };

export const updateDepositAction =
  (
    requestBody: UpdateDepositPayload,
    depositId: number
  ): AppThunk<Promise<CashDepositsDetailEntity | undefined>> =>
  async (dispatch, _getState) => {
    try {
      const updateDepositApiResponse = await updateDeposit(
        requestBody,
        depositId.toString()
      );
      const depositDetail: CashDepositsListEntity = {
        deposit_id: updateDepositApiResponse.deposit_id,
        amount: updateDepositApiResponse.amount,
        created: updateDepositApiResponse.created,
        deposit_date_time: updateDepositApiResponse.deposit_date_time,
        status: updateDepositApiResponse.status,
        transaction_id: updateDepositApiResponse.transaction_id,
        transaction_proof_img_url:
          updateDepositApiResponse.transaction_proof_img_url,
        notes: updateDepositApiResponse.notes,
        finance_admin_remarks: updateDepositApiResponse.finance_admin_remarks,
      };
      setEntities(depositDetail, dispatch);
      return updateDepositApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };

export const depositVerificationRequestAction =
  (
    requestBody: UpdateDepositPayload,
    depositId: number
  ): AppThunk<Promise<CashDepositsDetailEntity | undefined>> =>
  async (dispatch, _getState) => {
    try {
      const depositVerificationRequestApiResponse =
        await depositVerificationRequest(requestBody, depositId.toString());
      const depositDetail: CashDepositsListEntity = {
        deposit_id: depositVerificationRequestApiResponse.deposit_id,
        amount: depositVerificationRequestApiResponse.amount,
        created: depositVerificationRequestApiResponse.created,
        deposit_date_time:
          depositVerificationRequestApiResponse.deposit_date_time,
        status: depositVerificationRequestApiResponse.status,
        transaction_id: depositVerificationRequestApiResponse.transaction_id,
        transaction_proof_img_url:
          depositVerificationRequestApiResponse.transaction_proof_img_url,
        notes: depositVerificationRequestApiResponse.notes,
        finance_admin_remarks:
          depositVerificationRequestApiResponse.finance_admin_remarks,
      };
      setEntities(depositDetail, dispatch);
      return depositVerificationRequestApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };

function setEntities(detailEntity: CashDepositsListEntity, dispatch: any) {
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.deposit_id.toString(),
      key: entityStateKeys.CASH_DEPOSITS_LIST,
    })
  );
}

export const cashDepositsListThunkActions = {
  fetchList,
  fetchListNextPage,
  reloadListCurrentPage,
  fetchListPreviousPage,
  getPaymnentStatsAction,
  createNewDepositAction,
  updateDepositAction,
  depositVerificationRequestAction,
};
