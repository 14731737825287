import { TripStatus } from '../../../../../../constants/trips/trip-list';
import { getTripList } from '../../../../../../data/api/trip';
import { TripListEntity } from '../../../../../../data/model/trip/trip';
import { store } from '../../../../../../redux/store';
import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';

const schema = {
  fields: [
    {
      label: 'Select Trip',
      name: 'trip_id',
      component: customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD,
      isSearchable: true,
      loadOptions: (currentSearchValue: string) =>
        new Promise((resolve, reject) => {
          const { selectedHubId } = store.getState().sharedPref;
          getTripList({ searchString: currentSearchValue, hubId: selectedHubId! })
            .then((response) => {
              let filteredList = response.results.filter(
                (trip) =>
                  trip.status !== TripStatus.CANCELLED &&
                  trip.status !== TripStatus.COMPLETED
              );
              resolve(
                filteredList.map((item: TripListEntity) => ({
                  label: `TRIP ID: ${item.trip_id}`,
                  value: item.trip_id,
                }))
              );
            })
            .catch(reject);
        }),
      simpleValue: true,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
  ],
} as const;

export type AddTripFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
