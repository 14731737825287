import { entityActions } from '../entities/reducer';
import { AppThunk } from '../store';
import { entityStateKeys } from '../entities/constants';
import { SnakbarUtils } from '../../utils/snakbar-util';
import {
  AddToExistingTripPayload,
  CreateTripPayload,
  PaymentInfo,
  TripDetailEntity,
  TripHandoverDetail,
  UpdateTripInfo,
} from '../../data/model/trip/trip';
import {
  createTrip,
  getTripDetailById,
  updateTripDetails,
  updateTripStatus,
} from '../../data/api/trip';
import {
  getTripPaymentDetails,
  getTripPaymentHandoverDetails,
} from '../../data/api/payment';
import { addTripShipmentRelation } from '../../data/api/tripShipmentRelation';
import { listActions } from '../lists/reducer';
import { listSateKeys } from '../lists/constants';
import { ErrorCode } from '../../constants/errorCodes/errCode';

export const fetchTripDetailAction =
  (
    tripId: string,
    forceReload = false
  ): AppThunk<Promise<TripDetailEntity | undefined>> =>
  async (dispatch, getState) => {
    const selectedTripDetail = getState().entities.tripDetail[tripId];
    if (!selectedTripDetail || forceReload) {
      try {
        const tripDetailResponse = await getTripDetailById(tripId);
        setEntities(tripDetailResponse, dispatch);
        return tripDetailResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    }
    return selectedTripDetail;
  };
  
  export const createTripAction = (
    requestBody: CreateTripPayload,
    selectedShipmentIds: number[],
  ): AppThunk<Promise<TripDetailEntity | undefined>> => {
    return async (_dispatch, _getState) => {
      try {
        const createTripApiResponse = await createTrip(requestBody);
        let requestData: AddToExistingTripPayload = {
          shipment_ids: selectedShipmentIds,
        };
        if(createTripApiResponse) {
          await addTripShipmentRelation(
            requestData,
            createTripApiResponse.trip_id.toString(),
          );
        }
        SnakbarUtils.showSuccessMessage('Trip Created Successfully');
        return createTripApiResponse;
      } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};


export const getTripPaymentDetailsAction = (
  tripId: string
): AppThunk<Promise<PaymentInfo | undefined>> => {
  return async (_dispatch, _getState) => {
    try {
      const createTripApiResponse = await getTripPaymentDetails(tripId);
      return createTripApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};


export const getPaymentHandoverDetailsAction = (
  tripId: string
): AppThunk<Promise<TripHandoverDetail | undefined>> => {
  return async (_dispatch) => {
    try {
      const getPaymentHandoverApiResponse = await getTripPaymentHandoverDetails(
        tripId
      );
      return getPaymentHandoverApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

export const updatedTripStatusAction = ({
  tripId,
  status,
}: {
  tripId: string;
  status: string;
}): AppThunk<Promise<TripDetailEntity | undefined | boolean>> => {
  return async (dispatch, getState) => {
    try {
      const updateTripApiResponse = await updateTripStatus({
        tripId: tripId,
        status: status,
      });
        dispatch(
          listActions.removeDeletedItem({
            removedItemId: tripId,
            key: listSateKeys.TRIP_LIST,
          })
        );
        setEntities(updateTripApiResponse, dispatch);
        return updateTripApiResponse;
      } catch (error: any) {
        console.error(error);
        const errorCode: string = error.response?.data?.code;
        if(errorCode === ErrorCode.TRP601) {
          return true;
        } else {
          SnakbarUtils.showAxiosErrorMessage(error);
        }
      }
    }
  };

  export const updateTripDetailsAction = ({
    tripId,
    requestPayload,
  }: {
    tripId: string;
    requestPayload: UpdateTripInfo;
  }): AppThunk<Promise<TripDetailEntity | undefined | boolean>> => {
    return async (dispatch, getState) => {
      try {
        const updateTripApiResponse = await updateTripDetails({
          tripId: tripId,
          requestPayload: requestPayload,
        });
    
        dispatch(
          entityActions.setEntity({
            entity: updateTripApiResponse,
            entityId: updateTripApiResponse.trip_id.toString(),
            key: entityStateKeys.TRIP_LIST,
          })
        );

          setEntities(updateTripApiResponse, dispatch);
          return updateTripApiResponse;
        } catch (error: any) {
          console.error(error);
          const errorCode: string = error.response?.data?.code;
          if(errorCode === ErrorCode.TRP601) {
            return true;
          } else {
            SnakbarUtils.showAxiosErrorMessage(error);
          }
        }
      }
    };
  

function setEntities(detailEntity: TripDetailEntity, dispatch: any) {
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.trip_id.toString(),
      key: entityStateKeys.TRIP_DETAIL,
    })
  );
}
