import { listSateKeys } from '../lists/constants';
import { listSelectors } from '../lists/selectors';
import { RootState } from '../store';

const selectListItems = (state: RootState) =>
  listSelectors
    .selectListItemIds(state, listSateKeys.CASH_DEPOSITS_LIST)
    .map((item) => state.entities.cashDepositsList[item]);

const selectCount = (state: RootState) =>
  listSelectors.selectCount(state, listSateKeys.CASH_DEPOSITS_LIST);

const selectPageSize = (state: RootState) =>
  listSelectors.selectPageSize(state, listSateKeys.CASH_DEPOSITS_LIST);

const selectPageNumber = (state: RootState) =>
  listSelectors.selectPageNumber(state, listSateKeys.CASH_DEPOSITS_LIST);

const selectPageItems = (state: RootState) =>
  listSelectors
    .selectPageItemIds(state, listSateKeys.CASH_DEPOSITS_LIST)
    .map((item) => state.entities.cashDepositsList[item]);

const selectLoadingStatus = (state: RootState) =>
  listSelectors.selectLoadingStatus(state, listSateKeys.CASH_DEPOSITS_LIST);

const selectFilterValue = (state: RootState, filterKey: string) =>
  listSelectors.selectFilterValue(
    state,
    filterKey,
    listSateKeys.CASH_DEPOSITS_LIST
  );

const selectFilters = (state: RootState) =>
  listSelectors.selectFilters(state, listSateKeys.CASH_DEPOSITS_LIST);

export const cashDepositsListSelectors = {
  selectListItems,
  selectPageItems,
  selectCount,
  selectPageSize,
  selectPageNumber,
  selectLoadingStatus,
  selectFilterValue,
  selectFilters,
};
