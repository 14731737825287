import { Button, Dialog, DialogContent } from '@mui/material';
import { useState } from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import TripForm from './CreateTripForm';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import { CreateTripFormValues } from './CreateTripForm/trip-form-schema';
import {
  CreateTripPayload,
  TripDetailEntity,
} from '../../../../../data/model/trip/trip';
import { createTripAction } from '../../../../../redux/trip-detail/thunk-actions';
import { useNavigate } from 'react-router-dom';
import { LogisticsPartner } from '../../../../../constants/trips/trip-list';
import { TPL } from './CreateTripForm/trip-form-options-type';
import dayjs from 'dayjs';

const CreateTripButton = ({
  selectedIds,
  refresh,
}: {
  selectedIds: number[];
  refresh: () => void;
}) => {
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (formValues: CreateTripFormValues & any) => {
    let requestBody: CreateTripPayload = {
      hub_id: hubId ?? '',
      client: formValues.logistics_partner,
      trip_cost: {
        client_cost: formValues.trip_cost.client_cost
        ? formValues.trip_cost.client_cost * 100
        : 0,
        fuel_cost: formValues.trip_cost.fuel_cost
        ? formValues.trip_cost.fuel_cost * 100
        : 0,
        vehicle_rent: formValues.trip_cost.vehicle_rent
        ? formValues.trip_cost.vehicle_rent * 100
        : 0,
      },
    };

    if (formValues.logistics_partner === LogisticsPartner.TPL) {
      requestBody.client_tpl = {
        delivery_partner_name:
          formValues.client_tpl_details.delivery_partner_name === TPL.OTHERS
            ? formValues.client_tpl_details.others
            : formValues.client_tpl_details.delivery_partner_name,
        tracking_number: formValues.client_tpl_details.tracking_number,
      };
      requestBody.scheduled_end_time = formValues.scheduled_end_time;
    } else if (formValues.logistics_partner === LogisticsPartner.LOCAL) {
      requestBody.client_local = {
        driver_name: formValues.client_local_details.driver_name,
        driver_phone: formValues.client_local_details.driver_phone,
      };
      requestBody.scheduled_start_time = formValues.scheduled_start_time;
    } else {
      requestBody.delivery_agent_id =
        formValues.delivery_agent_info_object?.value?.user_id;
      requestBody.scheduled_start_time = dayjs(formValues.scheduled_start_time).format();
      requestBody.delivery_agent_info = {
        name: formValues.delivery_agent_info_object?.value?.user_info.name,
        phone: formValues.delivery_agent_info_object?.value?.user_info.phone,
      }
    }

    let response: TripDetailEntity | undefined = await dispatch(
      createTripAction(requestBody, selectedIds)
    );
    if (response) {
      closeDialog();
      refresh();
      navigate(`/trips/${response.trip_id}`, {
        state: {
          forceReload: true,
        },
      });
    }
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="small"
        onClick={openDialog}
        endIcon={<ArrowForwardOutlinedIcon />}
        disabled={selectedIds.length === 0}
      >
        Create a trip
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent>
          <TripForm closeDialog={closeDialog} handleSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateTripButton;
