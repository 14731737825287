import { useFieldApi } from '..';
import { Chip, Box } from '@mui/material';

export function CustomChip(props: any) {
  const { input, options } = useFieldApi(props);

  const handleChange = (value: any) => {
    input.onChange(value.value);
  };

  return (
    <Box sx={{ marginTop: '8px' }}>
      {options.map((item: any) => (
        <Chip
          key={item.label}
          label={item.label}
          color={input?.value === item.value ? 'primary' : 'default'}
          variant="outlined"
          sx={{
            marginRight: '12px',
            marginBottom: '12px',
          }}
          clickable
          onClick={() => handleChange(item)}
        />
      ))}
    </Box>
  );
}
