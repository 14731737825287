import { useAppDispatch } from '../../../redux/hooks';
import { Box, Container } from '@mui/material';
import { FormRenderer } from '@data-driven-forms/react-form-renderer';
import { loginFromApiThunkAction } from '../../../redux/login/thunk-actions';
import schema, { FormValues } from './login-form-schema';
import {
  CustomFormTemplate,
  customComponentMapper,
} from '../../../reusable-components/data-driven-forms';

function LoginPage() {
  const dispatch = useAppDispatch();

  const handleSubmit = async (formValues: FormValues) => {
    await dispatch(
      loginFromApiThunkAction(formValues.phone, formValues.password)
    );
  };

  return (
    <Box sx={{ width: '100%', height: '600px', mt: '100px' }}>
      <Container maxWidth="sm">
        <FormRenderer
          FormTemplate={CustomFormTemplate}
          componentMapper={customComponentMapper}
          schema={schema}
          onSubmit={handleSubmit}
        />
      </Container>
    </Box>
  );
}

export default LoginPage;
