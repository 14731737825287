import { Typography } from '@mui/material';
import { PaymentStatus } from '../../../../../constants/trips/payment';
import { DisplayUtils } from '../../../../../utils/display-util';

function ShipmentPaymentStatusTag({ paymentStatus }: { paymentStatus: string }) {
  return (
      <Typography
        variant="subtitle2"
        component="div"
        style={{
          color: paymentStatus === PaymentStatus.PAID || paymentStatus === PaymentStatus.COLLECTED
          ? 'rgba(1, 157, 89, 0.7)' 
          : 'rgba(255, 0, 0, 0.7)',
          background: paymentStatus === PaymentStatus.PAID || paymentStatus === PaymentStatus.COLLECTED
          ? 'rgba(1, 157, 89, 0.2)' 
          : 'rgba(255, 0, 0, 0.2)',
          display: 'inline-block',
          padding: '4px',
        }}
      >
        {DisplayUtils.paymentStatusString(paymentStatus)}
      </Typography>
  );
}

export default ShipmentPaymentStatusTag;
