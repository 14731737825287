import {
  ITableHead,
  ITableRow,
} from '../../../../reusable-components/table/type';
import { RemovePincodeButton } from '../PincodeRemoveButton';

export const tableHead: ITableHead<string> = [
  { label: 'Pincode' },

  { label: 'Action', component: RemovePincodeButton },
];

export const getTableRows = (pincodeList: string[]): ITableRow<string>[] => {
  if (pincodeList.length === 0) {
    let row: ITableRow<string>;
    row = {
      id: 'No Data',
      value: 'No Data',
      cells: [{ label: 'No Data Available' }],
    };
    return [row];
  } else {
    return pincodeList.reduce<ITableRow<string>[]>(
      (accumulator, currentValue) => {
        const row: ITableRow<string> = {
          id: currentValue,
          value: currentValue,
          cells: [
            { label: currentValue },
            { label: currentValue, value: currentValue },
          ],
        };
        return [...accumulator, row];
      },
      []
    );
  }
};
