import { AxiosResponse } from 'axios';
import { logisticsApiClient } from './logisticsApiClient';
import { ListResponse } from '../model/interfaces';
import {
  CreateTripPayload,
  TripDetailEntity,
  TripListEntity,
  UpdateTripInfo,
} from '../model/trip/trip';
import { tripsUrl, tripDetailUrl } from './apiUrls/logisticsApiUrls';

export const getTripList = async ({
  pageNumber = 1,
  searchString,
  queryParams,
  hubId,
  depositCreated,
}: {
  pageNumber?: number;
  searchString?: string;
  hubId: string;
  depositCreated?: boolean;
  queryParams?: {
    [key: string]: any;
  };
}) => {
  const axiosResponse: AxiosResponse<ListResponse<TripListEntity>> =
    await logisticsApiClient.get(tripsUrl, {
      params: {
        page: pageNumber,
        search_query: searchString,
        hub_id: hubId,
        deposit_created: depositCreated,
        ...queryParams,
      },
    });
  return axiosResponse.data;
};

export const createTrip = async (requestBody: CreateTripPayload) => {
  const axiosResponse: AxiosResponse<TripDetailEntity> =
    await logisticsApiClient.post(tripsUrl, requestBody);
  return axiosResponse.data;
};

export const getTripDetailById = async (tripId: string) => {
  const axiosResponse: AxiosResponse<TripDetailEntity> =
    await logisticsApiClient.get(tripDetailUrl(tripId));
  return axiosResponse.data;
};

export const updateTripStatus = async ({
  status,
  tripId,
}: {
  status: string;
  tripId: string;
}) => {
  const axiosResponse: AxiosResponse<TripDetailEntity> =
    await logisticsApiClient.patch(tripDetailUrl(tripId), {
      status: status,
    });
  return axiosResponse.data;
};

export const updateTripDetails = async ({
  tripId,
  requestPayload,
}: {
  tripId: string;
  requestPayload: UpdateTripInfo;
}) => {
  const axiosResponse: AxiosResponse<TripDetailEntity> =
    await logisticsApiClient.patch(tripDetailUrl(tripId), requestPayload);
  return axiosResponse.data;
};
