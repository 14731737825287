import { useState } from 'react';

function useDialogBox() {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setOpen(false);
  };

  return {
    open,
    openDialog,
    closeDialog,
    isSubmitting,
    setIsSubmitting,
  };
}

export default useDialogBox;
