import { InputBaseComponentProps } from '@mui/material/InputBase';

import { styled } from '@mui/material/styles';
import { ForwardedRef, forwardRef, useId } from 'react';

const Label = styled('label')`
  position: relative;
  flex-grow: 1;
  input {
    opacity: 0 !important;
  }

  span {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    margin: 16px 0px;
    line-break: anywhere;
  }

  span.MuiFileInput-placeholder {
    color: gray;
  }
`;

type InputProps = InputBaseComponentProps & {
  text: string;
  isPlaceholder: boolean;
};

export const CustomInput = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { text, isPlaceholder, placeholder, ...restInputProps } = props;
    // eslint-disable-next-line react/hook-use-state
    const id = useId();

    return (
      <Label htmlFor={id}>
        <input {...restInputProps} ref={ref} id={id} />
        {text ? (
          <span
            aria-placeholder={placeholder}
            className={isPlaceholder ? 'MuiFileInput-placeholder' : ''}
          >
            {text}
          </span>
        ) : null}
      </Label>
    );
  }
);
