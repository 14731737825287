import { enqueueSnackbar } from 'notistack';

export class SnakbarUtils {
  static showAxiosErrorMessage(error: any) {
    const errorMessage = error?.response?.data?.message;
    enqueueSnackbar(errorMessage || 'Failed, Something went wrong', {
      variant: 'error',
    });
  }

  static showErrorMessage(errorMessage: string) {
    enqueueSnackbar(errorMessage, {
      variant: 'error',
    });
  }
  
  static showInfoMessage(showInfoMessage: string) {
    enqueueSnackbar(showInfoMessage, {
      variant: 'info',
    });
  }

  static showSuccessMessage(successMessage: string) {
    enqueueSnackbar(successMessage, {
      variant: 'success',
    });
  }
}
