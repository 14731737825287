import React from 'react';
import CloudUploadIcon from '@mui/icons-material/UploadFile';
import { Box, Button, Typography } from '@mui/material';

export type FileUploadProps = {
  imageButton?: boolean;
  accept: string;
  hoverLabel?: string;
  dropLabel?: string;
  height?: string;
  backgroundColor?: string;
  onFileSelected: (file: File) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  imageButton = false,
  hoverLabel = 'Drag here to upload a file',
  dropLabel = 'Drop the file here',
  height = '200px',
  backgroundColor = '#fff',
  onFileSelected,
}) => {
  const [labelText, setLabelText] = React.useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      const dataTransferItems = e.dataTransfer.items;
      if (dataTransferItems?.length) {
        const item = dataTransferItems[0];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            onFileSelected(file);
          }
        }
      }
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      const file = files[0];
      onFileSelected(file);
    }
  };

  return (
    <Box
      sx={{
        border: '2px dashed #bbb',
      }}
    >
      <input
        onChange={handleChange}
        accept={accept}
        style={{
          display: 'none',
        }}
        id="file-upload"
        type="file"
      />

      <label
        htmlFor="file-upload"
        {...dragEvents}
        style={{
          cursor: 'pointer',
          textAlign: 'center',
          display: 'flex',
        }}
      >
        <Box
          height={height}
          bgcolor={backgroundColor}
          sx={{
            pointerEvents: 'none',
            display: 'flex',
            flex: '1',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box>
                <CloudUploadIcon fontSize="large" />
                <br></br>
                <Typography>{labelText}</Typography>
                <Typography>OR</Typography>
                <Button>Select a CSV or Excel File to Upload</Button>
              </Box>
            </>
          )}
        </Box>
      </label>
    </Box>
  );
};
