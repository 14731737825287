import { listSateKeys } from '../lists/constants';
import { listSelectors } from '../lists/selectors';
import { RootState } from '../store';

const selectListItems = (state: RootState) =>
  listSelectors
    .selectListItemIds(state, listSateKeys.SHIPMENT_LIST)
    .map((item) => state.entities.shipmentList[item]);

const selectCount = (state: RootState) =>
  listSelectors.selectCount(state, listSateKeys.SHIPMENT_LIST);

const selectPageSize = (state: RootState) =>
  listSelectors.selectPageSize(state, listSateKeys.SHIPMENT_LIST);

const selectPageNumber = (state: RootState) =>
  listSelectors.selectPageNumber(state, listSateKeys.SHIPMENT_LIST);

const selectPageItems = (state: RootState) =>
  listSelectors
    .selectPageItemIds(state, listSateKeys.SHIPMENT_LIST)
    .map((item) => state.entities.shipmentList[item]);

const selectLoadingStatus = (state: RootState) =>
  listSelectors.selectLoadingStatus(state, listSateKeys.SHIPMENT_LIST);

  const selectFilterValue = (state: RootState, filterKey: string) =>
  listSelectors.selectFilterValue(state, filterKey, listSateKeys.SHIPMENT_LIST);

const selectFilters = (state: RootState) =>
  listSelectors.selectFilters(state, listSateKeys.SHIPMENT_LIST);


export const shipmentListSelectors = {
  selectListItems,
  selectPageItems,
  selectCount,
  selectPageSize,
  selectPageNumber,
  selectLoadingStatus,
  selectFilterValue,
  selectFilters,
};
