import { SnakbarUtils } from '../../../utils/snakbar-util';

export interface LocationInfo {
lat: number;
lon: number;
};

const getLocation = (
  callback: (locationData: LocationInfo) => void,
  input: any,
  setHasLocationAccess: any
) => {
  if (!input.value) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const locationData: LocationInfo = {
            lat: latitude,
            lon: longitude,
          };
          input.onChange(locationData);
          setHasLocationAccess(true);
          callback(locationData);
        },
        () => {
          setHasLocationAccess(false);
          SnakbarUtils.showErrorMessage('Unable to retrieve your location');
        }
      );
    } else {
      SnakbarUtils.showErrorMessage('Geolocation not supported');
    }
  } else {
    const locationData: LocationInfo = {
      lat: input.value.lat,
      lon: input.value.lon,
    };
    callback(locationData);
  }
};

export default getLocation;
