import { ItemDetail } from '../../../../../data/model/trip/shipment';
import SimpleTable from '../../../../../reusable-components/table/SimpleTable';
import { getTableRows, tableHead } from './table-data';

export const ShipmentItemsTable = ({
  shipmentItems,
}: {
  shipmentItems: ItemDetail[];
}) => {
  return (
    <SimpleTable
      tableHead={tableHead}
      tableRows={getTableRows(shipmentItems)}
      isRowClickable
    />
  );
};
