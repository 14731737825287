import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MouseEventHandler } from 'react';
import { ITableHead, ITableRow, defaultCellWidth } from '../type';
import { Paper, TableContainer, Tooltip } from '@mui/material';

function SimpleTable<R = any>({
  tableHead,
  tableRows,
  onCellClicked,
  isRowClickable,
}: {
  tableHead: ITableHead<R>;
  tableRows: ITableRow<R>[];
  onCellClicked?: (cellValue: any, cellIndex: number, rowValue: R | string) => void;
  isRowClickable?: boolean;
}) {
  return (
    <TableContainer component={Paper}>
      <Table
        style={{
          width: '-webkit-fill-available',
        }}
      >
        <TableHead>
          <TableRow>
            {tableHead.map((cell, index) => (
              <TableCell
                component="th"
                scope="row"
                key={index}
                align={
                  cell.component ? 'center' : cell.isNumeric ? 'right' : 'left'
                }
                sx={{
                  fontWeight: '500',
                  minWidth: cell.width || defaultCellWidth,
                  maxWidth: cell.width,
                }}
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: isRowClickable ? 'pointer' : undefined,
                '&:hover': isRowClickable
                  ? { backgroundColor: 'rgba(25, 118, 210, 0.12)' }
                  : undefined,
              }}
            >
              {row.cells.map((cell, index) => {
                let onCellClickHandler: MouseEventHandler | undefined =
                  undefined;
                if (onCellClicked) {
                  onCellClickHandler = (e) => {
                    onCellClicked(cell.value, index, row.value);
                  };
                }
                if (tableHead[index].component) {
                  const CustomComponent = tableHead[index].component;
                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      key={index}
                      align="center"
                      sx={{
                        minWidth: tableHead[index].width || defaultCellWidth,
                        maxWidth: tableHead[index].width,
                      }}
                    >
                      <CustomComponent
                        value={cell.value}
                        rowValue={row.value}
                        onClick={onCellClickHandler}
                      />
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    component="th"
                    scope="row"
                    key={index}
                    onClick={onCellClickHandler}
                    align={tableHead[index].isNumeric ? 'right' : 'left'}
                    sx={{
                      minWidth: tableHead[index].width || defaultCellWidth,
                      maxWidth: tableHead[index].width,
                      textOverflow: tableHead[index].singleLine
                        ? 'ellipsis'
                        : undefined,
                      whiteSpace: tableHead[index].singleLine
                        ? 'nowrap'
                        : undefined,
                      overflow: tableHead[index].singleLine
                        ? 'hidden'
                        : undefined,
                    }}
                  >
                    <Tooltip title={cell.label} placement="top-start">
                      <span>{cell.label}</span>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SimpleTable;
