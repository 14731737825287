export enum UserRoleData {
  HUB_MANAGER = 'HUB_MANAGER',
  AGENT = 'AGENT',
  LOGISTICS_ADMIN = 'LOGISTICS_ADMIN',
  FINANCE_ADMIN = 'FINANCE_ADMIN',
}

export function getUserRoleFromString(
  userRoleString: string | null
): UserRoleData {
  switch (userRoleString) {
    case UserRoleData.AGENT.toString():
      return UserRoleData.AGENT;
    case UserRoleData.LOGISTICS_ADMIN.toString():
      return UserRoleData.LOGISTICS_ADMIN;
    case UserRoleData.FINANCE_ADMIN.toString():
      return UserRoleData.FINANCE_ADMIN;
    default:
      return UserRoleData.HUB_MANAGER;
  }
}
