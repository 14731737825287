/**
 * @param {number} value - This is containing paisa
 * @returns {number} - converted amount from paisa to rupee
 */
export const convertPaisaToRupee = (value: number | undefined) => {
  if (value == null) {
    return '';
  }
  value = value / 100;
  return `₹${value.toLocaleString(`en-IN`, {
    minimumFractionDigits: 2,
  })}`;
};

/**
 * @param {number} value - This is containing paisa
 * @returns {number} - converted amount from paisa to rupee
 */
export const formatRupee = (value: number | undefined) => {
  if (value == null) {
    return '';
  }
  return `₹${value.toLocaleString(`en-IN`, {
    minimumFractionDigits: 2,
  })}`;
};
