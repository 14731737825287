import { GridColDef } from '@mui/x-data-grid';
import { ReporteeListEntity } from '../../../../data/model/geolocation/reportee';
import { ViewReporteesButton } from '../ViewReportessButton';
import { ViewCheckinsButton } from '../ViewCheckinsButton';
import { UnlinkReporteeButton } from '../UnlinkReporteeButton';

export const getColumns: (
  rowModesModel: any,
  refresh: Function
) => GridColDef<ReporteeListEntity, any, any>[] = (rowModesModel, refresh) => [
  {
    field: 'reportee_info.name',
    headerName: 'Name',
    valueGetter: (v) => v.row.reportee_info.name,
    sortable: false,
    align: 'left',
    headerAlign: 'left',
    minWidth: 220,
  },
  {
    field: 'reportee_info.phone',
    headerName: 'Phone',
    valueGetter: (v) => v.row.reportee_info.phone,
    sortable: false,
    minWidth: 220,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'reportee_role',
    headerName: 'Role',
    sortable: false,
    minWidth: 220,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'action1',
    headerName: '',
    minWidth: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell(params) {
      return (
        <ViewReporteesButton
          managerId={params.row.reportee_id}
          managerName={params.row.reportee_info?.name}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'action2',
    headerName: '',
    minWidth: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell(params) {
      return (
        <ViewCheckinsButton
          reporteeId={params.row.reportee_id}
          role={params.row.reportee_role}
          reporteeName={params.row.reportee_info?.name}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'action3',
    headerName: '',
    minWidth: 250,
    align: 'center',
    headerAlign: 'center',
    renderCell(params) {
      return (
        <UnlinkReporteeButton
          managerPhone={params.row.manager_info.phone}
          reporteePhone={params.row.reportee_info.phone}
          managerRole={params.row.manager_role}
          reporteeRole={params.row.reportee_role}
          refresh={refresh}
        />
      );
    },
    sortable: false,
  },
];
