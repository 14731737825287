import { AppThunk } from '../store';
import { SnakbarUtils } from '../../utils/snakbar-util';
import { AddressDetailEntity, SaveAddressPayload } from '../../data/model/trip/address';
import { saveAddress, updateAddress } from '../../data/api/address';

  export const addAddressAction = (
    requestBody: SaveAddressPayload
  ): AppThunk<Promise<AddressDetailEntity | undefined>> => {
    return async (dispatch, getState) => {
      try {
        const saveAddresstApiResponse = await saveAddress(requestBody);
        SnakbarUtils.showSuccessMessage("Address Saved Successfully");
        return saveAddresstApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    };
  };

  export const updateAddressAction = (
    requestBody: SaveAddressPayload
  ): AppThunk<Promise<AddressDetailEntity | undefined>> => {
    return async (dispatch, getState) => {
      try {
        const updateAddresstApiResponse = await updateAddress(requestBody);
        SnakbarUtils.showSuccessMessage("Address Updated Successfully");
        return updateAddresstApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    };
  };

