import { Chip } from '@mui/material';
import { IFilterValue } from '../../../data/model/interfaces';

export const CustomSelectFilter = ({
  label,
  oldValue,
  updatedValue,
  setValue,
}: {
  label: string;
  oldValue: IFilterValue | null;
  updatedValue: string;
  setValue: (value: IFilterValue | null) => void;
}) => {

  const openDialog = () => {
    setValue({
      label: label,
      value: updatedValue,
    });
  };

  return (
    <>
      <Chip
        variant="outlined"
        onClick={ oldValue?.value !== updatedValue ? openDialog : undefined}
        color={oldValue?.value === updatedValue ? 'primary' : undefined}
        label={label}
        sx={{
          mb: 0.5,
          borderBottom: `3px solid ${oldValue?.value === updatedValue ? 'blue' : undefined}`,
          fontSize: '15px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          padding: '5px',
          backgroundColor: 'rgb(246, 245, 245)',
        }}
      />
    </>
  );
};
