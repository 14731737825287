// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { getMenuItems } from '../../../../menu-items';
import { useAppSelector } from '../../../../redux/hooks';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const userRole = useAppSelector((state) => state.sharedPref.selectedUserRole);

  let menuItems = getMenuItems(userRole);
  const navItems = menuItems.map((item: any) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
