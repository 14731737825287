export type ITableHead<R> = IHeadCell<R>[];

export interface IHeadCell<R> {
  label: string;
  isNumeric?: boolean;
  component?: any;
  width?: number;
  singleLine?: boolean;
}

export interface ITableRow<R> {
  id: any;
  value: R | string;
  cells: ITableCell[];
}

export interface ITableCell {
  label: string;
  value?: any;
}

export const defaultCellWidth = 100;
