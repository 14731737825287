import { useEffect } from 'react';
import './ConsoleApp.css';
import Routes from './routes';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { loadSharedPrefData as loadSharefPrefData } from './redux/shared-pref/thunk-actions';
import useQueryParams from './utils/queryparam-hook';
import { authenticationStatusSelector } from './redux/login/selectors';
import { AuthenticationStatus } from './redux/login/type';
import {
  loginFromCpConsoleThunkAction,
  loginFromSharedPref,
} from './redux/login/thunk-actions';

function ConsoleApp() {
  const dispatch = useAppDispatch();
  const { authToken } = useQueryParams();
  const authenticationStatus = useAppSelector(authenticationStatusSelector);
  useEffect(() => {
    if (authToken) {
      dispatch(loginFromCpConsoleThunkAction(authToken));
    } else {
      dispatch(loadSharefPrefData());
      dispatch(loginFromSharedPref());
    }
  }, [authToken]);
  if (authenticationStatus === AuthenticationStatus.LOADING) {
    return <div>Loading....</div>;
  }
  return <Routes />;
}

export default ConsoleApp;