import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IFilterValue, LoadingStatus } from '../../data/model/interfaces';
import { IListStateFull, ListSateKeysType } from './type';

const setListResponse: CaseReducer<
  IListStateFull,
  PayloadAction<{ listItems: string[]; count: number; key: ListSateKeysType }>
> = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: state[action.payload.key]?.loadingStatus ?? LoadingStatus.IDLE,
      pageNumber: state[action.payload.key]?.pageNumber ?? 1,
      count: action.payload.count,
      listItems: [
        ...(state[action.payload.key]?.listItems ?? []),
        ...action.payload.listItems.filter((id) => !(state[action.payload.key]?.listItems ?? []).includes(id)),
      ],
      pageSize:
        action.payload.listItems.length > (state[action.payload.key]?.pageSize ?? 0)
          ? action.payload.listItems.length
          : state[action.payload.key]?.pageSize ?? 0,
      filters: state[action.payload.key]?.filters ?? [],
    },
  };
};

const setLoadingStatus: CaseReducer<
  IListStateFull,
  PayloadAction<{ loadingStatus: LoadingStatus; key: ListSateKeysType }>
> = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: action.payload.loadingStatus,
      pageNumber: state[action.payload.key]?.pageNumber ?? 1,
      count: state[action.payload.key]?.count ?? 0,
      listItems: state[action.payload.key]?.listItems ?? [],
      pageSize: state[action.payload.key]?.pageSize ?? 0,
      filters: state[action.payload.key]?.filters ?? [],
    },
  };
};

const setPageNumber: CaseReducer<IListStateFull, PayloadAction<{ pageNumber: number; key: ListSateKeysType }>> = (
  state,
  action
) => {
  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: state[action.payload.key]?.loadingStatus ?? LoadingStatus.IDLE,
      pageNumber: action.payload.pageNumber,
      count: state[action.payload.key]?.count ?? 0,
      listItems: state[action.payload.key]?.listItems ?? [],
      pageSize: state[action.payload.key]?.pageSize ?? 0,
      filters: state[action.payload.key]?.filters ?? [],
    },
  };
};

const addCreatedItem: CaseReducer<IListStateFull, PayloadAction<{ createdItemId: string; key: ListSateKeysType }>> = (
  state,
  action
) => {
  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: state[action.payload.key]?.loadingStatus ?? LoadingStatus.IDLE,
      pageNumber: state[action.payload.key]?.pageNumber ?? 1,
      count: (state[action.payload.key]?.count ?? 0) + 1,
      listItems: [action.payload.createdItemId, ...(state[action.payload.key]?.listItems ?? [])],
      pageSize: state[action.payload.key]?.pageSize ?? 0,
      filters: state[action.payload.key]?.filters ?? [],
    },
  };
};

const removeDeletedItem: CaseReducer<
  IListStateFull,
  PayloadAction<{ removedItemId: string; key: ListSateKeysType }>
> = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: state[action.payload.key]?.loadingStatus ?? LoadingStatus.IDLE,
      pageNumber: state[action.payload.key]?.pageNumber ?? 1,
      count: (state[action.payload.key]?.count ?? 1) - 1,
      listItems: state[action.payload.key]?.listItems.filter((item) => item !== action.payload.removedItemId),
      pageSize:
        state[action.payload.key]?.count === state[action.payload.key]?.pageSize
          ? (state[action.payload.key]?.pageSize ?? 1) - 1
          : state[action.payload.key]?.pageSize ?? 0,
      filters: state[action.payload.key]?.filters ?? [],
    },
  };
};

const resetListState: CaseReducer<IListStateFull, PayloadAction<ListSateKeysType>> = (state, action) => {
  return {
    ...state,
    [action.payload]: {
      loadingStatus: LoadingStatus.IDLE,
      listItems: [],
      pageNumber: 1,
      count: 0,
      pageSize: 0,
      filters: state[action.payload]?.filters ?? [],
    },
  };
};

const setFilterValue: CaseReducer<
  IListStateFull,
  PayloadAction<{
    filterValue: IFilterValue | IFilterValue[];
    filterKey: string;
    key: ListSateKeysType;
  }>
> = (state, action) => {
  const filters = state[action.payload.key]?.filters ?? [];
  const updatedFilters = filters.filter((filter) => filter.filterKey !== action.payload.filterKey);
  updatedFilters.push({
    filterKey: action.payload.filterKey,
    filterValue: action.payload.filterValue,
  });

  return {
    ...state,
    [action.payload.key]: {
      loadingStatus: state[action.payload.key]?.loadingStatus ?? LoadingStatus.IDLE,
      pageNumber: state[action.payload.key]?.pageNumber ?? 1,
      count: state[action.payload.key]?.count ?? 0,
      listItems: state[action.payload.key]?.listItems ?? [],
      pageSize: state[action.payload.key]?.pageSize ?? 0,
      filters: updatedFilters,
    },
  };
};

export const activityListCaseReducers = {
  setListResponse,
  setLoadingStatus,
  setPageNumber,
  resetListState,
  addCreatedItem,
  setFilterValue,
  removeDeletedItem,
};
