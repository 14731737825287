import {
  LogisticsPartner,
  LogisticsPartnerFilter,
  TripLogisticsPartnerFilterLabel,
  TripStatus,
  TripStatusFilter,
  TripStatusFilterLabel,
} from '../../../../../../constants/trips/trip-list';
import { getPaymentStats } from '../../../../../../data/api/cashDeposit';
import { getTripList } from '../../../../../../data/api/trip';
import { PaymentStatsInfo } from '../../../../../../data/model/cash-deposit/cashDeposit';
import { IFilter, ListResponse } from '../../../../../../data/model/interfaces';
import { TripListEntity } from '../../../../../../data/model/trip/trip';
import { store } from '../../../../../../redux/store';
import {
  customComponentTypes,
  validatorTypes,
  Schema,
} from '../../../../../../reusable-components/data-driven-forms';
import { DisplayUtils } from '../../../../../../utils/display-util';
import { getQueryParams } from '../../../../../../utils/qurey-param-util';

const schema = {
  fields: [
    {
      label: 'Date deposited',
      name: 'deposit_date_time',
      component: customComponentTypes.DATE_PICKER,
      isRequired: true,
      condition: {
        when: 'deposit_id',
        isEmpty: true,
        then: {
          visible: false,
        },
        else: { visible: true },
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Select trip',
      name: 'trip_ids',
      component: customComponentTypes.CUSTOM_SEARCHABLE_SELECT_FIELD,
      isMulti: true,
      FormFieldGridProps: { xs: 6 },
      loadOptions: (currentSearchValue: string) =>
        new Promise((resolve, reject) => {
          const { selectedHubId } = store.getState().sharedPref;
          const filterValue: IFilter[] = [
            {
              filterKey: TripStatusFilter.FILTER_KEY,
              filterValue: {
                label: TripStatusFilterLabel.COMPLETED,
                value: TripStatus.COMPLETED,
              },
            },
            {
              filterKey: LogisticsPartnerFilter.FILTER_KEY,
              filterValue: {
                label: TripLogisticsPartnerFilterLabel.ONE_BRIDGE,
                value: LogisticsPartner.ONE_BRIDGE,
              },
            },
          ];
          getTripList({
            searchString: currentSearchValue,
            hubId: selectedHubId!,
            depositCreated: false,
            queryParams: getQueryParams(filterValue),
          })
            .then((response: ListResponse<TripListEntity>) => {
              resolve(
                response.results.map((item: TripListEntity) => ({
                  label: `ID: ${item.trip_id}`,
                  value: item.trip_id,
                }))
              );
            })
            .catch(reject);
        }),
      simpleValue: true,
      condition: {
        when: 'deposit_id',
        isNotEmpty: false,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      component: customComponentTypes.CUSTOM_CHANGE_VALUE_FIELD,
      name: 'listener',
      hideField: true,
    },
    {
      name: 'amount',
      component: customComponentTypes.CUSTOM_PLAIN_TEXT,
      loadOptions: (formValues: any) =>
        new Promise((resolve, reject) => {
          const { selectedHubId } = store.getState().sharedPref;
          const tripIds = formValues.trip_ids.map((trip: any) => trip.value);
          getPaymentStats(selectedHubId!, tripIds)
            .then((response: PaymentStatsInfo) => {
              resolve(response.handover_amount);
            })
            .catch(reject);
        }),
      formatter: DisplayUtils.amountDepositString,
      condition: {
        and: [
          {
            when: 'fetch_handover_amount',
            is: true,
          },
          {
            when: 'trip_ids',
            isNotEmpty: true,
          },
        ],
      },
      simpleValue: true,
    },
    {
      label: 'Bank Transaction ID',
      name: 'transaction_id',
      component: customComponentTypes.TEXT_FIELD,
      condition: {
        when: 'deposit_id',
        isNotEmpty: true,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
    },
    {
      label: 'Remarks/Note',
      name: 'notes',
      component: customComponentTypes.TEXT_FIELD,
    },
    {
      label: 'Transaction Images',
      name: 'transaction_images',
      component: customComponentTypes.FIELD_ARRAY,
      initialValue: [null],
      minItems: 1,
      maxItems: 2,
      condition: {
        when: 'deposit_id',
        isNotEmpty: true,
      },
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
        },
      ],
      fields: [
        {
          label: 'Attach receipt screenshot',
          component: customComponentTypes.CUSTOM_IMAGE_UPLOAD_FIELD,
        },
      ],
    },
  ],
} as const;

export type CreateDepositFormValues = {
  [K in (typeof schema.fields)[number]['name']]: any;
};

export default schema as any as Schema;
