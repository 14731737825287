import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MouseEvent, useState } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { useAppDispatch } from '../../../../../redux/hooks';
import { dialogTransition } from '../../../../../utils/transitions';
import { SnakbarUtils } from '../../../../../utils/snakbar-util';
import {
  TripStatus,
} from '../../../../../constants/trips/trip-list';
import { TripDetailEntity } from '../../../../../data/model/trip/trip';
import { updatedTripStatusAction } from '../../../../../redux/trip-detail/thunk-actions';
import {
  ConfirmActionContent,
  ShipmentNotCompleteDialogBox,
  TripCloseDialogBox,
} from '../TripCloseDialogBox';
import { tripListThunkActions } from '../../../../../redux/trip-list/thunk-actions';

const StartAndStopTripButton = ({
  tripStatus,
  tripId,
  assignedDa,
  amountToCollect = 0,
  logisticsPartner,
  refresh,
}: {
  tripStatus: string;
  tripId: GridRowId;
  assignedDa: string;
  amountToCollect?: number;
  logisticsPartner: string;
  refresh: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [shipmentIncompleteError, setShipmentIncompleteError] = useState(false);
  const [isAmountReceived, setIsAmountReceived] = useState(false);

  const handleSubmit = async (e: MouseEvent) => {
    setIsSubmitting(true);
    let updatedStatus: string =
      tripStatus === TripStatus.CREATED
        ? TripStatus.STARTED
        : TripStatus.COMPLETED;

    let response: TripDetailEntity | undefined | boolean = await dispatch(
      updatedTripStatusAction({
        tripId: tripId.toString(),
        status: updatedStatus,
      })
    );

    if (response === true) {
      setShipmentIncompleteError(true);
    } else if (response) {
      SnakbarUtils.showSuccessMessage('Updated successfully');
      dispatch(tripListThunkActions.fetchList(true));
      closeDialog();
      if ((response as TripDetailEntity).status === TripStatus.COMPLETED) {
        refresh();
      }
    }
    setIsSubmitting(false);
    e.stopPropagation();
  };

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    if (shipmentIncompleteError) {
      setShipmentIncompleteError(false);
    }
    setIsAmountReceived(false);
    setIsSubmitting(false);
    setOpen(false);
  };

  const submitButtonDisabled =
    !isAmountReceived &&
    tripStatus === TripStatus.STARTED &&
    !(amountToCollect === 0);

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        size="medium"
        onClick={openDialog}
        disabled={tripStatus === TripStatus.COMPLETED}
        color={tripStatus === TripStatus.CREATED ? 'primary' : 'error'}
      >
        {tripStatus === TripStatus.CREATED
          ? 'START'
          : tripStatus === TripStatus.STARTED
          ? 'END'
          : 'COMPLETE'}
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        TransitionComponent={dialogTransition}
        scroll="body"
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 400,
            backgroundColor: shipmentIncompleteError
              ? 'rgba(255, 0, 0, 0.2)'
              : undefined,
            border: shipmentIncompleteError
              ? '1px solid rgba(255, 0, 0, 1)'
              : undefined,
          }}
        >
          {!shipmentIncompleteError && (
            <>
              {tripStatus === TripStatus.CREATED || amountToCollect === 0 ? (
                <ConfirmActionContent
                  text={tripStatus === TripStatus.CREATED ? 'start' : 'end'}
                  closeDialog={closeDialog}
                />
              ) : (
                <TripCloseDialogBox
                  amountToCollect={amountToCollect}
                  assignedDa={assignedDa}
                  closeDialog={closeDialog}
                  cashReceived={(value) => {
                    setIsAmountReceived(value);
                  }}
                />
              )}
            </>
          )}

          {shipmentIncompleteError && (
            <ShipmentNotCompleteDialogBox
              assignedDa={assignedDa}
              closeDialog={closeDialog}
            />
          )}
        </DialogContent>
        {!shipmentIncompleteError ? (
          <Box>
            <DialogActions>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit}
                variant="contained"
                disabled={submitButtonDisabled}
                color={tripStatus === TripStatus.CREATED ? 'primary' : 'error'}
              >
                {tripStatus === TripStatus.CREATED ? 'Yes, Start' : 'Yes, End'}
              </LoadingButton>
              <Box sx={{ width: '8px' }}></Box>
            </DialogActions>
            <Box sx={{ height: '12px' }}></Box>
          </Box>
        ) : undefined}
      </Dialog>
    </>
  );
};

export default StartAndStopTripButton;
