import {
  DataGrid,
  GridPaginationModel,
  GridRowModesModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { CashDepositsListEntity } from '../../../../data/model/cash-deposit/cashDeposit';
import { cashDepositsListSelectors } from '../../../../redux/cash-deposit-list/selectors';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import PageContainer from '../../../../reusable-components/PageContainer';
import { getColumns } from './cash-deposit-history-table-data';
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../data/model/interfaces';
import ListDataGridToolbar from '../../../../reusable-components/data-grid/ListDataGridToolbar';
import { cashDepositsListThunkActions } from '../../../../redux/cash-deposit-list/thunk-actions';
import AddNewDepositButton from './AddNewDepositButton';

function CashDepositHistoryList() {
  const cashDepositsList = useAppSelector(
    cashDepositsListSelectors.selectPageItems
  );
  const cashDepositsListLoadingStatus = useAppSelector(
    cashDepositsListSelectors.selectLoadingStatus
  );
  const cashDepositsCount = useAppSelector(
    cashDepositsListSelectors.selectCount
  );
  const cashDepositsPageSize = useAppSelector(
    cashDepositsListSelectors.selectPageSize
  );
  const cashDepositsPageNumber = useAppSelector(
    cashDepositsListSelectors.selectPageNumber
  );

  const { selectedHubId } = useAppSelector((state) => state.sharedPref);

  const dispatch = useAppDispatch();

  const loginProfile = useAppSelector(
    (state) => state.sharedPref.authData
  )?.profile;

  const title = 'Cash Deposit History';

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  useEffect(() => {
    dispatch(cashDepositsListThunkActions.fetchList());
  }, [dispatch, selectedHubId]);

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > cashDepositsPageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < cashDepositsPageNumber - 1) {
      fetchPreviousPage();
    }
  };

  const refresh = () => {
    dispatch(cashDepositsListThunkActions.fetchList(true));
  };

  const reloadCurrentPage = () => {
    dispatch(cashDepositsListThunkActions.fetchList());
  };

  const fetchNextPage = () => {
    dispatch(cashDepositsListThunkActions.fetchListNextPage());
  };

  const fetchPreviousPage = () => {
    dispatch(cashDepositsListThunkActions.fetchListPreviousPage());
  };

  return (
    <PageContainer dynamicHeight={true}>
      <DataGrid<CashDepositsListEntity>
        columns={getColumns(rowModesModel)}
        rowSelectionModel={rowSelectionModel}
        rows={cashDepositsList}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        editMode='row'
        getRowId={(row) => row.deposit_id}
        pagination
        paginationModel={{
          page: cashDepositsPageNumber - 1,
          pageSize: cashDepositsPageSize,
        }}
        pageSizeOptions={[]}
        rowCount={cashDepositsCount}
        paginationMode='server'
        loading={cashDepositsListLoadingStatus === LoadingStatus.LOADING}
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          toolbar: ListDataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            title,
            refresh,
            suffixRefreshButton: loginProfile?.HUB_MANAGER ? (
              <AddNewDepositButton onSubmit={refresh} />
            ) : undefined,
          },
        }}
        rowSelection={false}
        disableColumnFilter
        hideFooter={false}
        columnVisibilityModel={
          loginProfile?.HUB_MANAGER
            ? {
                action: true,
              }
            : {
                action: false,
              }
        }
      />
    </PageContainer>
  );
}

export default CashDepositHistoryList;
