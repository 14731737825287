import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './modules/main-routes';
import AuthenticationRoutes from './modules/authentication-routes';
import UserRoutes from './modules/user-routes';
import TripRoutes from './modules/trip-routes';
import VehicleRoutes from './modules/vehicle-routes';
import AdminRoutes from './modules/admin-routes';
import ServiceAbilityRoutes from './modules/serviceAbility-routes';
import DeliveryAgentRoutes from './modules/delivery-agent-routes';
import CashManagementRoutes from './modules/cash-management-routes';
import EmployeeManagementRoutes from './modules/empolyee-management';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    VehicleRoutes,
    UserRoutes,
    TripRoutes,
    AdminRoutes,
    ServiceAbilityRoutes,
    DeliveryAgentRoutes,
    CashManagementRoutes,
    EmployeeManagementRoutes
  ]);
}
