import { createSlice } from '@reduxjs/toolkit';
import { EntityState } from './type';
import { clearAction } from '../login/reducer';
import { listCaseReducers } from './actions';

const initialState: EntityState = {
  tripList: {},
  shipmentList: {},
  unAssignedShipmentList: {},
  shipmentDetail: {},
  tripDetail: {},
  tripShipmentList: {},
  shipmentTrailList: {},
  tripShipmentDetail: {},
  pincodeList: {},
  deliveryAgentList: {},
  cashDepositsList: {},
  cashDepositDetail: {},
  reporteeList: {},
};

const slice = createSlice({
  name: 'entityState',
  initialState,
  reducers: {
    ...listCaseReducers,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, (state) => {
      return initialState;
    });
  },
});

export const entityActions = slice.actions;

export default slice.reducer;
