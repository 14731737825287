import { Button, Menu, MenuItem } from '@mui/material';
import { useState, MouseEvent } from 'react';
import DropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useNavigate } from 'react-router-dom';

export const CreateShipmentButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreate = () => {
    navigate('/shipments/create');
    handleClose();
  };

  const handleUpload = () => {
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={handleClick}
        endIcon={<DropDownOutlinedIcon />}
      >
        CREATE A SHIPMENT
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCreate}>Create Shipment</MenuItem>
        <MenuItem onClick={handleUpload}>Upload Shipment</MenuItem>
      </Menu>
    </>
  );
};
