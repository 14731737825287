import { MenuItem } from './type';

export const cashManagementMenuItems: {
  [key: string]: MenuItem;
} = {
  cashDepositDetail: {
    id: 'cashDeposit',
    title: 'Cash Deposit',
    type: 'item',
    url: '/payment/deposits',
    breadcrumbs: false,
  },
};

const cashManagement: MenuItem = {
  id: 'cashManagement',
  title: 'Cash Management',
  type: 'group',
  children: [cashManagementMenuItems.cashDepositDetail],
};

export default cashManagement;
