import { GridColDef } from '@mui/x-data-grid';
import { DisplayUtils } from '../../../../../utils/display-util';
import { DateUtils } from '../../../../../utils/date-util';
import { ShipmentTrailListEntity } from '../../../../../data/model/trip/shipment';
import { TripIdButton } from './TripIdButton';

export const getColumns: (
  rowModesModel: any
) => GridColDef<ShipmentTrailListEntity, any, any>[] = (rowModesModel) => [
  {
    field: 'trip_id',
    headerName: 'Trip ID',
    type: 'string',
    minWidth: 120,
    align: 'left',
    headerAlign: 'left',
    renderCell(params) {
      return <TripIdButton tripId={params.row.trip?.trip_id ?? undefined} />;
    },
    editable: false,
    sortable: false,
  },
  {
    field: 'trip_created',
    headerName: 'Trip Created Date',
    type: 'string',
    minWidth: 200,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return params.value
        ? DateUtils.getFormattedDateString(params.value)
        : '--';
    },
    valueGetter(params) {
      return params.row.trip ? params.row.trip.created : undefined;
    },
  },
  {
    field: 'hub_address',
    headerName: 'Description',
    type: 'string',
    minWidth: 400,
    align: 'left',
    headerAlign: 'left',
    editable: false,
    sortable: false,
    valueFormatter(params) {
      return DisplayUtils.getShipmentTrailText({
        hubName: params.value.hub?.address_name ?? '',
        status: params.value.status,
        createdTime: params.value.created,
      });
    },
    valueGetter(params) {
      return params.row;
    },
  },
];
