import { Typography } from '@mui/material';
import { DisplayUtils } from '../../../../../utils/display-util';
import { CashDepositStatus } from '../../../../../constants/cashDeposit/cashDeposit';

function CashDepositStatusTag({
  cashDepositStatus,
}: {
  cashDepositStatus: string;
}) {
  return (
    <Typography
      variant="subtitle2"
      component="div"
      style={{
        color:
          cashDepositStatus === CashDepositStatus.CREATED
            ? 'rgba(241,196,0)'
            : cashDepositStatus === CashDepositStatus.DRAFTED
            ? 'rgba(255, 0, 0)'
            : 'rgba(1, 157, 89, 0.7)',
        display: 'inline-block',
        padding: '4px',
      }}
    >
      {DisplayUtils.cashDepositStatusString(cashDepositStatus)}
    </Typography>
  );
}

export default CashDepositStatusTag;
