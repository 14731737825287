import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { CircularProgress, TextField, debounce } from '@mui/material';
import { FormFieldGrid } from '@data-driven-forms/mui-component-mapper';
import { useFieldApi, useFormApi } from '..';
import { AutocompleteOptionType } from './type';

export default function CustomSearchableSelectField(props: any) {
  const { input, label, isRequired, loadOptions, isMulti } =
    useFieldApi<AutocompleteOptionType>(props);

  const formOptions = useFormApi();
  const meta = useFormApi();
  const [open, setOpen] = React.useState(false);
  const [fetchingData, setFethingData] = React.useState(false);

  const isLoading = open && fetchingData;

  const [inputValue, setInputValue] = React.useState('');
  const [latestValue, setLatestValue] = React.useState<
    AutocompleteOptionType | AutocompleteOptionType[]
  >([]);

  const [options, setOptions] = React.useState<AutocompleteOptionType[]>([]);

  const fetchOptions = React.useMemo(
    () =>
      debounce(
        (
          inputValue: string,
          onSucess: (value: any) => any,
          onError: (reason: any) => any
        ) => {
          if (loadOptions) {
            loadOptions(
              inputValue,
              formOptions.getState().values,
              meta.initialValues
            )
              .then(onSucess)
              .catch(onError);
          }
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (fetchingData) {
      fetchOptions(
        inputValue,
        (results: AutocompleteOptionType[]) => {
          if (active) {
            setFethingData(false);
            setOptions(results);
          }
        },
        (e) => {
          if (active) {
            setFethingData(false);
          }
        }
      );
    }

    return () => {
      active = false;
    };
  }, [input?.value, inputValue, fetchingData]);

  return (
    <FormFieldGrid>
      <Autocomplete
        options={options}
        autoComplete
        getOptionLabel={(option) => {
          return option.label ?? '';
        }}
        multiple={isMulti}
        value={isMulti ? latestValue : input?.value}
        noOptionsText={isLoading ? '...Please wait' : 'No Options'}
        onOpen={() => {
          setOpen(true);
          setFethingData(true);
        }}
        onClose={() => {
          setFethingData(false);
          setOpen(false);
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setLatestValue(newValue);
          } else {
            setInputValue('');
          }
          input.onChange(newValue);
          setFethingData(true);
        }}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length > 0) {
            setInputValue(newInputValue);
            setFethingData(true);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.label, matches);

          return (
            <li {...props} key={JSON.stringify(option.value)}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </FormFieldGrid>
  );
}
