import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  DetailItemVerticalDirection,
  DetailItem,
  CustomDetailItemVerticalDirection,
  CustomDetailItem,
} from '../../../../reusable-components/DetailItem';
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../../data/model/interfaces';
import { PageLoadingFailed } from '../../../../reusable-components/PageLoadingFailed';
import { PageLoading } from '../../../../reusable-components/PageLoading';
import {
  fetchTripDetailAction,
  getPaymentHandoverDetailsAction,
  getTripPaymentDetailsAction,
} from '../../../../redux/trip-detail/thunk-actions';
import { convertPaisaToRupee } from '../../../../utils/currenty-util';
import { DisplayUtils } from '../../../../utils/display-util';
import {
  PaymentInfo,
  TripHandoverDetail,
} from '../../../../data/model/trip/trip';
import {
  LogisticsPartner,
  TripStatus,
} from '../../../../constants/trips/trip-list';
import TripShipmentListPage from './TripShipmentRelationsPage';
import StartAndStopTripButton from '../TripList/StartAndStopTripButton';
import TripStatusTag from './TripStatusTag';
import EditTripButon from './EditTripButton';
import { DateUtils } from '../../../../utils/date-util';
import { tripDetailSelector } from '../../../../redux/trip-detail/selectors';
import { tripShipmentListSelectors } from '../../../../redux/trip-shipment-relation-list/selectors';
import { join } from 'path';

function TripDetailPage() {
  const { tripId } = useParams();
  const tripDetail = useAppSelector((state) =>
    tripDetailSelector(state, tripId!)
  );
  const tripShipmentCount = useAppSelector(
    tripShipmentListSelectors.selectCount
  );
  const [tripDetailLoadingStatus, setTripDetailLoadingStatus] = useState(
    LoadingStatus.IDLE
  );
  const [paymentData, setPaymentData] = useState<PaymentInfo>();

  const [handoverData, setHandoverData] = useState<TripHandoverDetail>();

  const dispatch = useAppDispatch();

  const getTripDetail = async (tripId: string, forceReload = false) => {
    if (tripDetailLoadingStatus === LoadingStatus.LOADING) {
      return;
    }
    setTripDetailLoadingStatus(LoadingStatus.LOADING);
    let response = await dispatch(fetchTripDetailAction(tripId, forceReload));
    if (response?.status === TripStatus.COMPLETED) {
      let handoverData = await dispatch(
        getPaymentHandoverDetailsAction(tripId)
      );
      setHandoverData(handoverData);
    } else {
      let paymentResponse = await dispatch(getTripPaymentDetailsAction(tripId));
      setPaymentData(paymentResponse);
    }
    if (response) {
      setTripDetailLoadingStatus(LoadingStatus.IDLE);
    } else {
      setTripDetailLoadingStatus(LoadingStatus.FAILED);
    }
  };

  useEffect(() => {
    getTripDetail(tripId!);
  }, [dispatch, tripId]);

  if (tripDetailLoadingStatus === LoadingStatus.LOADING) return <PageLoading />;

  if (tripDetailLoadingStatus === LoadingStatus.FAILED) {
    return (
      <PageLoadingFailed
        onReload={() => {
          getTripDetail(tripId!, true);
        }}
      />
    );
  }

  if (!tripDetail) {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6">Unable to fetch details.</Typography>
        </Box>
      </>
    );
  }

  const driverName =
    tripDetail?.logistics_partner === LogisticsPartner.ONE_BRIDGE
      ? tripDetail?.delivery_agent_info?.name
      : tripDetail?.client_local_details?.driver_name;

  const logisticsPartner = DisplayUtils.getLogisticsPartnerName({
    logisticsPartner: tripDetail?.logistics_partner,
    clientTPLDetails: tripDetail?.client_tpl_details,
  });

  const totalTripCost =
    (tripDetail?.trip_cost?.client_cost ?? 0) +
    (tripDetail?.trip_cost?.fuel_cost ?? 0) +
    (tripDetail?.trip_cost?.vehicle_rent ?? 0);

  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <div style={{ display: 'flex' }}>
        <Typography variant="h6">Trip Details</Typography>
        <Box sx={{ flexGrow: 1 }} />
        {tripDetail.status === TripStatus.CREATED ? (
          <>
            <EditTripButon tripId={tripId!} tripDetails={tripDetail} />
            <Box sx={{ width: '16px' }} />
          </>
        ) : undefined}
        {tripDetail.status !== TripStatus.COMPLETED ? (
          <StartAndStopTripButton
            tripId={tripId!}
            tripStatus={tripDetail.status}
            assignedDa={driverName}
            amountToCollect={paymentData?.handover_to_collect}
            logisticsPartner={tripDetail.logistics_partner}
            refresh={async () => {
              let handoverData = await dispatch(
                getPaymentHandoverDetailsAction(tripDetail.trip_id.toString())
              );
              if (handoverData) {
                setPaymentData(undefined);
                setHandoverData(handoverData);
              }
            }}
          />
        ) : undefined}
      </div>

      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" component="div">
            {`Trip ID: ${tripDetail.trip_id.toString()}`}
          </Typography>
          <br></br>
          <Grid container>
            <Grid item xs={6}>
              <DetailItemVerticalDirection
                label="Logistics Partner: "
                value={logisticsPartner}
              />
              <br></br>
              <DetailItemVerticalDirection
                label="Trip Created Time: "
                value={
                  tripDetail.created
                    ? DateUtils.getFormattedDateString(tripDetail.created)
                    : ''
                }
              />
              <br></br>
              {tripDetail.logistics_partner === LogisticsPartner.TPL ? (
                <>
                  <DetailItemVerticalDirection
                    label="Trip Estimated Delivey Time: "
                    value={
                      tripDetail.scheduled_end_time
                        ? DateUtils.getFormattedDateString(
                            tripDetail.scheduled_end_time
                          )
                        : ''
                    }
                  />
                  <br></br>
                </>
              ) : undefined}
              {tripDetail.logistics_partner !== LogisticsPartner.TPL ? (
                <>
                  <DetailItemVerticalDirection
                    label="Trip Estimated Start Time: "
                    value={
                      tripDetail.scheduled_start_time
                        ? DateUtils.getFormattedDateString(
                            tripDetail.scheduled_start_time
                          )
                        : ''
                    }
                  />
                </>
              ) : undefined}
              <br></br>
              <DetailItemVerticalDirection
                label="Trip Actual Start Time: "
                value={
                  tripDetail.actual_start_time
                    ? DateUtils.getFormattedDateString(
                        tripDetail.actual_start_time
                      )
                    : ''
                }
              />
              <br></br>
              {tripDetail.logistics_partner === LogisticsPartner.TPL ? (
                <>
                  <DetailItemVerticalDirection
                    label="Tracking ID: "
                    value={tripDetail.client_tpl_details?.tracking_number}
                  />
                  <br></br>
                </>
              ) : undefined}
              {tripDetail.status === TripStatus.COMPLETED ? (
                <>
                  <DetailItemVerticalDirection
                    label="Trip End Time: "
                    value={
                      tripDetail.actual_end_time
                        ? DateUtils.getFormattedDateString(
                            tripDetail.actual_end_time
                          )
                        : ''
                    }
                  />
                  <br></br>
                </>
              ) : undefined}
            </Grid>
            <Grid item xs={6}>
              <CustomDetailItemVerticalDirection
                labelComponent={
                  <Typography variant="body2" component="div">
                    Trip Status:
                  </Typography>
                }
                valueComponent={
                  <TripStatusTag tripStatus={tripDetail.status} />
                }
              />
              <br></br>
              <DetailItemVerticalDirection
                label="Trip Cost: "
                value={convertPaisaToRupee(totalTripCost)}
              />
              <br></br>
              {tripDetail.status === TripStatus.COMPLETED ? (
                <DetailItemVerticalDirection
                  label="Trip Duration: "
                  value={tripDetail.trip_duration}
                />
              ) : undefined}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Typography variant="h6" component="div">
        Shipment Details
      </Typography>
      <br></br>
      <Card variant="outlined">
        <CardContent>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <DetailItem
              label="Total No. of Shipments: "
              value={tripShipmentCount.toString()}
            />
            <DetailItem
              label="Net Weight: "
              value={DisplayUtils.weightFormatter(tripDetail.net_weight ?? 0)}
            />
          </Grid>
        </CardContent>
      </Card>
      <br />
      {paymentData ? (
        <>
          <Typography variant="h6" component="div">
            Payment Details
          </Typography>
          <br></br>
          <Card variant="outlined">
            <CardContent>
              <Grid container columnSpacing={4} rowSpacing={2}>
                <DetailItem
                  label="Due Payment: "
                  value={convertPaisaToRupee(paymentData?.un_paid)}
                />
                <DetailItem
                  label="Cash Collected: "
                  value={convertPaisaToRupee(paymentData?.collected)}
                />
              </Grid>
            </CardContent>
          </Card>
          <br />
        </>
      ) : undefined}
      {handoverData ? (
        <>
          <Typography variant="h6" component="div">
            Hanover Details
          </Typography>
          <br></br>
          <Card variant="outlined">
            <CardContent>
              <Grid container columnSpacing={4} rowSpacing={2}>
                <DetailItem
                  label="Handover ID: "
                  value={handoverData?.handover_id.toString()}
                />
                <DetailItem
                  label="Handover Amount: "
                  value={convertPaisaToRupee(handoverData?.amount)}
                />
                <DetailItem
                  label="Handover On: "
                  value={DateUtils.getFormattedDateTimeString(
                    handoverData?.created
                  )}
                />
                <DetailItem
                  label="Handover for Shipment: "
                  value={handoverData?.shipments.join(', ')}
                />
                <CustomDetailItem
                  labelComponent={
                    <Typography variant="body2">
                      Payment Collected By(User ID):
                    </Typography>
                  }
                  valueComponent={
                    <Typography variant="subtitle2">
                      {handoverData?.collected_by_user_id}
                    </Typography>
                  }
                />
              </Grid>
            </CardContent>
          </Card>
          <br />
        </>
      ) : undefined}
      {tripDetail.logistics_partner !== LogisticsPartner.TPL ? (
        <>
          <Typography variant="h6" component="div">
            Vehicles and DA Details
          </Typography>
          <br></br>
          <Card variant="outlined">
            <CardContent>
              <Grid container columnSpacing={4} rowSpacing={2}>
                <DetailItem label="DA Name: " value={driverName} />
                <DetailItem
                  label="Contact Person: "
                  value={
                    tripDetail.logistics_partner === LogisticsPartner.ONE_BRIDGE
                      ? tripDetail.delivery_agent_info?.phone
                      : tripDetail.client_local_details?.driver_phone
                  }
                />
                <DetailItem label="Vehicle Number: " value="" />
                <DetailItem label="Vehicle Type: " value="" />
                <DetailItem label="Vehicle Capacity: " value="" />
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : undefined}
      <Card></Card>
      <br></br>
      <TripShipmentListPage tripId={tripId!} tripStatus={tripDetail.status} />
    </Box>
  );
}

export default TripDetailPage;
