import {
  ITableHead,
  ITableRow,
} from '../../../../reusable-components/table/type';
import { TeamplateHeaders } from '../constants';

export const tableHead: ITableHead<any> = [
  { label: '#' },
  { label: TeamplateHeaders.MANAGER_PHONE, singleLine: true, width: 250 },
  { label: TeamplateHeaders.MANAGER_ROLE, singleLine: true, width: 250 },
  { label: TeamplateHeaders.REPORTEE_NAME, singleLine: true, width: 250 },
  { label: TeamplateHeaders.REPORTEE_PHONE, singleLine: true, width: 250 },
  { label: TeamplateHeaders.REPORTEE_ROLE, singleLine: true, width: 250 },
];

export const getTableRows = (itemList: any[]): ITableRow<any>[] => {
  return itemList.reduce<ITableRow<any>[]>(
    (accumulator, currentValue, index) => {
      const row: ITableRow<any> = {
        id: index,
        value: currentValue,
        cells: [
          { label: index + 1 },
          { label: currentValue[TeamplateHeaders.MANAGER_PHONE] },
          { label: currentValue[TeamplateHeaders.MANAGER_ROLE] },
          { label: currentValue[TeamplateHeaders.REPORTEE_NAME] },
          { label: currentValue[TeamplateHeaders.REPORTEE_PHONE] },
          { label: currentValue[TeamplateHeaders.REPORTEE_ROLE] },
        ],
      };
      return [...accumulator, row];
    },
    []
  );
};
