import { entityActions } from '../entities/reducer';
import { AppThunk } from '../store';
import { entityStateKeys } from '../entities/constants';
import { SnakbarUtils } from '../../utils/snakbar-util';
import {
  CashDepositsDetailEntity,
  DepositHandoverDetailEntity,
} from '../../data/model/cash-deposit/cashDeposit';
import {
  cashDepositDetail,
  depositHandoverDetail,
} from '../../data/api/cashDeposit';

export const cashDepositDetailAction = ({
  depositId,
  forceReload = false,
}: {
  depositId: string;
  forceReload: boolean;
}): AppThunk<Promise<CashDepositsDetailEntity | undefined>> => {
  return async (dispatch, getState) => {
    const depositDetail = getState().entities.cashDepositDetail[depositId];
    if (!depositDetail || forceReload) {
      try {
        const cashDepositDetailApiResponse = await cashDepositDetail(depositId);
        setEntities(cashDepositDetailApiResponse, dispatch);
        return cashDepositDetailApiResponse;
      } catch (error) {
        console.error(error);
        SnakbarUtils.showAxiosErrorMessage(error);
      }
    }
    return depositDetail;
  };
};

export const fetchDepositHandoverDetailAction = (
  depositId: string
): AppThunk<Promise<DepositHandoverDetailEntity[] | undefined>> => {
  return async (_dispatch, _getState) => {
    try {
      const fetchDepositHandoverDetailApiResponse = await depositHandoverDetail(
        depositId
      );
      return fetchDepositHandoverDetailApiResponse;
    } catch (error) {
      console.error(error);
      SnakbarUtils.showAxiosErrorMessage(error);
    }
  };
};

function setEntities(detailEntity: CashDepositsDetailEntity, dispatch: any) {
  dispatch(
    entityActions.setEntity({
      entity: detailEntity,
      entityId: detailEntity.deposit_id.toString(),
      key: entityStateKeys.CASH_DEPOSIT_DETAIL,
    })
  );
}
