import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const ViewReporteesButton = ({
  managerId,
  managerName,
}: {
  managerId: string;
  managerName: string;
}) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate(`/employee-management/${managerId}/reportees`, {
      state: {
        userName: managerName,
      },
    });
  };

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        size="medium"
        onClick={handleSubmit}
      >
        View Reportees
      </Button>
    </>
  );
};
