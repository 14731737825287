import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: any) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);

  const Icon = item.icon;
  const itemIcon = () =>
    item?.icon ? (
      <Icon
        sx={{
          color: isOpen ? theme.palette.primary.dark : 'inherit',
        }}
      />
    ) : (
      <FiberManualRecordIcon
        sx={{
          width: isOpen ? 8 : 6,
          height: isOpen ? 8 : 6,
          color: isOpen ? theme.palette.primary.dark : 'inherit',
        }}
        fontSize={level > 0 ? 'inherit' : 'medium'}
      />
    );
  const itemHandler = (url: string) => {
    navigate(url);
  };

  // active menu item on page load
  useEffect(() => {
    const pathSegments = document.location.pathname.toString().split('/');
    const currentIndex = pathSegments.findIndex((id) => id === item.id);
    // Special dahboard condition
    if (item.url === document.location.pathname) {
      setIsOpen(true);
    } else if (currentIndex == pathSegments.length - 1) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [pathname]);

  return (
    <ListItemButton
      disabled={item.disabled}
      sx={{
        borderRadius: `5px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={isOpen}
      onClick={() => itemHandler(item.url)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
        {itemIcon()}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography color={isOpen ? theme.palette.primary.dark : 'inherit'}>
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
