import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import layoutReducer from './layout/reducer';
import authReducer from './login/reducer';
import sharedPrefReducer from './shared-pref/reducer';
import entitiesReducer from './entities/reducer';
import listsReducer from './lists/reducer';
// ...

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    auth: authReducer,
    sharedPref: sharedPrefReducer,
    entities: entitiesReducer,
    lists: listsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
