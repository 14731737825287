import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { SnakbarUtils } from '../../../../utils/snakbar-util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { linkPincodeAction } from '../../../../redux/pincode/thunk-actions';
import CustomDialogBox from '../../../../reusable-components/CustomDialogBox';

function LinkPincodeForm({ refresh }: { refresh: () => void }) {
  const { selectedHubName } = useAppSelector((state) => state.sharedPref);
  const dispatch = useAppDispatch();
  const [pincodes, setPincodes] = useState('');

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setIsSubmitting(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const pincodesArray = pincodes.split(',');
    const validPincode = pincodesArray.every((pincode) => {
      return pincode.length === 6;
    });
    if (validPincode) {
      await dispatch(linkPincodeAction(pincodesArray));
      refresh();
    } else {
      SnakbarUtils.showErrorMessage('Invalid Inputs');
    }
    setIsSubmitting(false);
    closeDialog();
  };
  return (
    <Box>
      <Typography variant="h6">Link Pincode</Typography>
      <Box sx={{ height: '12px' }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          placeholder="Enter Pincode"
          value={pincodes}
          sx={{ height: '45px', borderRadius: '4px', width: '350px' }}
          onChange={(event) => {
            const regex = /^[0-9,]*$/g;
            if (regex.test(event.target.value)) {
              setPincodes(event.target.value);
            }
          }}
          helperText=" Multiple pincodes can be added separated by comma, e.g. 111111,222222,333333"
        />
        <Box sx={{ width: '40px' }} />
        <Button
          id="basic-button"
          variant="contained"
          size="large"
          onClick={openDialog}
        >
          Add
        </Button>
        <CustomDialogBox
          open={open}
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          buttonText="Yes, Add"
          messageBody={`Are you sure, you want to add these pincodes to ${selectedHubName}?`}
          hasIcon
        />
      </div>
    </Box>
  );
}

export default LinkPincodeForm;
