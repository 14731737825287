import { MenuItem } from './type';

export const serviceAbilityMenuItems: {
  [key: string]: MenuItem;
} = {
  pincodesList: {
    id: 'pincodes',
    title: 'Pincodes',
    type: 'item',
    url: '/hub/pincodes',
    target: true,
  },

};

const serviceAbility: MenuItem = {
  id: 'serviceAbility',
  title: 'ServiceAbility',
  type: 'group',
  children: [serviceAbilityMenuItems.pincodesList],
};

export default serviceAbility;
