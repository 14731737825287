import { useEffect } from 'react';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';

const CustomChangeValueField = () => {
  const { getState, change } = useFormApi();

  const { trip_ids } = getState().values;

  useEffect(() => {
    if (trip_ids) {
      change('fetch_handover_amount', false);
      setTimeout(() => {
        change('fetch_handover_amount', true);
      }, 200);
    }
  }, [trip_ids]);

  return null;
};

export const CustomChangeValueFieldWrapper = () => (
  <FormSpy subscription={{ values: true }}>
    {() => <CustomChangeValueField />}
  </FormSpy>
);
