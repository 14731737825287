import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getColumns } from './delivery-agent-list-table-data';
import {
  DataGrid,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopReasons,
  GridRowModesModel,
  GridRowParams,
  MuiBaseEvent,
  MuiEvent,
} from '@mui/x-data-grid';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { deliveryAgentListSelectors } from '../../../redux/delivery-agent-list/selectors';
import { deliveryAgentListThunkActions } from '../../../redux/delivery-agent-list/thunk-actions';
import { DeliveryAgentListEntity } from '../../../data/model/deliveryAgent/deliveryAgent';
import PageContainer from '../../../reusable-components/PageContainer';
import { LoadingStatus } from '../../../data/model/interfaces';
import ListDataGridToolbar from '../../../reusable-components/data-grid/ListDataGridToolbar';
import AddDeliveryAgentButton from './AddDeliveryAgentButton';
import SelectHubContainer from '../../dashbord/SelectHubContainer';

function DeliveryAgentListPage() {
  const location = useLocation();
  const forceReload = location.state?.forceReload ?? false;
  const deliveryAgentList = useAppSelector(
    deliveryAgentListSelectors.selectPageItems
  );
  const deliveryAgentCount = useAppSelector(
    deliveryAgentListSelectors.selectCount
  );
  const deliveryAgentPageSize = useAppSelector(
    deliveryAgentListSelectors.selectPageSize
  );
  const deliveryAgentPageNumber = useAppSelector(
    deliveryAgentListSelectors.selectPageNumber
  );
  const deliveryAgentListLoadingStatus = useAppSelector(
    deliveryAgentListSelectors.selectLoadingStatus
  );

  const title = 'Delivery Agent List';

  const dispatch = useAppDispatch();
  const hubId = useAppSelector((state) => state.sharedPref.selectedHubId);

  useEffect(() => {
    dispatch(deliveryAgentListThunkActions.fetchList(forceReload));
  }, [dispatch, forceReload, hubId]);

  const fetchNextPage = () => {
    dispatch(deliveryAgentListThunkActions.fetchListNextPage());
  };

  const fetchPreviousPage = () => {
    dispatch(deliveryAgentListThunkActions.fetchListPreviousPage());
  };

  const refresh = () => {
    dispatch(deliveryAgentListThunkActions.fetchList(true));
  };

  const reloadCurrentPage = () => {
    dispatch(deliveryAgentListThunkActions.fetchList());
  };

  //DataGrid
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params: GridRowParams<DeliveryAgentListEntity> | any,
    event: MuiEvent<MuiBaseEvent>
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.page > deliveryAgentPageNumber - 1) {
      fetchNextPage();
    } else if (newPaginationModel.page < deliveryAgentPageNumber - 1) {
      fetchPreviousPage();
    }
  };

  return (
    <SelectHubContainer>
      <PageContainer>
        <DataGrid<DeliveryAgentListEntity>
          columns={getColumns(rowModesModel)}
          rows={deliveryAgentList}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          pagination
          keepNonExistentRowsSelected
          editMode="row"
          getRowId={(row) => row.user_id}
          paginationModel={{
            page: deliveryAgentPageNumber - 1,
            pageSize: deliveryAgentPageSize,
          }}
          pageSizeOptions={[]}
          rowCount={deliveryAgentCount}
          paginationMode="server"
          loading={deliveryAgentListLoadingStatus === LoadingStatus.LOADING}
          slots={{
            toolbar: ListDataGridToolbar,
          }}
          slotProps={{
            toolbar: {
              title,
              refresh,
              prefixRefreshButton: <AddDeliveryAgentButton refresh={refresh} />,
            },
          }}
          onPaginationModelChange={handlePaginationModelChange}
          onRowEditStop={handleRowEditStop}
        />
      </PageContainer>
    </SelectHubContainer>
  );
}

export default DeliveryAgentListPage;
