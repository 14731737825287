import { AxiosResponse } from 'axios';
import { logisticsApiClient } from './logisticsApiClient';
import { getServicablePincodeListUrl } from './apiUrls/logisticsApiUrls';

export const servicablePincode = async (hubId: string) => {
  const axiosResponse: AxiosResponse<string[]> = await logisticsApiClient.get(
    getServicablePincodeListUrl(hubId)
  );
  return axiosResponse.data;
};

export const unlinkPincode = async (hubId: string, pincode: string) => {
  const axiosResponse: AxiosResponse<any> = await logisticsApiClient.delete(
    getServicablePincodeListUrl(hubId),
    {
      data: {
        pincodes: [pincode],
      },
    }
  );
  return axiosResponse.data;
};

export const linkPincode = async (hubId: string, pincodes: string[]) => {
  const axiosResponse: AxiosResponse<void> = await logisticsApiClient.post(
    getServicablePincodeListUrl(hubId),
    {
      pincodes: pincodes,
    }
  );
  return axiosResponse.data;
};
