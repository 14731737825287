import { ItemDetail } from '../../../../../data/model/trip/shipment';
import {
  ITableHead,
  ITableRow,
} from '../../../../../reusable-components/table/type';
import { convertPaisaToRupee } from '../../../../../utils/currenty-util';
import { DisplayUtils } from '../../../../../utils/display-util';

export const tableHead: ITableHead<any> = [
  {
    label: 'Product Name',
  },
  {
    label: 'Quantity',
    isNumeric: true,
  },
  {
    label: 'Unit Price',
    isNumeric: true,
  },
  {
    label: 'Net Weight',
  },
];

export const getTableRows = (
  shipmentItems: ItemDetail[]
): ITableRow<ItemDetail>[] => {
  return shipmentItems.reduce<ITableRow<ItemDetail>[]>(
    (accumulator, currentValue) => {
      const row: ITableRow<ItemDetail> = {
        id: currentValue.item_name,
        value: currentValue,
        cells: [
          { label: currentValue.item_name },
          { label: currentValue.quantity.toString() },
          { label: convertPaisaToRupee(currentValue.cost) },
          { label: DisplayUtils.weightFormatter(currentValue.weight ?? 0) },
        ],
      };
      return [...accumulator, row];
    },
    []
  );
};
